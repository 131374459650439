import * as React from 'react';
import {Box, IconButton,FormGroup, FormControlLabel, Checkbox, Link,  Collapse, Card, CardMedia, CardContent, Stack, Divider, Grid, TextField, Button, InputLabel, MenuItem, FormControl, Select, Typography, ButtonGroup} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {SelectNFTmodal} from './SelectNFTModal';
import { ethers } from 'ethers'
//import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json'
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json'
import Marketplace from './artifacts/contracts/Marketplace.sol/Marketplace.json'
import {Logo} from './ethlogo.js';
import InputAdornment from '@mui/material/InputAdornment';
import IOSSwitch from './IOSwitch';
import {ContractModal} from './ContractModal';
import UploadFile from './IPFSupload';
import LinearProgress from './LinearProgress';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useState, useEffect } from 'react';
import ESlogo from './logos/etherscan-logo-light-circle.svg';
import AnimatedOrderCard from './AnimatedOrderCard';
import FormattedBlockNumber from './FormattedBlockNumber';

import SearchableContractInput from './contractSelectDropdown.js';

import X_logo from './logos/X_logo.svg';
import X_logoWhite from './logos/XlogoWhite.svg'
import VerifiedIcon from '@mui/icons-material/Verified';
import LanguageIcon from '@mui/icons-material/Language';
import OSwhite from './logos/OSwhite.svg' ;
import OSblack from './logos/OSblack.svg';
import ME from './logos/ME.svg' ;
import MEDark from './logos/MEDark.svg';
import DiscordWhite from './logos/discordWhite.svg';
import DiscordBlack from './logos/discordBlack.svg';
import Blur from './logos/Blur.svg';
import BlurDark from './logos/BlurDark.svg';

import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';

import ModalCreateOrder from './ModalCreateOrder';
import {createOrder, acceptOrder, getOrder} from './ethCreateOffer.js';
import { getOrders, getBlockGas, supabase,getUniqueUnderlyingFromOrders } from './supaBaseFuncs.js';
import Moralis from 'moralis';

import FormattedPrice from './FormattedPrice.js';
import OrderCard from './OrderCard.js';


import {convertBlocksToTime} from './Etherfuncs.js';

const INFURA_URL = process.env.REACT_APP_INFURA_URL;

export function OrderViewContract(props) {
     
      const theme = props.theme;
      const mode = props.mode;
      const switchPC = props.switchPC;
      const userAccount = props.userAccount;
      const curr = props.curr;
      const toggleTabBar = props.toggleTabBar;
      const blockMapping = props.blockMapping;

      const [MyOrders, setMyOrders] = useState([]);
      //const [curr, setCurr] = React.useState(6637107);
      const [currGas, setCurrGas] = React.useState('11');
      const [filterOn, setFilterOn] = React.useState(false);
      const [currContract, setCurrContract] = React.useState(NFTAddress);
      const [uniqueContracts, setUniqueContracts] = useState(['Nothing']);
      const [filterContract, setFilterContract] = useState('');
      const [filteredOrders, setFilteredOrders] = useState([]);

      const [openModalId, setOpenModalId] = useState(null); // Move state here

      const handleClose = () => setOpenModalId(null); // Move close function here
    

      const callStrikes = [100, 110, 125];
            // Add a new state to track if orders have been initially loaded
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    //const expiries = [0+curr, 20000+curr, 2000000+curr];
    const [expiries, setExpiries] = useState([]);
    const [organizedOrders, setOrganizedOrders] = useState({});
    const [bestOrders, setBestOrders] = useState({});
    const [expsOpen, setExpsOpen] = useState({});
    const [moralisData, setMoralisData] = React.useState({
                      "token_address": "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
                      "name": "BoredApeYachtClub",
                      "symbol": "BAYC",
                      "contract_type": "ERC721",
                      "synced_at": "2021-04-22T03:03:16.000Z",
                      "possible_spam": false,
                      "verified_collection": true,
                      "collection_logo": "https://i.seadn.io/gae/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB?w=500&auto=format",
                      "collection_banner_image": "https://i.seadn.io/gae/i5dYZRkVCUK97bfprQ3WXyrT9BnLSZtVKGJlKQ919uaUB0sxbngVCioaiyu9r6snqfi2aaTyIvv6DHm4m2R3y7hMajbsv14pSZK8mhs?w=500&auto=format",
                      "collection_category": "pfps",
                      "project_url": "http://www.boredapeyachtclub.com/",
                      "wiki_url": null,
                      "discord_url": "https://discord.gg/3P5K3dzgdB",
                      "telegram_url": null,
                      "twitter_username": "BoredApeYC",
                      "instagram_username": null,
                      "floor_price": "10.9482177",
                      "floor_price_usd": "29343.618",
                      "floor_price_currency": "eth"
                  });

      const [hideEmptyExpiries, setHideEmptyExpiries] = useState(false);
      
      //const [modalSelectNFTVisible, setModalSelectNFTVisible] = useState(null);
      const [modalSelectNFTOrderId, setModalSelectNFTOrderId] = useState(null);                                                  
      // To using an object keyed by orderId
      const [modalSelectedNFT, setModalSelectedNFT] = React.useState({});

      // Add a useEffect to track state changes
      useEffect(() => {
        //console.log("test567 modalSelectedNFT updated in OrderViewContract:", modalSelectedNFT);
      }, [modalSelectedNFT]);

      // Modify the useEffect for initial data loading
      useEffect(() => {
          if (!ordersLoaded) {
          GetOrders();
          }
      }, [ordersLoaded, currContract]);
      

      React.useEffect(()=>{
          Moralis.start({
              apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjhkODEyZTc4LWUzZmQtNDQ3Ny04OWZjLWE1ZmY3OWFiNmQzNSIsIm9yZ0lkIjoiNDA5OTA1IiwidXNlcklkIjoiNDIxMjI0IiwidHlwZUlkIjoiMDA2OTUwNWYtYmI1ZS00ZDA5LTgyM2QtNDIxNjU3ZTFjODM3IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3Mjc2NDMwNjUsImV4cCI6NDg4MzQwMzA2NX0.Lm42iD-8CWRDHSNUxsmx1GGOL7OCW01Mr2BleGrW5qU"
            });
      },[])

      
      useEffect(() => {
          if (ethers.isAddress(currContract)){
              getNFTContractMetadata(currContract);
          } else {
              console.log(currContract);
          }
      }, [currContract]);

    // Modify the Supabase subscription effects
    useEffect(() => {
      if (!ordersLoaded) return; // Only set up subscriptions after initial load

      const ordersChannel = supabase.channel('tradeView-orders-channel')
        .on('postgres_changes', { event: '*', schema: 'public', table: 'orders' }, (payload) => {
          console.log('supabase: Change received!', payload);
          GetOrders();
        })
        .subscribe();

      const optionsChannel = supabase.channel('tradeView-options-channel')
        .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'options' }, (payload) => {
          console.log('supabase: Change received!', payload);
          GetOrders();
        })
        .subscribe();
      
        const subToOwners = false;
        if (subToOwners){
      const ownersChannel = supabase.channel('tradeView-owners-channel')
        .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'owners' }, (payload) => {
          console.log('supabase: Change received!', payload);
          GetOrders();
        })
        .subscribe();
      }

      return () => {
        ordersChannel.unsubscribe();
        optionsChannel.unsubscribe();
        if (subToOwners){
        //ownersChannel.unsubscribe();
        console.log("unsubscribing from owners");
        }
      };
    }, [ordersLoaded]);

    useEffect(()=>{},[expsOpen]);

    async function getUniqueExpiriesForContract(contract) {
      const { data, error } = await supabase
        .from('options')
        .select('*')
        .gt('expiry', curr)
        .eq('contractAddress', contract)
        .order('expiry', { ascending: true });

      if (error) {
        console.error('Error fetching expiries:', error);
        return [];
      }

      // Fetch owners for the options
      const { data: ownersData, error: ownersError } = await supabase
        .from('owners')
        .select('optionId, owner')
        .in('optionId', data.map(item => item.optionId));

      if (ownersError) {
        console.error('Error fetching owners:', ownersError);
        return [];
      }

      // Create a map of optionId to owner
      const ownerMap = Object.fromEntries(ownersData.map(item => [item.optionId, item.owner]));

      // Filter out options where the owner is the zero address
      const validOptions = data.filter(item => {
        const owner = ownerMap[item.optionId];
        return owner && owner !== '0x0000000000000000000000000000000000000000';
      });

      const uniqueExpiries = [...new Set(validOptions.map(item => item.expiry))];

      // Fetch expiries from the "orders" table
      const { data: ordersData, error: ordersError } = await supabase
        .from('orders')
        .select('expiry')
        .eq('isFilled', false)
        .eq('isCancelled', false)
        .gt('validUntil', curr)
        .eq('isGeneral', true);
      console.log("ordersData", ordersData);

      if (ordersError) {
        console.error('Error fetching orders:', ordersError);
        return uniqueExpiries; // Return existing unique expiries if there's an error
      }

      // Extract unique expiries from orders
      const orderExpiries = [...new Set(ordersData.map(order => order.expiry))];

      // Combine unique expiries from valid options and orders
      const combinedUniqueExpiries = [...new Set([...uniqueExpiries, ...orderExpiries])];

      console.log('Unique expiries for contract', contract, ':', combinedUniqueExpiries);

      return combinedUniqueExpiries;
    }

        


      async function getNFTitem(address, id){
          try {
              const response = await Moralis.EvmApi.nft.getNFTMetadata({
                "chain": "0xaa36a7",
                "format": "decimal",
                "normalizeMetadata": true,
                "mediaItems": false,
                "address": address,
                "tokenId": id
              });
            
              console.log(response.raw);
              return response.raw;
            } catch (e) {
              console.error(e);
            }
      }
      async function getNFTContractMetadata(address) {
          //address = '0x5af0d9827e0c53e4799bb226655a1de152a425a5';
          try {
              
          const response = await Moralis.EvmApi.nft.getNFTContractMetadata({
            "chain": "0x1", //0xaa36a7
            "address":  address
          });
              let moD = response.raw;

              if (moD.collection_logo){
                  console.log(moD.collection_logo)
              } else{
                  let nftData = await getNFTitem(address, 1);
                  //console.log(nftData)
                  moD = {...moD, 
                          'collection_logo':nftData.normalized_metadata.image
                  }
                  //console.log(moD);
              }
              console.log(moD);


              if (String(address).toLowerCase() === String(NFTAddress).toLowerCase()) {
                moD = {
                  "token_address": "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
                  "name": "BoredApeYachtClub",
                  "symbol": "BAYC",
                  "contract_type": "ERC721",
                  "synced_at": "2021-04-22T03:03:16.000Z",
                  "possible_spam": false,
                  "verified_collection": true,
                  "collection_logo": "https://i.seadn.io/gae/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB?w=500&auto=format",
                  "collection_banner_image": "https://i.seadn.io/gae/i5dYZRkVCUK97bfprQ3WXyrT9BnLSZtVKGJlKQ919uaUB0sxbngVCioaiyu9r6snqfi2aaTyIvv6DHm4m2R3y7hMajbsv14pSZK8mhs?w=500&auto=format",
                  "collection_category": "pfps",
                  "project_url": "http://www.boredapeyachtclub.com/",
                  "wiki_url": null,
                  "discord_url": "https://discord.gg/3P5K3dzgdB",
                  "telegram_url": null,
                  "twitter_username": "BoredApeYC",
                  "instagram_username": null,
                  "floor_price": "10.9482177",
                  "floor_price_usd": "29343.618",
                  "floor_price_currency": "eth"
              };
              }
              
              setMoralisData(moD);
          } catch (e) {
              console.error(e);
          }
      };



        // this function is used to get the best orders for each expiry and option type   
        function getBestOrders(organizedOrders) {
          console.log("Input organized orders:", organizedOrders);
        
          const bestOrders = {};
        
          Object.entries(organizedOrders).forEach(([expiry, types]) => {
            bestOrders[expiry] = { calls: {}, puts: {} };
            
            ['calls', 'puts'].forEach(optionType => {
              const sortedStrikes = Object.keys(types[optionType]).sort((a, b) => parseFloat(a) - parseFloat(b));
              
              sortedStrikes.forEach(strike => {
                const orders = types[optionType][strike];
                bestOrders[expiry][optionType][strike] = {
                  bestBid: orders.bids.reduce((best, order) => 
                    (!best || order.price > best.price) ? order : best, null),
                  bestOffer: orders.offers.reduce((best, order) => 
                    (!best || order.price < best.price) ? order : best, null)
                };
              });
            });
        
            // Remove strikes where both bestBid and bestOffer are null for both calls and puts
            const strikesToRemove = [];
            Object.keys(bestOrders[expiry].calls).forEach(strike => {
              const callOrder = bestOrders[expiry].calls[strike];
              const putOrder = bestOrders[expiry].puts[strike];
              if (!callOrder.bestBid && !callOrder.bestOffer && !putOrder.bestBid && !putOrder.bestOffer) {
                strikesToRemove.push(strike);
              }
            });
        
            strikesToRemove.forEach(strike => {
              delete bestOrders[expiry].calls[strike];
              delete bestOrders[expiry].puts[strike];
            });
        
            // Remove expiry if it's empty
            if (Object.keys(bestOrders[expiry].calls).length === 0 && Object.keys(bestOrders[expiry].puts).length === 0) {
              delete bestOrders[expiry];
            }
          });
        
          console.log("Final best orders structure:", bestOrders);
        
          return bestOrders;
        }
        

        function organizeOrders(orders, expiries) {
          // Create the organized structure dynamically based on expiries
          console.log("organizeOrders expiries", expiries);
          const organized = Object.fromEntries(expiries.map(expiry => [expiry, { calls: {}, puts: {} }]));
        
          // Check if orders is empty
          if (!orders || orders.length === 0) {
            console.log("No orders to organize");
            return organized;
          }
        
          // First pass: organize orders and collect all strikes
          const allStrikes = new Set();
          orders.forEach(order => {
          let expiryGroup = expiries.find(exp => order.expiry == exp);

          if (!expiryGroup) {
              console.warn(`No matching expiry found for order:`, order);
              console.log(`Available expiries:`, expiries);
              return; // Skip this order
          }

          const optionType = order.isCall ? 'calls' : 'puts';
          const orderType = order.isOffer ? 'offers' : 'bids';
          
          if (!organized[expiryGroup]) {
              console.warn(`Expiry group not initialized:`, expiryGroup);
              organized[expiryGroup] = { calls: {}, puts: {} };
          }

          if (!organized[expiryGroup][optionType][order.strike]) {
              organized[expiryGroup][optionType][order.strike] = { bids: [], offers: [] };
          }
          
          organized[expiryGroup][optionType][order.strike][orderType].push(order);
          allStrikes.add(order.strike);
          });

          console.log("Organized structure:", organized);
        
          // Second pass: ensure symmetry across all dimensions
          Object.keys(organized).forEach(expiryGroup => {
            allStrikes.forEach(strike => {
              ['calls', 'puts'].forEach(optionType => {
                if (!organized[expiryGroup][optionType][strike]) {
                  organized[expiryGroup][optionType][strike] = { bids: [], offers: [] };
                } else {
                  // Ensure both bids and offers exist
                  if (!organized[expiryGroup][optionType][strike].bids) {
                    organized[expiryGroup][optionType][strike].bids = [];
                  }
                  if (!organized[expiryGroup][optionType][strike].offers) {
                    organized[expiryGroup][optionType][strike].offers = [];
                  }
                }
              });
            });
          });
        
          return organized;
        }




    // Modify the GetOrders function
    async function GetOrders() {
      try {
        const exps = await getUniqueExpiriesForContract(currContract);
        setExpiries(exps);
        if (exps.length > 0) {
          handleExps(exps[0]);
        }

        const orders = await getOrders();
        const filtered = filterOrders(orders);
        setMyOrders(orders);
        setFilteredOrders(filtered);

        let organized = await organizeOrders(filtered, exps);
        setOrganizedOrders(organized);

        let unique = orders
          .map(item => item.underlyingContract)
          .filter((value, index, self) => self.indexOf(value) === index);
        setUniqueContracts(unique);

        const best = getBestOrders(organized);
        setBestOrders(best);

        // Set ordersLoaded to true after initial load
        setOrdersLoaded(true);
      } catch (error) {
        console.error("Error in GetOrders:", error);
      }
    }

      const formatBlockNumber = (blockNumber) => {
          const exp = Math.floor(Math.log10(blockNumber));
          const base = blockNumber / Math.pow(10, exp);
          return `${base.toFixed(1)}×10^${exp}`;
      };

      // First, modify the useEffect to set all expiries open initially
      useEffect(() => {
        if (expiries.length > 0) {
          setExpsOpen(prevState => {
            const newState = {};
            expiries.forEach(exp => {
              // Only set if not already set in prevState
              if (!(exp in prevState)) {
                newState[exp] = true;
              }
            });
            return { ...prevState, ...newState };
          });
        }
      }, [expiries]);

      // Fix the handleExps function to toggle individual expiries
      function handleExps(exp) {
        setExpsOpen(prevState => ({
          ...prevState,
          [exp]: !prevState[exp]
        }));
      }

      // Add this new function after the handleExps function
      function handleExpandAll(expand) {
        setExpsOpen(prevState => {
          const newState = {};
          Object.keys(organizedOrders).forEach(exp => {
            newState[exp] = expand;
          });
          return newState;
        });
      }


    // Create a separate function for filtering orders
    function filterOrders(orders) {
        console.log("filtering by", filterContract, curr);
        if (orders?.length > 0) {
            const f = orders.filter(order => {
                const contractMatch = !filterContract || order.underlyingContract.toLowerCase() === filterContract.toLowerCase();
                const validMatch = (Number(order.validUntil) - Number(curr)) >= 1;
                const expiryMatch = (Number(order.expiry) - Number(curr)) >= 1;
                const cancelledMatch = !order.isCancelled && !order.isFilled;
                return contractMatch && validMatch && expiryMatch && cancelledMatch;
            });
            console.log("FFFF", f);
            return f;
        } else {
            console.log("no orders");
            return [];
        }
    }

    const hasStrikes = (exp) => {
      return (Object.keys(bestOrders[exp]?.calls || {}).length + 
              Object.keys(bestOrders[exp]?.puts || {}).length) > 0;
    };

    return(
    <Card sx={{maxWidth:{xs:'100%', sm:'100%', lg:'100%'}, minWidth: {sm:'97%', sm:'90%', lg:'50%'}, paddingLeft:1, paddingRight:0}}>
      <CardContent sx={{paddingLeft:0, paddingRight:0,  marginLeft:1, marginRight:0}}>    
          
      <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
          alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>              
              <Grid item xs={12} style={{ paddingTop: 10 }}>
                    <Stack direction='column'>
                        <Typography variant="h6" align='left' style={{paddingBottom:0, paddingTop:10}}>
                        Contract view
                    </Typography>
                    <Typography variant="body2" align='left' style={{paddingBottom:0, paddingTop:10}}>
                        {currContract}
                    </Typography>

                    <div>
                    <SearchableContractInput
                    contracts = {uniqueContracts}
                    onChange={setFilterContract}
                    label="Underlying Contract"
                    />
                    </div>

                    </Stack>
                  
              </Grid>

              <Grid container item xs={12} 
              style={{ paddingTop: 10 }} 
              alignItems="stretch" columns={{ xs: 6 }}>
                  <Grid item  xs={6}>
                  <Card sx={{ position: 'relative', overflow: 'hidden' }}>
                    <div 
                        style={{
                            backgroundImage: `url(${moralisData.collection_banner_image})`, // Use the collection banner image
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '400px', // Adjust height as needed
                            filter: 'grayscale(100%)', // Make the image black and white
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1 // Ensure the image is behind the content
                        }}
                    />
                    <div 
                        style={{
                            backgroundColor:  mode === 'light' ? 
                              'rgba(255, 255, 255, 0.8)'
                              :
                              'rgba(0, 0, 0, 0.8)'
                            , // Semi-transparent black overlay
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 2 // Ensure the overlay is above the image
                        }}
                    />
                        <CardContent sx={{ position: 'relative', zIndex: 3 }}> 
                        <Typography variant='body2' align='center' color='primary'>
                            Underlying collection
                        </Typography>
                        <Link 
                            href={`https://sepolia.etherscan.io/address/${moralisData.token_address}`} 
                            target="_blank" 
                            underline="hover" 
                            color={mode=='light'?'black':'white'}
                            rel="noopener noreferrer"
                            style={{ 
                                textDecoration: 'none' // Ensure no underline by default
                            }} 
                        >
                            <Typography variant='h6' style={{ fontSize: 18 }}>
                                {moralisData.name}
                                {moralisData.verified_collection && (
                                <VerifiedIcon style={{ width: '20px', height: '20px', marginLeft:'2px', verticalAlign: 'middle' }} />
                            )}
                            </Typography>
                        </Link>

                        <Grid container alignContent='center' justifyContent='center' spacing={2} sx={{ width: '100%' }}>
                            <Grid item>
                                <Link href={`https://opensea.io/assets/ethereum/${moralisData.token_address}`} target="_blank" rel="noopener noreferrer">
                                    <img src={mode === 'light' ? OSblack : OSwhite} alt="OpenSea" style={{ width: '16px', height: '16px' }} />
                                </Link>
                            </Grid>

                            <Grid item>
                                <Link href={`https://blur.io/eth/collection/${moralisData.token_address}`} target="_blank" rel="noopener noreferrer">
                                    <img src={mode === 'dark' ? Blur : BlurDark} alt="Blur" style={{ width: '16px', height: '16px' }} />
                                </Link>
                            </Grid>

                            <Grid item>
                                <Link href={`https://magiceden.io/collections/ethereum/${moralisData.token_address}`} target="_blank" rel="noopener noreferrer">
                                    <img src={mode === 'dark' ? ME : MEDark} alt="Magic Eden" style={{ width: '16px', height: '16px' }} />
                                </Link>
                            </Grid>

                            <Grid item>
                                <Link href={moralisData.project_url} target="_blank" rel="noopener noreferrer">
                                <LanguageIcon style={{ color: mode === 'light' ? 'black' : 'white', width: '20px', height: '20px', marginLeft: '5px', verticalAlign: 'top' }} />
                                </Link>
                            </Grid>

                            <Grid item sx={{alignContent:'top'}}>
                                <Link href={`https://twitter.com/${moralisData.twitter_username}`} target="_blank" rel="noopener noreferrer" sx={{height:'12px', alignContent:'top', paddingTop:0, marginTop:0}}>
                                    <img src={mode === 'light' ? X_logo : X_logoWhite} alt="X" style={{ width: '14px', height: '14px', marginRight: '5px' }} />
                                </Link>
                            </Grid>

                            <Grid item>
                                <Link href={moralisData.discord_url} target="_blank" rel="noopener noreferrer">
                                    <img src={mode === 'light' ? DiscordBlack : DiscordWhite} alt="Discord" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                                </Link>
                            </Grid>
                        </Grid>


                        <Typography style={{ fontSize: 18, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} sx={{maxWidth:'80%'}}>
                            <Link 
                                href={`https://sepolia.etherscan.io/address/${moralisData.token_address}`} 
                                target="_blank" 
                                underline="hover" 
                                rel="noopener noreferrer"
                                style={{ 
                                    flex: '0 0 100%', // Take up 80% of the space
                                    overflow: 'hidden', // Prevent overflow
                                    whiteSpace: 'nowrap', // Prevent text wrapping
                                    textOverflow: 'ellipsis' // Add ellipsis for overflow text
                                }} 
                            >
                                {String(moralisData.token_address).slice(0, 6) + "..." + String(moralisData.token_address).slice(-4)}
                            </Link>
                        </Typography>

                        <Box display='flex' justifyContent='center' alignItems='center'>
                        <CardMedia component="img" src={moralisData.collection_logo}  height='170px'  sx={{ borderRadius: '100px', boxShadow: 0, maxWidth:'25%', minWidth:'170px' }}/>
                        
                        </Box>

                        <Box justifyContent='center' alignItems='center' sx={{minWidth:'70%'}}>
                            <Stack direction='row' justifyContent='center' alignItems='center'>
                                <IconButton onClick={() => window.open('https://goerli.etherscan.io/address/0x7002cC4e2b8b539E71c9BcEe8529c6AED17B8E96')}>
                                    <img src={OSwhite} width={15}/>
                                </IconButton>

                                <Typography align='center' >
                                    Ξ {moralisData.floor_price} 
                                </Typography>
                            </Stack>
                        </Box>
                        </CardContent>
                    </Card>
                  </Grid>
              </Grid>
        </Grid>



<Box sx={{width:'100%', overflowX:'scroll', marginTop:2, paddingTop:2, paddingBottom:3,
    marginLeft:'-16px', paddingTop:'30px', borderTop:1, borderBottom:1, borderColor:'#232526'}}>


    <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
            alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>              
                
                <Grid item xs={12} style={{ padding: 1, paddingLeft:35, margin:1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '20px' }}>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleExpandAll(!Object.values(expsOpen).some(v => v))}
                        startIcon={Object.values(expsOpen).some(v => v) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        style={{padding:3, margin:0}}
                    >
                      <Typography fontSize={10}>
                      {Object.values(expsOpen).some(v => v) ? 'Collapse All' : 'Expand All'}
                      </Typography>
                       
                    </Button>
                    
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hideEmptyExpiries}
                                onChange={(e) => setHideEmptyExpiries(e.target.checked)}
                                size="small"
                            />
                        }
                        label={<Typography fontSize={12}>Hide empty expiries</Typography>}
                    />
                </Grid>

                <Grid container item xs={12}  style={{ paddingTop: 0, marginTop:-10, minWidth:'660px'}}  alignItems="stretch" columns={{ xs: 9}} wrap='nowrap'>
                    <Grid container item  xs={4} columns={{xs: 2}} alignItems="stretch" sx={{minWidth:'50%'}}>
                            <Grid item  xs={2}>
                                <Typography>
                                    Calls
                                </Typography>
                                <Divider/>
                            </Grid>
                    </Grid>

                    <Grid item  xs={1} sx={{minHeigth:'100%'}}>
                        <Divider orientation="vertical" />
                    </Grid>

                    <Grid container item  xs={4} columns={{xs: 2}} alignItems="stretch" sx={{minWidth:'50%'}}>
                            <Grid item  xs={2}>
                                <Typography>
                                    Puts
                                </Typography>
                                <Divider/>
                            </Grid>
                    </Grid>
                </Grid>

                {Object.keys(organizedOrders)
                    .filter(exp => !hideEmptyExpiries || hasStrikes(exp))
                    .map((exp, index) => {
                        const strikeCount = Object.keys(bestOrders[exp]?.calls || {}).length + 
                                          Object.keys(bestOrders[exp]?.puts || {}).length;
                        
                        return (
                            <Grid container item xs={12} style={{ paddingTop: 10, minWidth:'660px' }}  
                                  alignItems="stretch" columns={{ xs: 9}} wrap='nowrap'
                                  key={`expiry-${exp}`}>
                                <Grid container item xs={4} columns={{xs: 2}} alignItems="stretch" sx={{minWidth:'50%'}}>
                                    <Grid item xs={2}>
                                        <Divider/>
                                        <Divider textAlign="left">
                                            <Stack direction="row" sx={{display:'flex', alignItems:'center'}}>
                                                {strikeCount > 0 ? (
                                                    <IconButton onClick={() => handleExps(exp)}>
                                                        {expsOpen[exp] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </IconButton>
                                                ) : (
                                                    // Placeholder div with same width as IconButton
                                                    <div style={{ width: '40px', height: '40px' }} /> // Adjust width/height to match your IconButton
                                                )}
                                                
                                                <Typography fontSize={12}>
                                                    Expiry at block #<FormattedBlockNumber blockNumber={exp} /> {' '}
                                                    <Typography component="span" color="text.secondary" fontSize={12}>
                                                        ({convertBlocksToTime(exp-curr)})
                                                    </Typography>
                                                    <Typography component="span" color="primary" fontSize={12}>
                                                        {' '}({strikeCount} strikes)
                                                    </Typography>
                                                </Typography>
                                            </Stack>
                                        </Divider>
                                    </Grid>

                                    { expsOpen[exp] ? 
                                    <div style={{minWidth:'100%', margin:0, padding:0}}>
                                    {Object.entries(bestOrders[exp]?.calls || {}).map(([strike, e], index) => {
                                        //console.log("best order", e, strike);
                                        return (
                                            <Grid container item  xs={4} columns={{xs:2}} style={{minWidth:'100%', margin:0, padding:0}}
                                            key={`call-${exp}-${strike}`}>
                                                
                                                <Grid container item  xs={2} columns={{xs:12}} wrap="nowrap" style={{minWidth:'100%'}}>
                                                    <Grid item xs={2} style={{minWidth:'10%', maxWidth:'10%'}}>
                                                    {index === 0 ? 
                                                    <Typography size="small" style={{fontSize:10, textAlign: 'left'}}>
                                                            Strike
                                                        </Typography>
                                                        : ""}
                                                        <Typography size="small" style={{fontSize:10, textAlign: 'left'}}>
                                                            {strike}
                                                        </Typography>
                                                    </Grid>
                                                    
                                                    <Grid item xs={5} >
                                                        <AnimatedOrderCard theme={theme} 
                                                        order={e.bestBid} isBid={true}  userAccount={userAccount} 
                                                        curr={curr} option={e} 
                                                        openModalId={openModalId} // Pass down state
                                                        setOpenModalId={setOpenModalId}
                                                        handleClose={handleClose}
                                                        contractAddress={currContract}
                                                        strike={strike}
                                                        expiry={exp}
                                                        isCall = {true} 
                                                        modalSelectNFTVisible={modalSelectNFTOrderId}
                                                        setModalSelectNFTVisible={setModalSelectNFTOrderId}
                                                        modalSelectedNFT={modalSelectedNFT}
                                                        setModalSelectedNFT={setModalSelectedNFT}
                                                        mode={mode}
                                                        blockMapping={blockMapping}
                                                        toggleTabBar={toggleTabBar}
                                                        
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5} >
                                                        <AnimatedOrderCard 
                                                        theme={theme} 
                                                        order={e.bestOffer} 
                                                        isBid={false}  
                                                        userAccount={userAccount} 
                                                        curr={curr}  
                                                        option={e} 
                                                        openModalId={openModalId} // Pass down state
                                                        setOpenModalId={setOpenModalId}
                                                        handleClose={handleClose} 
                                                        contractAddress={currContract}
                                                        strike={strike}
                                                        expiry={exp}
                                                        isCall = {true}
                                                        modalSelectNFTVisible={modalSelectNFTOrderId}
                                                        setModalSelectNFTVisible={setModalSelectNFTOrderId}
                                                        modalSelectedNFT={modalSelectedNFT}
                                                        setModalSelectedNFT={setModalSelectedNFT}
                                                        mode={mode}
                                                        blockMapping={blockMapping}
                                                        toggleTabBar={toggleTabBar}
                                                        />
                                                    </Grid>
                                                    
                                                    
                                                </Grid>
                                            </Grid>

                                            
                                        )} )
                                    }
                                    </div>
                                    
                                    : ''} 
                                    
                                </Grid>
                    {
                        // HERE WE NEED TO FILTER IS PUTS SHOULD BE SHOWN, CALLS OR BOTH
                    }
                    <Grid item  xs={1} sx={{minHeigt:'100%'}}>
                        <Divider orientation="vertical" />
                    </Grid>

                    <Grid container item  xs={4} columns={{xs: 2}} alignItems="stretch" sx={{minWidth:'50%'}}>
                            
                            <Grid item  xs={2}>
                                <Divider/>
                                <Divider sx={{marginTop:'20px', marginBottom:'20px'}}>
                                </Divider>
                            </Grid>

                            { expsOpen[exp] ? 
                            <div>
                            {Object.entries(bestOrders[exp]?.puts || {}).map(([strike, e], index) => {
                                return (

                                    
                                    <Grid container item  xs={2} columns={{xs:2}} key={`put-${exp}-${strike}`}>
                                        
                                        <Grid container item  xs={2} columns={{xs:5}} wrap="nowrap" style={{minWidth:'100%'}}>
                                            <Grid item xs={2}>
                                                <Typography size="small" style={{fontSize:10}}>
                                                    Strike: {strike}
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item xs={5} >
                                                          <AnimatedOrderCard theme={theme} 
                                                          order={e.bestBid} isBid={true}  userAccount={userAccount} 
                                                          curr={curr} option={e} 
                                                          openModalId={openModalId} // Pass down state
                                                          setOpenModalId={setOpenModalId}
                                                          handleClose={handleClose} 
                                                          contractAddress={currContract}
                                                          strike={strike}
                                                          expiry={exp}
                                                          isCall = {false}
                                                          modalSelectNFTVisible={modalSelectNFTOrderId}
                                                          setModalSelectNFTVisible={setModalSelectNFTOrderId}
                                                          modalSelectedNFT={modalSelectedNFT}
                                                          setModalSelectedNFT={setModalSelectedNFT}
                                                          mode={mode}
                                                          toggleTabBar={toggleTabBar}
                                                          blockMapping={blockMapping}
                                                          />
                                                      </Grid>
                                                      <Grid item xs={5} >
                                                          <AnimatedOrderCard theme={theme} 
                                                          order={e.bestOffer} 
                                                          isBid={false}  
                                                          userAccount={userAccount} 
                                                          curr={curr}  
                                                          option={e} 
                                                          openModalId={openModalId} // Pass down state
                                                          setOpenModalId={setOpenModalId}
                                                          handleClose={handleClose} 
                                                          contractAddress={currContract}
                                                          strike={strike}
                                                          expiry={exp}
                                                          isCall = {false}
                                                          modalSelectNFTVisible={modalSelectNFTOrderId}
                                                          setModalSelectNFTVisible={setModalSelectNFTOrderId}
                                                          modalSelectedNFT={modalSelectedNFT}
                                                          setModalSelectedNFT={setModalSelectedNFT}
                                                          mode={mode}
                                                          toggleTabBar={toggleTabBar}
                                                          blockMapping={blockMapping}
                                                          />
                                                      </Grid>

                                        </Grid>
                                    </Grid>
                                )} )
                            }
                        </div>
                        
                        : ''} 
                    </Grid>
                </Grid>
                                )})
                }
                


    </Grid>
          
</Box>

      </CardContent>
</Card>)};



