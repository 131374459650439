import * as React from 'react';
//import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
import {Casino, MoreHoriz} from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import Swipeable from './Swipeable';
import { Typography, AppBar, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Toolbar, Container,
    Box, Paper, Stack, TextField, Button, ButtonGroup, InputLabel, MenuItem, FormControl, Select} from '@mui/material';

import { ethers } from 'ethers';
import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json';
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json';
import Marketplace from './artifacts/contracts/Marketplace.sol/Marketplace.json';

import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';


import { useConnectWallet } from '@web3-onboard/react'

//import UploadFile from './IPFSupload';
//import { infuraProvider } from 'wagmi/providers/infura';
//import { network } from 'hardhat';

const INFURA_URL = process.env.REACT_APP_INFURA_URL;

//const INFURA_RPC = `https://sepolia.infura.io/v3/${INFURA_URL}`


const gradient1 = "radial-gradient(circle at top, #414345, #232526)";
const cards1 = [1, 2]; // Cards for option list
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 250,
  borderRadius: 1,
  boxShadow: 5,
  bgcolor: 'black',
  p: 1,
  outline:'none'
};

export function ExtraMenu(props) {


  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  
  //const fakeBaycAddress = props.fakeBaycAddress
  //const erc721OptionAddress = props.erc721OptionAddress 
  const amount   = props.amount
  const userAccount = props.userAccount
  const setUserAccount = props.setUserAccount
  const setAmount = props.setAmount
  const setBAYC = props.setBAYC
  const setOption = props.setOption


  //const MarketplaceAddress = '0x840AbfDB14b1Fc33cC2e108714526cF8a1a4Ca14';


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  }


async function getProvider(){
  if (wallet) {
    let account = wallet.accounts[0].address;
    const provider= new ethers.BrowserProvider(wallet.provider, 'any')
    const signer = await provider.getSigner()
    return [provider, signer, account];
}
}
  // Marketplace functions

  async function setContractAddressOPT(){
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      console.log("trying 0");
      const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
      const contractP = new ethers.Contract(MarketplaceAddress, Marketplace.abi, provider);

      const currentContract = contractP.OptionContractAddress();
      console.log(currentContract, "??", ZtrikeAddress);
      if (ZtrikeAddress != currentContract){
        console.log(`trying 1 ${ZtrikeAddress}`);
        const transation = await contract.setOptionContractAddress(ZtrikeAddress);
        console.log("trying 2");
        await transation.wait();
        console.log(`Marketplace: Option contract succesfully set to ${ZtrikeAddress}`);
      } else {
        console.log("they are the same")
      };
      
    }
    }


    async function setContractAddressMKT(){
      if (wallet) {
        const [provider, signer, account] = await getProvider();
        console.log("MP trying 0");
        const contract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);
        const contractP = new ethers.Contract(ZtrikeAddress, Option.abi, provider);
        const currentContract = contractP.MarketplaceAddress();

        console.log(currentContract, "??", MarketplaceAddress);
        if (MarketplaceAddress != currentContract){
          console.log(`MP trying 1 ${MarketplaceAddress}`);
          const transation = await contract.setMarketplaceAddress(MarketplaceAddress);
          console.log("MP trying 2");
          await transation.wait();
          console.log(`Option: Marketplace contract succesfully set to ${MarketplaceAddress}`);
        } else {
          console.log("MP: they are the same")
        };
        
      }
      }


    

    async function getOrders(){
      if (typeof window.ethereum !== 'undefined') {
        
        //const provider = new ethers.providers.JsonRpcProvider(INFURA_RPC);

        console.log(INFURA_URL);
        const provider = new ethers.InfuraProvider("sepolia", INFURA_URL);
        
        console.log(provider);

        const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, provider);

        const Order = await contract.Orders(0);
        console.log(Order)

      };
    }


    async function createOffer(){
      if (wallet) {
        const [provider, signer, account] = await getProvider();
  
        // if not already approved, approve alle Ztrike options for Marketplace contract

        console.log("account", account, ZtrikeAddress, "signer", signer);
        const ztrikeContract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);

        console.log("trying approve", account, MarketplaceAddress);
        const isApproved = await ztrikeContract.isApprovedForAll(account, MarketplaceAddress);
        //await isApproved.wait();
        console.log("isApproved", isApproved);

        if (false){
          const approval = await ztrikeContract.setApprovalForAll(MarketplaceAddress, true);
          await approval.wait();
          console.log("approved");
        } else {
          console.log("already approved");
        };
        
        const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
        //console.log(`trying 1 ${ZtrikeAddress}`);
        //const transation = await contract.setOptionContractAddress(ZtrikeAddress);
        //console.log("trying 2");
        //await transation.wait();
        //console.log(`Marketplace: Option contract succesfully set to ${ZtrikeAddress}`);
        
        const optionId = 0;
        const priceInWei = 0.05*10000;
        const validForNblocks = 20000;
        console.log("trying createOffer with args:", optionId, priceInWei, validForNblocks);

        const offerCreate = await contract.createOffer(optionId, priceInWei, validForNblocks);
        await offerCreate.wait();
        console.log("Offer created")
        
      }
      }


  // FakeBayc functions
  async function safeMint() {

    

    console.log(wallet)
    
    if (wallet) {
      console.log("wallets -->", wallet, wallet.accounts[0].address)
      let account = wallet.accounts[0].address;
      const ethersProvider = new ethers.BrowserProvider(wallet.provider, 'any')

      const signer = await ethersProvider.getSigner()

      const contract = new ethers.Contract(NFTAddress, BAYC.abi, signer);
      const transation = await contract.safeMint(account);
      await transation.wait();
      console.log(`BAYC minted successfully sent to ${account}`);
    };
  
  console.log("----------");
    if (false){
      if (wallet) {
        const [provider, signer, account] = await getProvider();
                  const contract = new ethers.Contract(NFTAddress, BAYC.abi, signer);
                  const transation = await contract.safeMint(account);
                  await transation.wait();
                  console.log(`BAYC minted successfully sent to ${account}`);
                }
    }
 
  }

  async function getBalanceBAYC() {
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      const contract = new ethers.Contract(NFTAddress, BAYC.abi, provider)
      const balance = await contract.balanceOf(account);
      console.log("Balance of BAYC: ", balance.toString());
    }
    }
    // Implement ERC721 Option function
    // Mint Option from FAKEBAYC
    // function writeOption(uint _strike, uint _expiry, uint _premium, uint _tokenId)
    // Redeem Option
    // Sell Option
    // approveForAll
    // setInterfaceContract

  // withdrawETH
  async function withdrawETH() {
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      const contract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);
      const transaction = await contract.withdrawETH();
    }
    }

  return (
    <div >
    <Button variant='outlined' color='secondary' sx={{ marginLeft: "auto"}} style={{paddingTop: 10, maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}}
    onClick={handleOpen} > <MoreHoriz/> </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

        <Container maxWidth='md' align='center' style={{  paddingTop: "50px"}}>
                            <Typography variant='h6' align='left' >
                                Admin menu: 
                            </Typography>
                            </Container>

                            <Container maxWidth='md'align='center'>
                            <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='center' alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>
                                <Grid item xs={6}  >
                                    <Button variant="contained" style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                        onClick={() => safeMint()}>Mint</Button>
                                </Grid>

                                <Grid item xs={6}  >
                                    <Button variant="contained" style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                        onClick={() => setContractAddressOPT()}>set Option SC in Marketplace SC </Button>
                                </Grid>

                                <Grid item xs={6}  >
                                    <Button variant="contained" style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                        onClick={() => setContractAddressMKT()}>set MKT SC in Option SC </Button>
                                </Grid>


                                <Grid item xs={6}  >
                                    <Button variant="contained" style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                        onClick={() => createOffer()}>createOffer()</Button>
                                </Grid>

                                <Grid item xs={6}  >
                                    <Button variant="contained" style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                        onClick={() => getOrders()}>getOrders()</Button>
                                </Grid>

                                



                                <Grid item xs={6}  >
                                    <TextField fullWidth id="standard-basic2" label="Option Contract" variant="outlined" onChange={e => setOption(e.target.value)} value={ZtrikeAddress}/>
                                </Grid>
                                <Grid item xs={6}  >
                                    <Button variant="contained" style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                        onClick={() => getBalanceBAYC()}>getBalanceBAYC</Button>
                                </Grid>
                                <Grid item xs={6}  >
                                    <Button variant="contained" style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}} 
                                        onClick={() => withdrawETH()}>withdrawETH</Button>
                                </Grid>
                            </Grid>
                            </Container>
        </Box>
      </Modal>
    </div>
  );
}