import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';



export default function LinearIndeterminate(props) {

if (props.showHide){
    return (
    <Box sx={{ width: '100%' }}>
           <Box sx={{ width: '100%' }}>
            <Typography variant='body2' align='center'>
                {props.loadingText}
            </Typography>
        </Box>  
        <Box sx={{ width: '100%' }}>
          <LinearProgress color={props.switchPC == 'Call' ? 'primary': 'secondary'}  />
        </Box>
   
    </Box>

      );
} else{
  if (props.showTextOnly) {
    return (
      <Box sx={{ width: '100%' }}>
             <Box sx={{ width: '100%' }}>
              <Typography variant='body2' align='center'>
                  {props.loadingText}
              </Typography>
          </Box>       
      </Box>
    );
  }

};
}