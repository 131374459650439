import * as React from "react";
import Modal from "@mui/material/Modal";
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import {Logo} from './ethlogo.js';
import {LogoSmallCustom, LogoSmall} from './ethlogoSmall.js';
import InputAdornment from '@mui/material/InputAdornment';
import { ethers } from 'ethers';
import {Twitter, Percent, HourglassTop} from '@mui/icons-material';
import OSwhite from './logos/OSwhite.svg' ;
import ESlogo from './logos/etherscan-logo-light-circle.svg';

import {ethCreateOfferInitializeWallet, createOrder, createGeneralBid} from './ethCreateOffer.js';
import IOSSwitch from "./IOSwitch.js";

import { useConnectWallet } from '@web3-onboard/react';
import { initializeWallet, convertBlocksToTime } from "./Etherfuncs.js";

import { blackScholes } from "./blackScholes.js";

import { SelectNFT } from "./OrderViewSelectNFT.js";


import { getOption } from "./supaBaseFuncs.js";

import {
  Typography,
  Card,
  Grid,
  Box,
  Stack,
  TextField,
  Button,
  Link,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { NFTAddress } from "./ethContracts.js";


const gradient1 = "radial-gradient(circle at top, #414345, #232526)";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  borderRadius: 1,
  boxShadow: 0,
  bgcolor: "transparent",
  p: 1,
  outline: "none",
};



export default function ModalCreateOrder(props) {

  const {
    buttonText,
    activeStrike,
    justifyContent,
    underlyingContract,
    activeExpiry,
    activePC,
    orderType,
    bb,
    isSpecific,
    openT,
    setOpenT,
    expIsBlock,
    activeUnderlyingID,
    optionID,
    buttonColor,
    blockMapping,
    theme,
    mode,
    toggleTabBar,
    switchPC,
    userAccount
  } = props;

  console.log("test666", activeStrike, activeExpiry);
  
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [loadingStatus, setLoadingStatus] = React.useState({
    visible: false,
    loadingText: ''
  });


  // Add state for option details
  const [selectedOptionDetails, setSelectedOptionDetails] = React.useState({
    optionId: null,
    strike: null,
    isCall: null,
    expiry: null,
    underlyingId: null,
    contract: null
  });


// Add order type enum for clarity
const ORDER_TYPES = {
  SELL_SPECIFIC: 'OrderToSellSpecific',
  BUY_SPECIFIC: 'OrderToBuySpecific',
  BUY_GENERAL: 'OrderToBuyGeneral'
};

// Update order type determination
const [orderTypeState, setOrderTypeState] = React.useState(() => {
  if (String(orderType).toLowerCase() === 'offer') {
    return ORDER_TYPES.SELL_SPECIFIC;
  } else if (isSpecific) {
    return ORDER_TYPES.BUY_SPECIFIC;
  } else {
    return ORDER_TYPES.BUY_GENERAL;
  }
});



  // Initialize state variables
  const [cStrike, setCstrike] = React.useState(
    (!isNaN(activeStrike)) ? activeStrike : 0.051
  );
  const [cExp, setCexp] = React.useState(
    expIsBlock 
      ? (activeExpiry && !isNaN(activeExpiry) ? activeExpiry : 200000)
      : (activeExpiry && !isNaN(activeExpiry) && (Number(activeExpiry) - Number(bb)) > 2000 
          ? (Number(activeExpiry) - Number(bb)) 
          : 200000)
  );
  const [cValue, setCValue] = React.useState(0);
  const [gContract, setgContract] = React.useState(NFTAddress);
  const [oPremium, setOPremium] = React.useState(cValue);
  const [oExp, setOExp] = React.useState(20000);
  const [isSellOrder, setIsSellOrder] = React.useState(String(orderType).toLowerCase() === 'offer');
  const [isCall, setIsCall] = React.useState(String(activePC).toLowerCase() === 'call');
  const [modalSelectedNFT, setModalSelectedNFT] = React.useState([]);
  const [open, setOpen] = React.useState(false);



  const [optionData, setOptionData] = React.useState({});


  // Effect to update order type when isSellOrder changes
  React.useEffect(() => {
    if (isSellOrder) {
      setOrderTypeState(ORDER_TYPES.SELL_SPECIFIC);
    } else {
      // Maintain existing buy order type (specific or general)
      setOrderTypeState(isSpecific ? ORDER_TYPES.BUY_SPECIFIC : ORDER_TYPES.BUY_GENERAL);
    }
  }, [isSellOrder]);

  async function updateOptionData(optionId){
    const optData = await getOption(optionId);
    setOptionData(optData);
  }

// Update the option data fetching effect
React.useEffect(() => {
  if (optionID !== null && optionID !== undefined) {
    updateOptionData(optionID);
  }
}, [optionID]);

// Update option details when optionData changes
React.useEffect(() => {
  if (optionData && optionData.optionId !== null && optionData.optionId !== undefined && 
      (orderTypeState === ORDER_TYPES.BUY_SPECIFIC || orderTypeState === ORDER_TYPES.SELL_SPECIFIC)) {
    setSelectedOptionDetails({
      optionId: optionData.optionId,
      strike: optionData.strike,
      isCall: optionData.isCall,
      expiry: optionData.expiry,
      underlyingId: optionData.underlyingId,
      contract: optionData.contractAddress || underlyingContract
    });
    setModalSelectedNFT([optionData.optionId]);
  }
}, [optionData, orderTypeState]);

// Modify the existing optionID effect to handle both sell and buy specific cases
React.useEffect(() => {
  if (optionID !== null && optionID !== undefined && 
      (orderTypeState === ORDER_TYPES.SELL_SPECIFIC || orderTypeState === ORDER_TYPES.BUY_SPECIFIC)) {
    // First set the basic details we know from props
    setSelectedOptionDetails({
      optionId: optionID,
      strike: activeStrike,
      isCall: String(activePC).toLowerCase() === 'call',
      expiry: activeExpiry,
      underlyingId: activeUnderlyingID,
      contract: underlyingContract
    });
    setModalSelectedNFT([optionID]);
    
    // Then fetch and update with complete details from supabase
    updateOptionData(optionID);
  }
}, [optionID, orderTypeState]);
     // Update option selection visibility logic
  const showOptionSelection = orderTypeState === ORDER_TYPES.SELL_SPECIFIC;
     // Update the showOptionDetails logic

  // Simplify the showOptionDetails logic
  const showOptionDetails = (orderTypeState === ORDER_TYPES.SELL_SPECIFIC || orderTypeState === ORDER_TYPES.BUY_SPECIFIC); //&& selectedOptionDetails.optionId != null;

  const allowOptionInput = orderTypeState === ORDER_TYPES.BUY_GENERAL;

  // Handle option selection
  const handleOptionSelection = (orderId, optionId, e) => {
    setModalSelectedNFT(optionId);
    console.log("handleOptionSelection", orderId, optionId, e);
    
    // Check if the strike value needs to be converted
    let formattedStrike;
    try {
      // First try to format it - this will throw if it's already formatted
      formattedStrike = ethers.formatEther(String(activeStrike));
    } catch (error) {
      // If formatting fails, it's likely already in ether format
      formattedStrike = activeStrike;
    }

    // Update option details when a new option is selected
    setSelectedOptionDetails({
      optionId: optionId,
      strike: formattedStrike,
      isCall: isCall,
      expiry: activeExpiry,
      underlyingId: activeUnderlyingID,
      contract: underlyingContract
    });
  };

  function toggleIsSell() {
    setIsSellOrder(!isSellOrder);
  }

  function toggleIsCall() {
    setIsCall(!isCall);
  }

// Update getActiveOptionId function
const getActiveOptionId = () => {
  if (selectedOptionDetails.optionId !== null && selectedOptionDetails.optionId !== undefined) {
    return selectedOptionDetails.optionId;
  }
  if (optionID !== null && optionID !== undefined) {
    return optionID;
  }
  return Object.values(modalSelectedNFT)[0] ?? null;
};


// Update renderOptionDetails function
const renderOptionDetails = () => {
  if (!isSpecific) {
    return 'Option details';
  } else {
    const activeOptionId = getActiveOptionId();
    if (activeOptionId !== null && activeOptionId !== undefined) {
      return `Option ID #${String(activeOptionId)}`;
    }
    return 'Select Option';
  }
};
 // Update handleCreateOrder function
const handleCreateOrder = () => {
  const activeOptionId = getActiveOptionId();
  if ((activeOptionId === null || activeOptionId === undefined) && isSellOrder) {
    setLoadingStatus({
      visible: true,
      loadingText: 'Please select an option to sell first'
    });
    return;
  }

  if (isSellOrder || isSpecific) {
    createOrder(activeOptionId, oPremium, oExp, isCall, isSellOrder, setLoadingStatus);
  } else {
    createGeneralBid(
      gContract,
      cStrike,
      isCall,
      cExp,
      oPremium,
      oExp,
      setLoadingStatus,
      expIsBlock
    );
  }
};

  return (
    <Modal
      open={openT}
      onClose={() => setOpenT(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding: 1 }}>
          <Card sx={{ maxWidth: '100%', padding: 2 }}>
                                   <Typography variant="h5">
                                    { isSpecific ? 
                                    '' :
                                      <IOSSwitch
                                    switchPC = {isSellOrder ? 'Put' : 'Call'}
                                    handlePCswitch = {toggleIsSell}
                                    isMintForm = {false}
                                    checkedText = ""
                                    uncheckedText = ""
                                    />
                                    
                                    } {isSellOrder ? 'Order to Sell' : 'Order to Buy'} 
                                    
                                    </Typography>

                                    {/* Update Option Selection Section */}
          {showOptionSelection && (
          <>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setOpen(true)}
            >
              {getActiveOptionId()
                ? `Sell option #${getActiveOptionId()}`
                : 'Select Option to Sell'}
            </Button>
                <SelectNFT
                  setModalSelectedNFT={handleOptionSelection}
                  modalSelectedNFT={modalSelectedNFT}
                  userAccount={userAccount}
                  filterContract={null}
                  filterIsCall={null}
                  filterStrike={null}
                  filterExpiry={null}
                  orderId={null}
                  theme={theme}
                  mode={mode}
                  toggleTabBar={toggleTabBar}
                  setOpen={setOpen}
                  open={open}
                />
              </>
            )}

            {/* Display Option Details */}
            <Divider textAlign="left">
              {renderOptionDetails()}
            </Divider>

            <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
  <Grid container spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
    alignItems="stretch" columns={{ xs: 2, sm: 2, md: 2 }}>
    
    <Grid item xs={2}>
      {showOptionDetails ? (
        <>
          <Typography align="center" variant='caption'>
            Option type: { selectedOptionDetails.optionId ? (!selectedOptionDetails.isCall ? 'Put' : 'Call') : ''} 
          </Typography>
          <br/>
          {selectedOptionDetails.underlyingId ?
            <Typography align="center" variant='caption'>
              Underlying ID: #{selectedOptionDetails.underlyingId} 
              <Link
                target="_blank" 
                href={`https://testnets.opensea.io/assets/sepolia/${selectedOptionDetails.contract}/${selectedOptionDetails.underlyingId}`}
              >
                <img src={OSwhite} width={15} style={{marginLeft:8, marginBottom:'-5px'}}/>
              </Link>
            </Typography>
            :
            <Typography align="center" variant='caption'>
              Underlying ID:
              </Typography>
          }

          <br/>
          {selectedOptionDetails.expiry ?
          <Typography align="center" variant='caption'>
          Contract address:  
          <Link 
            color={(activePC === 'call') ? 'primary': 'secondary'} 
            style={{paddingLeft:4}}
            target="_blank" 
            href={`https://sepolia.etherscan.io/address/${selectedOptionDetails.contract}`}
          > 
            {String(selectedOptionDetails.contract).substring(0,5) + '...' + String(selectedOptionDetails.contract).substring(38,50)+'  '} 
          </Link> 
          <Link
            target="_blank" 
            href={`https://testnets.opensea.io/assets/sepolia/${selectedOptionDetails.contract}`}
          >
            <img src={OSwhite} width={15} style={{marginLeft:8, marginBottom:'-5px'}}/>
          </Link>
          <Link
            target="_blank" 
            href={`https://sepolia.etherscan.io/address/${selectedOptionDetails.contract}`}
          >
            <img src={ESlogo} width={15} style={{marginLeft:8, marginBottom:'-5px'}}/>
          </Link>
        </Typography>
        :
        <Typography align="center" variant='caption'>
          Contract address:
        </Typography>
          }
            
          <br/>
          
          <Typography 
            align="center" 
            variant='caption' 
            sx={{height:0, display:'flex', alignContent:'center', margin:0, padding:0}}
          >
            Strike:
            <Box sx={{height:24, width:24, marginTop:'-5px'}}>
              <LogoSmall />
            </Box>
            {selectedOptionDetails.strike ? String(selectedOptionDetails.strike) : ''}
          </Typography>
          <br/>
          
          {selectedOptionDetails.expiry ?
              <Typography align="center" variant='caption'>
                Expiry: {selectedOptionDetails.expiry} ({selectedOptionDetails.expiry-bb} left, ~{convertBlocksToTime(selectedOptionDetails.expiry-bb)})
              </Typography>
            :
              <Typography align="center" variant='caption'>
                Expiry: (Select option)
              </Typography>
          }
        </>
      ) : (
        <>
          <IOSSwitch
            switchPC={!isCall ? 'Put' : 'Call'}
            handlePCswitch={toggleIsCall}
            isMintForm={false}
            checkedText="Call"
            uncheckedText="Put"
          />
          <br/>
          
          <TextField 
            id="outlined-basic" 
            label="Underlying Contract" 
            fullWidth
            variant="outlined" 
            defaultValue={gContract}
            onChange={e => setgContract(e.target.value)}
            sx={{paddingBottom:2}}
          />   
          
          <TextField 
            id="outlined-basic" 
            label="Strike (ETH)" 
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"><Logo /></InputAdornment>
              ),
            }} 
            variant="outlined" 
            defaultValue={cStrike}
            onChange={e => setCstrike(e.target.value)}
            sx={{paddingBottom:2}}
          />  
          
          <FormControl fullWidth color={switchPC === 'Call' ? 'primary' : 'secondary'}>
            <InputLabel id="expiry-label">Expiry (# blocks)</InputLabel>
            <Select 
              labelId="expiry-label" 
              id="expiry" 
              label="Expiry" 
              defaultValue={ activeExpiry ? parseInt(activeExpiry) : (blockMapping && Object.keys(blockMapping).length > 0 ? parseInt(Object.keys(blockMapping)[0]) : '')} 
              onChange={(e) => setCexp(e.target.value)}
            >
              {blockMapping && Object.entries(blockMapping).map(([key, value]) => (
                <MenuItem key={key} value={parseInt(key)} sx={{fontSize: 12}}>
                  Block {parseInt(key)} ({value.blocks.toLocaleString()} blocks left, {value.time})
                </MenuItem>
              ))}
              {!blockMapping && (
                <MenuItem value={cExp} sx={{fontSize: 12}}>
                  Block {cExp}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </>
      )}

      <Divider>
        Order Details
      </Divider>
    </Grid>

    {/* Rest of the code remains the same... */}
                                                
                                                
                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label={isSellOrder ? "Offer (ETH)" : "Bid (ETH)"} fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start"><Logo /></InputAdornment>
                                                    ),
                                                }} variant="outlined" 
                                                defaultValue={oPremium}
                                                onChange={e => setOPremium(e.target.value)}/>     
                                                </Grid>

                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label={"Order valid (# blocks)"} fullWidth
                                                variant="outlined" 
                                                defaultValue={oExp}
                                                onChange={e => setOExp(e.target.value)}/>     
                                                </Grid>

                                                 {/* Updated Create Order Button */}
                                                  <Grid item xs={2}>
                                                  <Button
                                                      variant="contained"
                                                      style={{
                                                        maxWidth: '100%',
                                                        maxHeight: '100%',
                                                        minWidth: '100%',
                                                        minHeight: '100%'
                                                      }}
                                                      onClick={handleCreateOrder}
                                                      disabled={(isSellOrder && (getActiveOptionId() === null || getActiveOptionId() === undefined)) || Number(oPremium) === 0}
                                                    >
                                                      {isSellOrder || isSpecific
                                                        ? `Create Order ${isSellOrder ? 'To Sell' : 'To Buy'} #${getActiveOptionId() ?? ''}`
                                                        : 'Create General Bid'}
                                                    </Button>
                                                  </Grid>
                                                                                            
                                                
                                                <Grid item xs={2} >
                                          { loadingStatus.loadingText != '' ? 
                                              <Box sx={{ width: '100%', minHeight:'10px' }}>
                                                <Typography sx={{fontSize:10}}>
                                                  {loadingStatus.loadingText}
                                                </Typography>
                                              </Box>
                                            : ''
                                              }
                                            { loadingStatus.visible ? 
                                              <Box sx={{ width: '100%', minHeight:'10px' }}>
                                                <LinearProgress color='primary'  />
                                              </Box>
                                            : ''
                                              }
                                                </Grid>

                                        </Grid>

                                    </Stack>
                                    </Card>
                                </Box>
        </Box>
      </Modal>
    
  );
}
