import * as React from 'react';
import {Box, Card, CardContent, Grid, TextField, Button, InputLabel, MenuItem, FormControl, Select, Typography} from '@mui/material';
import {SelectNFTmodal} from './SelectNFTModal';
import { ethers } from 'ethers'
import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json'
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json'
import {Logo} from './ethlogo.js';
import InputAdornment from '@mui/material/InputAdornment';
import IOSSwitch from './IOSwitch';
import {ContractModal} from './ContractModal';
import UploadFile from './IPFSupload';
import LinearProgress from './LinearProgress';

import { useConnectWallet } from '@web3-onboard/react'


export function MintForm(props) {


  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();


  const fakeBaycAddress = props.fakeBaycAddress
  const erc721OptionAddress = props.erc721OptionAddress 
  const amount   = props.amount
  const userAccount = props.userAccount
  const setUserAccount = props.setUserAccount
  const setAmount = props.setAmount
  const setBAYC = props.setBAYC
  const setOption = props.setOption
  const isTransferDisabled = props.isTransferDisabled
  const toggleDisabled = props.toggleDisabled
  const strike = props.strike
  const setStrike = props.setStrike
  //const currentOption  = props.currentOption
  //const  setCurrentOption = props.setCurrentOption
  const switchPC = props.switchPC;
  const setPC = props.setPC;
  const bb = props.bb;
  const blockMapping = props.blockMapping;
  
  const [expiry, setExpiry] = React.useState(10000);
  const [selectedNFTid, setSelectedNFTid] = React.useState(0);
  const [selectText, setSelectText] = React.useState('Select ERC721 from wallet');
  const [showHide, setShowHide] = React.useState(false);
  const [showTextOnly, setShowTextOnly] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState('Loading');
  const [NFTopen, NFTsetOpen] = React.useState(false);
  const [showNFTselect, setShowNFT] = React.useState(true);

  const inputRefStrike = React.useRef();
  

  React.useEffect( () => {
    if (switchPC == 'Call') {
      if (selectText.substring(0,4) == 'ID #') {   
        inputRefStrike.current.focus();
      }
    } else {
      inputRefStrike.current.focus();
    }
  }, [selectText, fakeBaycAddress]);


  React.useEffect(()=>{
    if (switchPC == 'Call') {
      if (!showNFTselect){
        setShowNFT(true);
      }
    } else {
      if (showNFTselect){
        setShowNFT(false);
      }
    }
  },[switchPC])
    
      async function _approveBAYC() {
        if (wallet) {
          let account = wallet.accounts[0].address;
          const provider = new ethers.BrowserProvider(wallet.provider, 'any')
          const signer = await provider.getSigner()
          //... reset of transaction
          console.log('account', account);
          const contract = new ethers.Contract(fakeBaycAddress, BAYC.abi, signer);
          console.log("Trying to approve..Minform78");
          const transation = await contract.approve(erc721OptionAddress, selectedNFTid);
          await transation.wait();
          console.log(`Approved erc721OptionAddress ${erc721OptionAddress}`);
          toggleDisabled();
      }
      }

      async function MintOption() {
        if (wallet) {
          let account = wallet.accounts[0].address;
          const ethersProvider = new ethers.BrowserProvider(wallet.provider, 'any')
          const signer = await ethersProvider.getSigner()
          //... reset of transaction
          const contract = new ethers.Contract(erc721OptionAddress, Option.abi, signer);
        console.log(`Trying to mint Option ${selectedNFTid} from ABI, signer ${signer}`)
        //const transation = await contract.writeOption(strike, 0, 0, currentOption, {value: ethers.parseEther("0.05")});
        setLoadingText(`Uploading data to IPFS`);
        // 1. Upload SVG to IPFS --> URL
        const expBlock = expiry;
        console.log(expBlock)
        console.log("Minting", selectedNFTid, switchPC);


        const nftId = switchPC == 'Call' ? selectedNFTid : 0;
        const PC = switchPC == 'Call' ? true : false; // PUT OR CALL
                                // (strike, contractAddress, writer, nftId, expiry, optType)
        const gg = await UploadFile(strike, fakeBaycAddress, account, nftId, expBlock, switchPC);
        console.log(gg);
        const writeFee = 500 * strike / 10000;
        console.log(writeFee);
        const Ovalue = switchPC == 'Call' ? ethers.parseEther(parseFloat(writeFee).toString()) : ethers.parseEther( (parseFloat(writeFee) + parseFloat(strike)).toString());
        console.log(Ovalue);
        // 3. Set tokenURI to (2.IPFShash)
        setLoadingText(`Minting option with IPFS tokenURI`);
        const transation = await contract.writeNFTonUnderlying(fakeBaycAddress, nftId, ethers.parseEther(strike),  expBlock, PC, gg, {value: Ovalue});
        // writeNFTonUnderlying(address cntrct_addr, uint _tokenId, uint _strike, uint _expiry) 
        await transation.wait();
        setLoadingText(`Option minted 🚀`);
        console.log(`${switchPC}@${strike}B${expBlock}  Option successfully sent to ${account}`);
        
      }
      }

      async function approveTransferAndMint() {
        setShowHide(true);
        try {
          if (switchPC == 'Call') {          
            setLoadingText(`Approving NFT with id ${selectedNFTid}`);
            await _approveBAYC();
          }
        } catch {console.log('Error Approve');
        setLoadingText(`Approvement failed, trying to mint`);
      }

        try{
          setLoadingText(`Minting ${switchPC.toLowerCase()} option`);
          //updateCurrentOption();
          await MintOption();
          //setLoadingText(``);
          setShowHide(false);
          setShowTextOnly(true);

        }catch{
          setLoadingText(`Failed while trying to mint 😔`);
          setShowHide(false);
          setShowTextOnly(true);
        }
      }    
      
      function checkNumber(value) {
        return ( (/^-?\d*(\.\d+)?$/.test(value)  && (value !== "") && (value > 0)) ? true : false);
      };

      function handlePCswitch() {
        if (switchPC == 'Call') {
            setPC('Put');
        } else {
            setPC('Call');
        }
    };

    return(
    <Card sx={{maxWidth:{xs:'100%', sm:'100%', lg:'100%'}, minWidth: {sm:'97%', sm:'90%', lg:'50%'}, padding:2}}>
      <CardContent>    
        <Box sx={{width:'100%', display:'flex', justifyContent:'left'}}>
          <IOSSwitch  switchPC={switchPC} userAccount={userAccount} handlePCswitch = {handlePCswitch} isMintForm = {true}
            checkedText = "Call"
            uncheckText = "Put"/>
        </Box>
          

          <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
          alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>

            <Grid item xs={2}
                      sm={ showNFTselect ? 2 : 4   } 
                       md={ showNFTselect ? 3 : 6   } >
                  <TextField fullWidth id="standard-basic" label="Contract adress" variant="outlined" 
                  onChange={e => setBAYC(e.target.value)} value={fakeBaycAddress}
                  color = {switchPC == 'Call' ? 'primary': 'secondary'}
                  InputProps={{ endAdornment: (
                    <InputAdornment position="end">
                      <ContractModal 
                        fakeBaycAddress={fakeBaycAddress} 
                        setBAYC={setBAYC} 
                        userAccount={userAccount}
                        NFTopen = {NFTopen}
                        NFTsetOpen = {NFTsetOpen}
                        switchPC={switchPC}
                        setPC={setPC}
                        inputRefStrike={inputRefStrike}
                      />
                    </InputAdornment>)}}
                  />
              </Grid>
              {
                showNFTselect &&
                  <Grid item xs={2}   md={3} sx={{height:{xs:'75px', md:'80px'}}}>
                  <SelectNFTmodal 
                    NFTopen = {NFTopen}
                    NFTsetOpen = {NFTsetOpen}
                    fakeBaycAddress = {fakeBaycAddress} 
                    erc721OptionAddress = {erc721OptionAddress} 
                    amount = {amount} 
                    userAccount={userAccount}
                    setUserAccount={setUserAccount}
                    setAmount={setAmount}
                    setBAYC={setBAYC}
                    setOption={setOption} 
                    strike = {strike}
                    setStrike={setStrike}
                    selectedNFTid={selectedNFTid}
                    setSelectedNFTid={setSelectedNFTid}
                    selectText = {selectText} 
                    setSelectText={setSelectText}
                    switchPC={switchPC}
                    setPC={setPC}
                    inputRefStrike={inputRefStrike}
                    isRP={false}
                  />  
                </Grid>
              }

              
              <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <div />
              </Grid>

              <Grid item xs={2} md={3} style={{ paddingTop: 20 }}>
                  <TextField id="outlined-basic" label="Strike (ETH)" fullWidth
                  InputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    startAdornment: (
                      <InputAdornment position="start"><Logo /></InputAdornment>
                    ),
                  }} variant="outlined" 
                  color = {switchPC == 'Call' ? 'primary': 'secondary'}
                  onChange={e => setStrike(e.target.value)}
                  inputRef={inputRefStrike}
                  />
              </Grid>

              <Grid item xs={2} md={3} style={{ paddingTop: 20 }}>
                  <FormControl  fullWidth  color = {switchPC == 'Call' ? 'primary': 'secondary'}>
                      <InputLabel id="expiry-label" >Expiry (# blocks)</InputLabel>
                      <Select labelId="expiry-label" id="expiry" label="Expiry" 
                      defaultValue={expiry}
                      onChange={(e)=> setExpiry(e.target.value)}
                     >
                       {Object.entries(blockMapping).map(([key, value]) => (
                        <MenuItem key={key} value={parseInt(key)} sx={{fontSize: 12}}>
                          Block {parseInt(key)} ({value.blocks.toLocaleString()} blocks left, {value.time})
                        </MenuItem>
                      ))}
                      </Select>
                  </FormControl>
              </Grid>

              <Grid item xs={6} style={{ paddingTop: 20 }}>
                  <Button variant='contained' color={switchPC == 'Call' ? 'primary': 'secondary'} 
                  style={{maxWidth: '100%', maxHeight: '100%', minWidth: '100%', minHeight: '100%'}}
                  onClick={() => approveTransferAndMint()}
                  disabled={!checkNumber(strike) || ( (selectText == 'Select ERC721 from wallet') && (switchPC == 'Call')  ) || !ethers.isAddress(userAccount)}>
                    {( ethers.isAddress(userAccount)) ? 'Mint' : 'Connect wallet'}
                  </Button>
              </Grid>
              {typeof strike != 'undefined' && <Grid item xs={6} style={{ paddingTop: 20 }}>
                  <Typography variant='body2' color={switchPC == 'Call' ? 'primary': 'secondary'} >
                    Write fee: {(strike*0.015).toFixed(4)} (1.5% of strike)
                  </Typography>
                  <Typography variant='body1' color={switchPC == 'Call' ? 'primary': 'secondary'} >
                    Transaction value: {switchPC == 'Call' ? `NFT + ${(strike*0.015).toFixed(4)}` : `${(strike*1.015).toFixed(4)}`}  + gas 
                  </Typography>
              </Grid>}
              <Grid item xs={6} style={{ paddingTop: 20 }}>
                <LinearProgress showHide = {showHide} showTextOnly={showTextOnly} loadingText={loadingText} switchPC={switchPC}/>
              </Grid>
          </Grid>
          
      </CardContent>
</Card>)};