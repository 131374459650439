import * as React from "react"


const OptionJSON = (props) => {
  let jsonContent = `{
    \"description\": \"Non-fungible Options on ERC721 tokens\", 
    \"external_url\": \"https://ztrike.io/\", 
    \"image\": \"https://ztrike.infura-ipfs.io/ipfs/${props.imageHash}\", 
    \"name\": \"Ztrike Option (${props.type})\",
    \"animation_url\": \"https://ztrike.infura-ipfs.io/ipfs/${props.imageHash}\", 
    \"attributes\":  [
        {
          \"trait_type\": \"Underlying Contract\", 
          \"value\": \"${props.contractAddress}\"
        }, 
        {
            \"trait_type\": \"Underlying ID\", 
            \"value\": \"${props.nftId}\"
          }, 
          {
            \"trait_type\": \"Strike Price\", 
            \"value\": \"${props.strike}\"
          }, 
          {
            \"trait_type\": \"Expiry (# block)\", 
            \"value\": \"${props.expiry}\"
          }, 
          {
            \"trait_type\": \"Option Type\", 
            \"value\": \"${props.type}\"
          }, 
          {
            \"trait_type\": \"Option writer\", 
            \"value\": \"${props.writer}\"
          }
    ] 
  }`;
  //console.log(jsonContent);
  return (
    JSON.stringify(jsonContent)
    //jsonContent
    );
};


export default OptionJSON