import { makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    container : {
        backgroundColor: theme.palette.background.default, 
        padding: theme.spacing(8, 0 ,6)
    },
    container2: {
        padding: theme.spacing(8, 0 ,6)
    },
    icon : {
        marginRight: '20px',
    },
    buttons:{
        marginTop: '40px',
    },
    cardGrid: {
        padding: '20px 0'
    },
    card : { 
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    cardMedia: {
        paddingTop: '56.25%',
        flexGrow: 1
    },
    cardContent: {},
    footer: {
        backgroundColor: theme.palette.background.default,
        padding: '50px'
    }
}));

export default useStyles;