import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import App from './App';
import Frontpage from "./routes/Frontpage";
import Docs from "./routes/Docs";
import Calc from "./routes/Calculator";
import Coin from './routes/Coin';
//import Marketplace from "./routes/Marketplace2";

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // 

root.render(
    <BrowserRouter>
    <Routes>
      <Route path="/app" element={<App />} />
      <Route path="/" element={<Frontpage />} />
      <Route path="/docs" element={<Docs />} />
      <Route path="/calc" element={<Calc />} />
      <Route path="/coin" element={<Coin />} />
    </Routes>
  </BrowserRouter>);
