import React from 'react';
import {Helmet} from "react-helmet";
import { Box, Typography, Button, Container, Grid, Paper, LinearProgress, CssBaseline, AppBar, Toolbar, ButtonGroup, Stack, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TokenIcon from '@mui/icons-material/Token';
import PaidIcon from '@mui/icons-material/Paid';
import {AppBarModule} from '../AppBarModuleICO';
import ZtrikePattern from '../ZtrikePattern.js';
import * as ReactDOMServer from "react-dom/server";
import ZtrikeNameDark from '../logos/ZvgZtrikeNameDark.svg';
import SpinningCoin from '../components/SpinningCoin';
import GrainyBox from '../components/GrainyBox';

const GradientText = styled(Typography)`
  background: linear-gradient(90deg, #264a39 0%, #001f14 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const FeatureCard = styled(Paper)`
  padding: 2rem;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  color: white;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  &:hover {
    transform: translateY(-5px);
  }

  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: white;
    opacity: 0.08;
    filter: url(#noise) contrast(150%) brightness(150%);
    mixBlendMode: overlay;
    z-index: -1;
  }
`;

const RoadmapItem = styled(Paper)`
  padding: 1rem;
  margin: 1rem 0;
  border-left: 4px solid ${props => props.theme.palette.primary.main};
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  
  > * {
    position: relative;
    z-index: 2;
    color: white;
  }
`;

const MintButton = styled(Button)`
  position: relative;
  border-radius: 30px;
  border: 0;
  height: 60px;
  padding: 0 50px;
  font-size: 24px;
  font-weight: bold;
  text-transform: none;
  transition: all 0.3s ease;
  overflow: hidden;
  background: linear-gradient(45deg, #264a39 30%, #001f14 90%);
  color: white;
  z-index: 3;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(45deg, #001f14 30%, #264a39 90%);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -2;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: white;
    opacity: 0.08;
    filter: url(#noise) contrast(120%) brightness(120%);
    mixBlendMode: 'overlay';
    z-index: -1;
    pointer-events: none;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.6);
    
    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
`;

const TokenMetrics = styled(Box)`
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  margin: 2rem 0;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 3;
`;

const NoiseFilter = () => (
  <svg style={{ display: 'none' }}>
    <filter id="noise">
      <feTurbulence type="fractalNoise" baseFrequency="0.8" numOctaves="4" stitchTiles="stitch"/>
      <feColorMatrix type="saturate" values="0"/>
      <feComponentTransfer>
        <feFuncR type="linear" slope="2" intercept="-0.5"/>
        <feFuncG type="linear" slope="2" intercept="-0.5"/>
        <feFuncB type="linear" slope="2" intercept="-0.5"/>
      </feComponentTransfer>
    </filter>
  </svg>
);

const useCounter = (end, duration) => {
  const [count, setCount] = React.useState(0);
  const [isVisible, setIsVisible] = React.useState(false);
  const counterRef = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (!isVisible) return;

    const steps = 60;
    const increment = end / steps;
    const timePerStep = duration * 1000 / steps;
    let current = 0;

    const timer = setInterval(() => {
      current += increment;
      if (current >= end) {
        setCount(end);
        clearInterval(timer);
      } else {
        setCount(current);
      }
    }, timePerStep);

    return () => clearInterval(timer);
  }, [end, duration, isVisible]);

  return { count, counterRef };
};

export default function Coin() {
  const [mode, setMode] = React.useState('light');
  const btnColor = mode === 'light' ? 'black' : 'white';

  function handleMode(){
    setMode(mode === "light" ? "dark" : "light");
  }

  const lightMode = {
    primary: {
      main: '#264a39',
      light: '#4c7561',
      dark: '#001f14',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f44336',
      light: '#ff7961',
      dark: '#ba000d',
      contrastText: '#fff',
    },
    background: {
      default: '#fcfcfc',
      paper: '#dbdbdb',
    },
  };

  const darkMode = {
    primary: {
      main: '#264a39',
      light: '#4c7561',
      dark: '#001f14',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f44336',
      light: '#ff7961',
      dark: '#ba000d',
      contrastText: '#fff',
    },
    background: {
      default: '#414345',
      paper: '#232526',
    },
  };

  const theme = React.useMemo(
    () => createTheme({
      palette: {
        mode,
        ...(mode === 'light' ? lightMode : darkMode),
      },
      typography: {
        fontFamily: 'Inter',
      },
      shape: {
        borderRadius: 12,
      },
      custom: {
        gradient1: mode === 'dark'
          ? "radial-gradient(circle at top, #414345, #232526)"
          : "radial-gradient(circle at top, #fcfcfc, #dbdbdb)",
      },
    }), [mode]);

  // Create SVG pattern background
  const svgString = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ZtrikePattern 
        lightColor={mode==='light' ? '#dbdbdb' : '#232526'}
        darkColor={mode==='light' ? '#ababab' : '#414345'}
        opacity="0.2"
      />
    )
  );

  const tokensCounter = useCounter(65, 2.5);
  const raisedCounter = useCounter(6500, 2.5);
  const daysCounter = useCounter(30, 2.5);

  const handleMint = () => {
    console.log('Mint button clicked');
    // Add your minting logic here
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NoiseFilter />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ztrike Coin | ICO</title>
        <style>{'body { background-color: black; }'}</style>
        <style>{`
          body { background-color: black; }
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
            40% { transform: translateY(-20px); }
            60% { transform: translateY(-10px); }
          }
        `}</style>
      </Helmet>

      <div style={{ background: theme.custom.gradient1 }}>
        <AppBarModule
          handleMode={handleMode}
          mode={mode}
        />
        
        <main>
          <div>
            {/* Hero Section with Mint Card */}
            <Box sx={{ 
              minHeight: '90vh', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center',
              color: mode === 'light' ? 'black' : 'white',
              textAlign: 'center',
              p: 4,
              position: 'relative',
              overflow: 'hidden'
            }}>
              <Container maxWidth="lg">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3, mt:10 }}>
                  <Box
                    component="img"
                    sx={{
                      maxWidth: {xs:'70%', sm:'80%'}
                    }}
                    alt="Ztrike"
                    src={mode === 'dark' ? ZtrikeNameDark : ZtrikeNameDark}
                  />
                  <GradientText variant="h4" sx={{ ml: 2, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                    COIN
                  </GradientText>
                </Box>
                
                <Typography variant="h6" sx={{ mb: 4 }}>
                  The Future of Digital Asset Financialization
                </Typography>

                {/* Mint Section */}
                {/* Update the Mint Section Box component */}
                <GrainyBox>
                <Typography variant="h6" sx={{ 
                    mb: 3, 
                    background: theme.custom.gradient1,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                }}>
                    TOKEN LAUNCH
                </Typography>
                <Typography variant="h5" sx={{ mb: 4 }}>
                    1 ETH = 10,000 ZTRK
                </Typography>
                <MintButton onClick={handleMint}>
                    MINT ZTRK
                </MintButton>
                
                <TokenMetrics>
                    <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h4" sx={{ color: 'white' }} ref={tokensCounter.counterRef}>
                        {Math.round(tokensCounter.count)}%
                        </Typography>
                        <Typography sx={{ color: 'white' }}>Tokens Sold</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h4" sx={{ color: 'white' }} ref={raisedCounter.counterRef}>
                        ${Math.round(raisedCounter.count).toLocaleString()}
                        </Typography>
                        <Typography sx={{ color: 'white' }}>Raised</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h4" sx={{ color: 'white' }} ref={daysCounter.counterRef}>
                        {Math.round(daysCounter.count)} Days
                        </Typography>
                        <Typography sx={{ color: 'white' }}>Left</Typography>
                    </Grid>
                    </Grid>
                </TokenMetrics>
                </GrainyBox>

                {/* Terminal-style Info Container */}
                <GrainyBox gradientOrder="alternate2">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'flex-start', md: 'center' },
                      justifyContent: 'space-between',
                      gap: 2,
                      px: 3,
                      py: 2,
                      color: '#ffffff'
                    }}
                  >
                    <Typography>TOTAL SUPPLY: 1,000,000 ZTRK</Typography>
                    <Typography>CONTRACT: 0x3222...8f91</Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Link href="#" sx={{ color: '#ffffff', textDecoration: 'underline' }}>Etherscan</Link>
                      <Link href="#" sx={{ color: '#ffffff', textDecoration: 'underline' }}>Docs</Link>
                      <Link href="#" sx={{ color: '#ffffff', textDecoration: 'underline' }}>Audit</Link>
                    </Box>
                  </Box>
                </GrainyBox>
              </Container>
            </Box>

            {/* Token Utility Section */}
            <Container maxWidth="lg" sx={{ my: 8, color: mode === 'light' ? 'black' : 'white' }}>
              <Typography variant="h3" sx={{ mb: 6, textAlign: 'center' }}>
                ZTRK Token Utility
              </Typography>
              
              <GrainyBox gradientOrder="alternate">
                <Grid container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <FeatureCard>
                      <PaidIcon sx={{ fontSize: 40, mb: 2, color: 'white' }} />
                      <Typography variant="h6" gutterBottom>
                        Protocol Revenue Share
                      </Typography>
                      <Typography>
                        All fees generated across Ztrike protocols are distributed proportionally to ZTRK holders, creating a sustainable revenue stream for token holders
                      </Typography>
                    </FeatureCard>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FeatureCard>
                      <AccountBalanceIcon sx={{ fontSize: 40, mb: 2, color: 'white' }} />
                      <Typography variant="h6" gutterBottom>
                        Protocol Governance
                      </Typography>
                      <Typography>
                        ZTRK holders vote on all protocol parameters including fee structures, liquidity incentives, and protocol upgrades, ensuring true decentralization
                      </Typography>
                    </FeatureCard>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FeatureCard>
                      <ShowChartIcon sx={{ fontSize: 40, mb: 2, color: 'white' }} />
                      <Typography variant="h6" gutterBottom>
                        Liquidity Mining Rewards
                      </Typography>
                      <Typography>
                        Earn additional yield on top of protocol revenue by providing liquidity to option pools, maximizing returns for active participants
                      </Typography>
                    </FeatureCard>
                  </Grid>
                </Grid>
              </GrainyBox>
            </Container>

            {/* Features Section */}
            <Container maxWidth="lg" sx={{ my: 8, color: mode === 'light' ? 'black' : 'white' }}>
              <Typography variant="h3" sx={{ mb: 6, textAlign: 'center' }}>
                The Ztrike Ecosystem
              </Typography>
              <GrainyBox>
                <Grid container spacing={4} sx={{ position: 'relative', zIndex: 3 }}>
                  <Grid item xs={12} md={3}>
                    <FeatureCard>
                      <SwapHorizIcon sx={{ fontSize: 40, mb: 2, color: 'white' }} />
                      <Typography variant="h6" gutterBottom>
                        NFT Options Trading
                      </Typography>
                      <Typography>
                        Revolutionary protocol for trading options on NFTs and digital assets
                      </Typography>
                    </FeatureCard>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FeatureCard>
                      <AccountBalanceIcon sx={{ fontSize: 40, mb: 2, color: 'white' }} />
                      <Typography variant="h6" gutterBottom>
                        Asset Fractionalization
                      </Typography>
                      <Typography>
                        Split valuable assets into tradeable tokens for increased liquidity
                      </Typography>
                    </FeatureCard>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FeatureCard>
                      <ShowChartIcon sx={{ fontSize: 40, mb: 2, color: 'white' }} />
                      <Typography variant="h6" gutterBottom>
                        AMM Pools
                      </Typography>
                      <Typography>
                        Automated market making for efficient trading of tokenized assets
                      </Typography>
                    </FeatureCard>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FeatureCard>
                      <TokenIcon sx={{ fontSize: 40, mb: 2, color: 'white' }} />
                      <Typography variant="h6" gutterBottom>
                        RWA Tokenization
                      </Typography>
                      <Typography>
                        Transform real-world assets into tradeable digital tokens
                      </Typography>
                    </FeatureCard>
                  </Grid>
                </Grid>
              </GrainyBox>
            </Container>

            {/* Roadmap Section */}
            <Container maxWidth="lg" sx={{ my: 8, pb: 8, color: mode === 'light' ? 'black' : 'white' }}>
              <Typography variant="h3" sx={{ mb: 6, textAlign: 'center' }}>
                Roadmap
              </Typography>
              <GrainyBox gradientOrder="alternate2">
                <Stack spacing={3}>
                  <RoadmapItem>
                    <Typography variant="h6" >Q2 2024 - Foundation</Typography>
                    <Typography>
                      • Launch of Ztrike NFT Options Trading Platform
                      • ICO Launch
                      • Partnership announcements
                    </Typography>
                  </RoadmapItem>
                  <RoadmapItem>
                    <Typography variant="h6" >Q3 2024 - Expansion</Typography>
                    <Typography>
                      • NFT Fractionalization Protocol
                      • Integration with major NFT marketplaces
                      • Launch of Ztrike AMM pools
                    </Typography>
                  </RoadmapItem>
                  <RoadmapItem>
                    <Typography variant="h6" >Q4 2024 - Innovation</Typography>
                    <Typography>
                      • Real World Asset tokenization platform
                      • Managed NFT funds
                      • Cross-chain integration
                    </Typography>
                  </RoadmapItem>
                </Stack>
              </GrainyBox>
            </Container>
          </div>
        </main>
      </div>
    </ThemeProvider>
  );
}