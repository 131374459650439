
var bs = require("black-scholes");

export function blackScholes(S, K, tBlocks, ivol, rf, putCall){
    return bs.blackScholes(S, K, tBlocks/2302080, ivol/100, rf, putCall.toLowerCase()).toFixed(3).toFixed(3)
}

// call it like: blackScholes(cFloor, cStrike, cExp, cVol/100, .05, activePC)

export function bsIVOL(){
    console.log("Not implemented");
}
