import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

const gradientPresets = {
  'default': [
    'rgba(255, 0, 128, 0.7)',    // red
    'rgba(0, 255, 128, 0.7)',    // green
    'rgba(0, 128, 255, 0.7)',    // blue
    'rgba(180, 255, 0, 0.5)',    // yellow
  ],
  'alternate': [
    'rgba(0, 128, 255, 0.7)',    // blue
    'rgba(180, 255, 0, 0.5)',    // yellow
    'rgba(255, 0, 128, 0.7)',    // red
    'rgba(0, 255, 128, 0.7)',    // green
  ],
  'alternate2': [
    'rgba(0, 128, 255, 0.7)',    // blue
    'rgba(0, 0, 0, 0.7)',    // blue
    'rgba(255, 0, 128, 0.7)',    // red
    'rgba(0, 255, 128, 0.7)',    // green
  ]
};

const GrainyBox = ({ children, gradientOrder = 'default' }) => {
  const [scrollOffset, setScrollOffset] = useState(0);

  useEffect(() => {
    let rafId;
    let currentOffset = 0;

    const handleScroll = () => {
      rafId = requestAnimationFrame(() => {
        const targetOffset = window.scrollY * 0.2;
        currentOffset += (targetOffset - currentOffset) * 0.05;
        setScrollOffset(currentOffset);
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      cancelAnimationFrame(rafId);
    };
  }, []);

  const colors = gradientPresets[gradientOrder] || gradientPresets.default;

  return (
    <Box sx={{ 
      my: 6,
      p: 4,
      borderRadius: '20px',
      position: 'relative',
      isolation: 'isolate',
      overflow: 'hidden',
      background: 'linear-gradient(125deg, rgba(100,100,100,1), rgba(100,100,100,1))',
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        background: `
          radial-gradient(circle at top left, ${colors[0]}, transparent 50%),
          radial-gradient(circle at top right, ${colors[1]}, transparent 50%),
          radial-gradient(circle at bottom right, ${colors[2]}, transparent 50%),
          radial-gradient(circle at bottom left, ${colors[3]}, transparent 50%)
        `,
        mixBlendMode: 'overlay',
        zIndex: 1,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        inset: 0,
        background: 'white',
        opacity: 0.12,
        filter: 'url(#noise) contrast(150%) brightness(150%)',
        mixBlendMode: 'overlay',
        zIndex: 2,
      }
    }}>
      <Box
        component="img"
        src="/favicon.svg"
        sx={{
          position: 'absolute',
          top: `calc(50% - ${scrollOffset}px)`,
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(0deg)',
          width: '2500px',
          opacity: 0.05,
          zIndex: 3,
          pointerEvents: 'none',
          transition: 'top 0.1s ease-out'
        }}
      />
      {children}
    </Box>
  );
};

export default GrainyBox;