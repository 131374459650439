import React from 'react';
import { Typography, Box } from '@mui/material';

const formatBlockNumber = (blockNumber) => {
  const numberString = blockNumber.toString();
  const firstThree = numberString.slice(0, 3);
  const remaining = numberString.slice(3);
  return { firstThree, remaining };
};

const FormattedBlockNumber = ({ blockNumber, fontSize = 12 }) => {
  const { firstThree, remaining } = formatBlockNumber(blockNumber);

  return (
    <Box component="span" sx={{ display: 'inline-flex', alignItems: 'baseline' }}>
      <Typography fontSize={fontSize} component="span">
        {firstThree}
      </Typography>
      <Typography 
        fontSize={`${fontSize * 0.7}px`} 
        component="span" 
        sx={{ lineHeight: 1 }}
      >
        {remaining}
      </Typography>
    </Box>
  );
};

export default FormattedBlockNumber;