import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

const SearchableContractInput = ({ contracts = [], onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const getOptionLabel = (option) => {
    return option !== null && option !== undefined ? String(option) : '';
  };

  const filterOptions = (contracts, { inputValue }) => {
    return Array.isArray(contracts) 
      ? contracts.filter((contract) =>
          String(contract).toLowerCase().includes(inputValue.toLowerCase())
        )
      : [];
  };

  return (
    <Autocomplete
      options={Array.isArray(contracts) ? contracts : []}
      renderInput={(params) => <TextField {...params} label="Underlying contract" />}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      value={value}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => getOptionLabel(option) === getOptionLabel(value)}
      freeSolo
      filterOptions={filterOptions}
      openOnFocus
      clearOnBlur={false}
      blurOnSelect={false}
      size="small"
    />
  );
};

export default SearchableContractInput;