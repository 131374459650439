import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Card, CardActions, CardContent, Skeleton} from '@mui/material'
import ModalCardMedia from './ModalCardMedia';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper(props) {
  //const { address, isConnecting, isDisconnected } = useAccount();

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStepNFTid, setactiveStepNFTid] = React.useState('');
  const [listError, setListError] = React.useState(false);
  const listOfNfts = props.listOfNfts;
  const maxSteps = listOfNfts.length;
  const fakeBaycAddress = props.fakeBaycAddress
  const handleSelect = props.handleSelect
  const tokenURI = props.tokenURI
  const currentTokenURI = props.currentTokenURI
  const currImage = props.currImage
  const getImageFromMetadata = props.getImageFromMetadata
  const switchPC = props.switchPC;
  const setCurrImage = props.setCurrImage;
  const placeholder = props.placeholder;

  React.useEffect(
    () => {
      console.log("currentTokenURI", currentTokenURI);
      getImageFromMetadata(currentTokenURI);}, 
    [currentTokenURI]
  );

  React.useEffect(
    () => {tokenURI(activeStepNFTid);}, [activeStepNFTid]
  );

  React.useEffect(
    () => {
      //setCurrImage('');
      if (listOfNfts.length == 0) {
        console.log('Error []');
        setListError(true);
      } else {
        setactiveStepNFTid(listOfNfts[activeStep].id);
        setListError(false);
      }
      
    }, [activeStep, listOfNfts]
  );

  
  const handleNext = () => {
    setCurrImage(placeholder);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setactiveStepNFTid(listOfNfts[activeStep].id);
    //handleImageState();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    
    //handleImageState();
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    setactiveStepNFTid(listOfNfts[activeStep].id);
    //handleImageState();
  };

  //console.log(listOfNfts);
  if (listOfNfts.length == 0) {
    return (
      <Box sx={{ maxWidth: 400, flexGrow: 1, borderRadius: 12 }}>
          { !listError && 
          <Typography variant="body2">
          Loading NFTs
        </Typography>}
        { listError && 
          <Typography variant="body2">
          No NFTs found
        </Typography>}

      </Box> 
    );
  } else {
  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1, borderRadius: 12 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval = {10000}
        autoplay = {false}
      >

      
      {listOfNfts.map((step, index) => (
        <div key={step.id}>
          {Math.abs(activeStep - index) <= 1 ? (
              <Box sx={{ width: '100%', borderRadius: 12, maxHeight: '80%'}} spacing={2} padding={1} >
                      <Card sx={{ width: "1/2" }}>
                          { currImage === 'placeholder' ? 
                            <Skeleton variant="rounded" width={200} height={350} sx={{margin:1}}/>
                          :
                            <ModalCardMedia currImage={currImage}/>
                          }
                          
                          <CardContent>
                              <Typography gutterBottom variant="h5" component="div">
                              # {step.id}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                          Contract: {fakeBaycAddress.slice(0, 5)}..{fakeBaycAddress.slice(39)}
                              </Typography>
                              <Typography variant="body2">
                                URI: {currImage}
                              </Typography>

                              
                          </CardContent>
                          <CardActions>
                              <Button size="small" variant='contained' color={switchPC == 'Call' ? 'primary': 'secondary'}
                              onClick={() => handleSelect(step.id)}>Select</Button>
                          </CardActions>
                      </Card>
                  </Box>
          ) : null}
        </div>
      ))}

        
      </AutoPlaySwipeableViews>
      <MobileStepper
        sx={{ borderRadius: 4, background:'transparent'}}
        steps={Math.min(maxSteps,7)}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            variant='contained'
            size="small"
            onClick={handleNext}
            color={switchPC == 'Call' ? 'primary': 'secondary'}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button variant='contained' size="small" 
          color={switchPC == 'Call' ? 'primary': 'secondary'}
          onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );}
}

export default SwipeableTextMobileStepper;