import React from 'react';
import {Helmet} from "react-helmet";
import { createTheme, ThemeProvider } from '@mui/material';
//import { styled } from '@mui/material/styles';
import useStyles from '../styles';
import { Typography, Button, IconButton, Stack, Box, CssBaseline, Container, Card, Grid, TextField, InputLabel, MenuItem, FormControl, Select} from '@mui/material'

//import { createTheme, ThemeProvider } from '@mui/material/styles';
import {AppBarModule} from '../AppBarModuleFP';
import {AppBarFooter} from '../AppBarFooter';
import {Logo} from '../ethlogo.js';
import InputAdornment from '@mui/material/InputAdornment';
import OSwhite from '../logos/OSwhite.svg' ;
import ESlogo from '../logos/etherscan-logo-light-circle.svg';

import {Twitter, Percent, HourglassTop} from '@mui/icons-material';
import Icon from '@material-ui/core/Icon';

import { useState } from 'react';

import { Link } from "react-router-dom";

import IOSSwitch from '../IOSwitch';


const Calc = () => {
    const theme = createTheme({
        palette: {
            mode: 'dark',
            background: {default: 'gray'},    
         primary: {
            light: '#757ce8',
            main: '#264a39',
            dark: '#607d8b',
            contrastText: '#fff',
          },
          secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#e64a19',
            contrastText: '#000',
          },
        },
        typography: {
            fontFamily: 'Inter',
          },
          shape: {
            borderRadius: 12,
          }, 
      });
const [userAccount, setUserAccount] = useState()
const [fakeBaycAddress, setBAYC] = useState('0x0d9d1354A3dF52DC22b20b96F221E0FC1e8F6d40')
const [erc721OptionAddress, setOption] = useState('0x0792e69D2d3449895f047177E8Df2beEB05B09A6')
const [isTransferDisabled, setIsTransferDisabled] = useState(false)


const [cFloor, setCfloor] = useState(1);
const [cStrike, setCstrike] = useState(1);
const [cVol, setCvol] = useState(175);
const [cExp, setCexp] = useState(100000);

const [switchPC, setPC] = useState('Call');

function handleDisableTransfer() {
    console.log("Toggled transfer");
    setIsTransferDisabled(!isTransferDisabled);
};

const gradient1 = "radial-gradient(circle at top, #414345, #232526)";

var bs = require("black-scholes");

function handlePCswitch() {
    if (switchPC == 'Call') {
        setPC('Put');
    } else {
        setPC('Call');
    }
};


// const cards1 = [1, 2]; // Cards for option list

    const classes = useStyles(theme);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{ background: gradient1}} >
            <Helmet>
                <meta charSet="utf-8" />
                <title> Ztrike | Calc</title>
                <style>{'body { background-color: black; }'}</style>
            </Helmet>


                <AppBarModule
                                            fakeBaycAddress = {fakeBaycAddress} 
                                            erc721OptionAddress = {erc721OptionAddress} 
                                            userAccount={userAccount}
                                            setUserAccount={setUserAccount}
                                            switchPC={switchPC}
                
                />
                <main >
                    <div className={classes.container2} >
                        <br/><br/>                        
                        <Container maxWidth='md'>

                            <Typography variant='h4' >
                            Calculator
                            </Typography>
                            <Typography variant='h5' >
                            Estimate the fair value of NFT options based on the Black-Scholes model
                            </Typography>
                        </Container>
                        </div>
                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding:1}}>
                                    <Card sx={{maxWidth:{sm:'80%', lg:'50%'}, minWidth: {sm:'80%', lg:'50%'}, padding:2}}>
                                    <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                                    <IOSSwitch  switchPC={switchPC} userAccount={userAccount} handlePCswitch = {handlePCswitch}/>
                                        <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
                                            alignItems="stretch" columns={{ xs: 2, sm: 2, md: 2 }}>
                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label="Floor price (ETH)" fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start"><Logo /></InputAdornment>
                                                    ),
                                                }} variant="outlined" 
                                                defaultValue={cFloor}
                                                onChange={e => setCfloor(e.target.value)}/>     
                                                </Grid>
                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label="Strike (ETH)" fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start"><Logo /></InputAdornment>
                                                    ),
                                                }} variant="outlined"
                                                defaultValue={cStrike}
                                                onChange={e => setCstrike(e.target.value)} />     
                                                </Grid>
                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label="Volatility (%, annual)" fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start"><Percent /></InputAdornment>
                                                    ),
                                                }} variant="outlined"
                                                defaultValue={cVol}
                                                onChange={e => setCvol(e.target.value)} />     
                                                </Grid>
                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label="Expiry (# Blocks)" fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start"><HourglassTop /></InputAdornment>
                                                    ),
                                                }} variant="outlined"
                                                defaultValue={cExp}
                                                onChange={e => setCexp(e.target.value)} />     
                                                </Grid>
                                                <Grid item xs={2} >
                                                <Typography align="center" variant='h5'>
                                                    Fair value: {bs.blackScholes(cFloor, cStrike, cExp/2302080, cVol/100, .05, switchPC.toLowerCase()).toFixed(3)} ETH
                                                </Typography>
                                                </Grid>

                                                <Grid item xs={2} >
                                                <Typography align="center" >
                                                    Percentage of floor: {(bs.blackScholes(cFloor, cStrike, cExp/2302080, cVol/100, .05, switchPC.toLowerCase())*100/cFloor).toFixed(3)} %
                                                </Typography>
                                                </Grid>

                                        </Grid>

                                    </Stack>
                                    </Card>
                                </Box>

                                <Box   display="flex" justifyContent="center" alignItems="center">
                                    <Button component={Link} to="/app" variant='contained' color='primary' 
                                    style={{maxWidth: '50%',  minWidth: '50%', marginTop:'50px'}}>
                                        Launch App </Button>
                                </Box>    
                                <Container maxWidth='md' align='center' style={{  paddingTop:'50px', paddingBottom: "700px"}}>

                                </Container>

                </main>

            </div>
 
        </ThemeProvider>
    );
}

export default Calc;