import * as React from "react";
import axios from 'axios';
import Modal from "@mui/material/Modal";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { ethers } from 'ethers'
import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json'

import { Contract, BrowserProvider, JsonRpcProvider, Interface } from 'ethers';
//import { Multicall } from 'ethereum-multicall';

import {Skeleton } from '@mui/material';
import {getAllERC721Transactions} from './Etherscan';
import {Typography,Box,Button} from "@mui/material";

import { useConnectWallet } from '@web3-onboard/react'
import { multicall3Abi } from "viem";

const loadingStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  borderRadius: 1,
  boxShadow: 0,
  bgcolor: "transparent",
  p: 1,
  maxHeight: "500px", 
  outline: "none",
};


const style = {
  maxHeight: "500px", 
  overflowY:"scroll"
};


const outerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  borderRadius: 1,
  boxShadow: 0,
  bgcolor: "transparent",
  p: 1,
  maxHeight: "500px", 
  outline: "none",
};



export function ContractModal(props) {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();


  const [listOfContracts, setListOfContracts] = React.useState([{'contract':'0', 'symbol':'0'}]);
  const userAccount = props.userAccount;
  const setBAYC = props.setBAYC;
  const NFTsetOpen = props.NFTsetOpen;
  const switchPC = props.switchPC;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [loadingText, setLoadingText] = React.useState("Loading ERC721 contracts")
  const [animation, setAnimation] = React.useState("wave");

  async function requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  }

  function onSelectCntrct(cntrct){
    setBAYC(cntrct); 
    handleClose(); 
    if (switchPC == 'Call'){
      NFTsetOpen(true);
    }
  }

  const fetchIconURLs = async () => {
    // for i in listOfNFTS:
    const apiCall = `https://api.opensea.io/api/v1/asset_contract/0x06012c8cf97bead5deae237070f9587f8e7a266d`;
    const resp = await axios.get(apiCall);
    console.log(resp)
    // iconURL = resp['image_url']
  };
  const INFURA_URL = process.env.REACT_APP_INFURA_URL;
  const MULTICALL_ADDRESS_SEPOLIA = '0x5e5f2aca0ec4f1d46394d49f5ed5d9f910589a10'; 
  const listContracts = async () => {
    const tempList = [];
    const ll = await getAllERC721Transactions(userAccount);
    console.log("Contracts:", ll);
  
    if (wallet) {
      try {
        //const provider = new BrowserProvider(wallet.provider, 'any');
        //const provider = new JsonRpcProvider(wallet.provider.rpcUrl);
        const provider = new ethers.InfuraProvider("sepolia", INFURA_URL);
        // Verify network
        const network = await provider.getNetwork();
        console.log("Connected to network:", network.chainId);
  
        if (network.chainId !== 11155111n) {
          throw new Error("Please connect to Sepolia testnet");
        }
  
        // Find the 'name' function in your existing ABI
        const nameAbi = BAYC.abi.find(item => item.name === 'name' && item.type === 'function');
        if (!nameAbi) {
          throw new Error("'name' function not found in ABI");
        }
  
        const intf = new Interface([nameAbi]);
        const nameFunction = intf.getFunction('name');
  
        // Prepare batch of calls
        const calls = Object.keys(ll).map(address => ({
          target: address,
          callData: intf.encodeFunctionData(nameFunction)
        }));
  
        // Create a contract instance for the Multicall3 contract
        const multicallAddress = '0xcA11bde05977b3631167028862bE2a173976CA11'; // Multicall3 address
        const multicallAbi = ['function aggregate3(tuple(address target, bool allowFailure, bytes callData)[] calls) external view returns (tuple(bool success, bytes returnData)[])'];
        const multicallContract = new Contract(multicallAddress, multicallAbi, provider);
  
        // Execute batch call
        const results = await multicallContract.aggregate3(calls.map(call => [call.target, false, call.callData]));
  
        // Process results
        for (let i = 0; i < results.length; i++) {
          const [success, returnData] = results[i];
          if (success) {
            const name = intf.decodeFunctionResult(nameFunction, returnData)[0];
            tempList.push({ 'contract': Object.keys(ll)[i], 'symbol': name });
          } else {
            console.warn(`Failed to fetch name for contract: ${Object.keys(ll)[i]}`);
          }
        }
  
        if (tempList.length === 0) {
          setListOfContracts([{'contract':'0', 'symbol':'0'}]);
          setLoadingText("No ERC721 NFTs found for wallet");
        } else {
          setListOfContracts(tempList);
        }
      } catch (error) {
        console.error("Error in listContracts:", error);
        setLoadingText(`Error: ${error.message}`);
      } finally {
        setAnimation(false);
      }
    } else {
      console.error("Wallet not connected");
      setLoadingText("Error: Wallet not connected");
      setAnimation(false);
    }
  };



  if (listOfContracts[0].contract == '0'){
    return(
      <div>
        <Button variant='outlined' color={switchPC == 'Call' ? 'primary': 'secondary'} 
                onClick={ () => {handleOpen(); listContracts()}}
                disabled={!ethers.isAddress(userAccount)}
                > 
          {ethers.isAddress(userAccount) ? 'Select' : 'Connect wallet'} 
        </Button>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={loadingStyle}>
              <Typography> {loadingText} </Typography>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <div>
                      <ListItem alignItems="flex-start">
                        <Skeleton animation={animation} variant="rectangular" width='100%' 
                        sx={{marginTop:1, borderRadius:2, bgcolor: animation == "wave" ? "":"secondary.main" }}/>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                  </div>
              </List>
          </Box>
        </Modal>
      </div> 
    );
  } else {
    return (
      <div>
        <Button variant='outlined' color={switchPC == 'Call' ? 'primary': 'secondary'}
                onClick={ () => {handleOpen(); listContracts()}}
                disabled={!ethers.isAddress(userAccount)}
                > 
          {ethers.isAddress(userAccount) ? 'Select' : 'Connect wallet'} 
        </Button>
        <Modal open={open} 
               onClose={handleClose} 
               aria-labelledby="modal-modal-title" 
               aria-describedby="modal-modal-description">
        <Box sx={outerStyle}>
        <Typography> SELECT CONTRACT </Typography>
        <Box sx={style}>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {listOfContracts.map((step, index) => (
                  <div key={index}>
                      <ListItem alignItems="flex-start" >
                          <ListItemAvatar>
                          <Avatar alt={step.symbol} src="/static/images/avatar/1.jpg" />
                          </ListItemAvatar>
                          <ListItemText
                            primary={step.symbol}
                            secondary={step.contract.substring(0,7) + '...' + step.contract.substring(26,32)}
                            />
                          <Button variant='outlined' color={switchPC == 'Call' ? 'primary': 'secondary'} sx={{marginTop: 1}} onClick={() => {onSelectCntrct(step.contract)}}>
                              Select
                          </Button>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                  </div>))}
              </List>
          </Box>
        </Box>

        </Modal>
      </div>
    );
  }
}