import * as React from "react"

const Ztrike = (props) => {
  const lstyle = `.st0{fill:${props.logocolor}}`;
  const logoColor = props.logoColor
  return(
  <svg
    id="svg15"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    width={props.width}
    viewBox="0 0 370 97.2"
    style={{
      enableBackground: "new 0 0 370 97.2",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{lstyle}</style>
    <g id="SvgjsG1016">
      <path
        id="rect3"
        className="st0"
        d="M9.6 0h61.6c5.3 0 9.6 4.3 9.6 9.6v78c0 5.3-4.3 9.6-9.6 9.6H9.6c-5.3 0-9.6-4.3-9.6-9.6v-78C0 4.3 4.3 0 9.6 0z"
      />
    </g>
    <g id="SvgjsG1017" transform="matrix(1.64313 0 0 1.64313 17.479 -12.16)">
      <path
        id="path6"
        d="M8.2 48.3c-1 0-1.5-.9-1.5-1.5v-6.2c0-.8.3-1.3 1-1.8L22 30.6l-2.7-1.5-10.5 6c-.2.1-.5.2-.8.2-.8 0-1.5-.7-1.5-1.5 0-.6.3-1 .8-1.3l10.9-6.3c.4-.2.7-.3 1-.3s.7.1 1 .3l5.3 3.1c.6.3.7.9.7 1.3 0 .5-.3 1-.7 1.3L9.6 41.2v3.1l14.7-8.5c.2-.1.5-.2.8-.2.8 0 1.5.7 1.5 1.5 0 .6-.3 1-.8 1.3L8.9 48.1c-.2.1-.5.2-.7.2zM2.5 58c-1 0-1.5-.9-1.5-1.5v-13c0-.8.7-1.5 1.5-1.5s1.5.8 1.5 1.6V54l20.2-11.7c.6-.3 1.2-.2 1.5 0 .6.3.7.9.7 1.3v6.2c0 .8-.3 1.3-1 1.7l-10.9 6.3c-.2.2-.5.2-.7.2-.8 0-1.5-.7-1.5-1.5 0-.6.3-1 .8-1.3l10.5-6v-3.1L3.3 57.8c-.2.2-.5.2-.8.2zm.1-25.9c-1 0-1.5-.9-1.5-1.5v-6.2c0-.8.3-1.3 1-1.8L13 16.4c.2-.1.5-.2.8-.2.8 0 1.5.7 1.5 1.5 0 .6-.3 1-.8 1.3L4 25v3.1l14.3-8.3c.4-.2.7-.3 1-.3.3 0 .7.1 1 .3l5.3 3.1c.5.3.8.7.8 1.3 0 .8-.7 1.5-1.5 1.5-.3 0-.5-.1-.8-.2l-4.8-2.8-16 9.2c-.2.1-.5.2-.7.2z"
        style={{
          fill: "#ffff",
        }}
      />
    </g>

  </svg>
)};

export default Ztrike
