import React from 'react';
import {Helmet} from "react-helmet";
import { createTheme, ThemeProvider } from '@mui/material';
//import { styled } from '@mui/material/styles';
import useStyles from '../styles';
import { Typography, Button, IconButton, Stack, Box, Card, CssBaseline, Container} from '@mui/material'

//import { createTheme, ThemeProvider } from '@mui/material/styles';
import {AppBarModule} from '../AppBarModuleFP';
import {AppBarFooter} from '../AppBarFooter';


import SvgComponent from '../optionMetadata/OptionSVG';
import ZtrikeIcon from '../ZtrikeIcon.js' ;

import OSwhite from '../logos/OSwhite.svg' ;
import ESlogo from '../logos/etherscan-logo-light-circle.svg';
import Marketplace from '../logos/Sponsor-amico.svg';
import TinyDino from '../logos/Online gallery-amico.svg';

import {Twitter} from '@mui/icons-material';
import Icon from '@material-ui/core/Icon';

import { useState } from 'react';

import { Link } from "react-router-dom";

const Docs = () => {
    const theme = createTheme({
        palette: {
            mode: 'dark',
            background: {default: 'gray'},    
         primary: {
            light: '#757ce8',
            main: '#264a39',
            dark: '#607d8b',
            contrastText: '#fff',
          },
          secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#e64a19',
            contrastText: '#000',
          },
        },
        typography: {
            fontFamily: 'Inter',
          },
          shape: {
            borderRadius: 12,
          }, 
      });
const [userAccount, setUserAccount] = useState()
const [fakeBaycAddress, setBAYC] = useState('0x0d9d1354A3dF52DC22b20b96F221E0FC1e8F6d40')
const [erc721OptionAddress, setOption] = useState('0x0792e69D2d3449895f047177E8Df2beEB05B09A6')
const [isTransferDisabled, setIsTransferDisabled] = useState(false)

function handleDisableTransfer() {
    console.log("Toggled transfer");
    setIsTransferDisabled(!isTransferDisabled);
};

const gradient1 = "radial-gradient(circle at top, #414345, #232526)";



// const cards1 = [1, 2]; // Cards for option list

    const classes = useStyles(theme);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{ background: gradient1}} >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ztrike | Docs</title>
                <style>{'body { background-color: black; }'}</style>
            </Helmet>


                <AppBarModule
                                            fakeBaycAddress = {fakeBaycAddress} 
                                            erc721OptionAddress = {erc721OptionAddress} 
                                            userAccount={userAccount}
                                            setUserAccount={setUserAccount}
                                            switchPC='Call'
                
                />
                <main >
                    <div className={classes.container2} >
                        <br/><br/>                        
                        <Container maxWidth='md'>
                            <Typography variant='h4' >
                            Documentation
                            </Typography>
                            <Typography variant='h5' >
                            How to use the ztrike dApp
                            </Typography>
                        </Container>
                        </div>
                    
                                <Box   display="flex" justifyContent="center" alignItems="center">
                                    <Button component={Link} to="/app" variant='contained' color='primary' 
                                    style={{maxWidth: '50%',  minWidth: '50%'}}>
                                        Launch App </Button>
                                </Box>

                                <Box display="flex" justifyContent="center"  alignItems="center" sx={{paddingTop:2}}>
                                    <Stack direction="row" spacing={2}>
                                        <IconButton onClick={() => window.open('https://google.com')}><img src={OSwhite} width={30} /></IconButton>
                                        <IconButton onClick={() => window.open('https://goerli.etherscan.io/address/0x7002cC4e2b8b539E71c9BcEe8529c6AED17B8E96')}><img src={ESlogo} width={30}/></IconButton>
                                        <IconButton  onClick={() => window.open('https://twitter.com/ztrike_io')}>
                                        <Twitter fontSize='large'/>  
                                        </IconButton>
                                        
                                    </Stack>
                                </Box>
                                

                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding:3}}>
                                    <Card sx={{maxWidth:{sm:'80%', lg:'50%'}, minWidth: {sm:'80%', lg:'50%'}, padding:2}}>
                                    <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                                        <Typography align="center" variant='h5'>
                                            What are ztrike options?
                                        </Typography>
                                        <Typography align="center">
                                        ztrike allows you to write covered call and put options on any NFT that follows the ERC721 protocol. 
Call options give the holder the right (but not the obligation) to purchase the underlying NFT at a specified price (the strike price) within a given timeframe.

Put options give the holder the right (but not the obligation) to receive a specified amount of ETH in exchange for an NFT from a specific collection. 

ztrike options are American-style options, meaning that they are redeemable at any time until expiry.
                                        </Typography>   
                                    </Stack>
                                    </Card>
                                </Box>

                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding:3}}>
                                    <Card sx={{maxWidth:{sm:'80%', lg:'50%'}, minWidth: {sm:'80%', lg:'50%'}, padding:2}}>
                                    <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                                        <Typography align="center" variant='h5'>
                                            Why are ztrike options represented as NFTs?
                                        </Typography>
                                        <Typography align="center">
                                        Representing options as NFTs themselves allows options to be traded on any NFT marketplace like OpenSea or LooksRare. Options-as-NFTs are decentralized and secured by the Ethereum blockchain, with all necessary information stored in the smart contract and additional details, like the option artwork, stored on IPFS, eliminating liquidity and counterparty risk.  
                                        </Typography>   
                                    </Stack>
                                    </Card>
                                </Box>



                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding:3}}>
                                    <Card sx={{maxWidth:{sm:'80%', lg:'50%'}, minWidth: {sm:'80%', lg:'50%'}, padding:2}}>
                                    <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                                        <Typography align="center" variant='h5'>
                                            How much does it cost to mint a ztrike option?
                                        </Typography>
                                        <Typography align="center">
                                        The writer pays a writeFee (currently 5% of the strike price) to the smart contract for writing the option. The only additional cost is gas fees when writing and redeeming an option. Use the dApp when gas prices are low to minimize costs. 
                                        </Typography>   
                                    </Stack>
                                    </Card>
                                </Box>

                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding:3}}>
                                    <Card sx={{maxWidth:{sm:'80%', lg:'50%'}, minWidth: {sm:'80%', lg:'50%'}, padding:2}}>
                                    <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                                        <Typography align="center" variant='h5'>
                                            How do I redeem (exercise) a ztrike option?
                                        </Typography>
                                        <Typography align="center">
                                        You can redeem an option by clicking "View options" on the App. For call options, you need to pay the strike. For put options, you need to select an eligible NFT from your wallet and use it to redeem.
You can also redeem directly from the smart contract, using the redeemOption() function. For put options, you need to first Approve the ztrike smart contract to take the NFT you wish to use for redemption.
                                        </Typography>   
                                    </Stack>
                                    </Card>
                                </Box>

                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding:3}}>
                                    <Card sx={{maxWidth:{sm:'80%', lg:'50%'}, minWidth: {sm:'80%', lg:'50%'}, padding:2}}>
                                    <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                                        <Typography align="center" variant='h5'>
                                            How do i retrieve my NFT/ETH from an expired (non-exercised) ztrike options?
                                        </Typography>
                                        <Typography align="center">
                                        You can retrieve underlying assets from expired options in the App, or use the retrieveNFTfromExpiredOption() function from the smart contract.
                                        </Typography>   
                                    </Stack>
                                    </Card>
                                </Box>


                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding:3}}>
                                    <Card sx={{maxWidth:{sm:'80%', lg:'50%'}, minWidth: {sm:'80%', lg:'50%'}, padding:2}}>
                                    <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                                        <Typography align="center" variant='h5'>
                                            The ztrike protocol
                                        </Typography>
                                        <Typography align="center" variant='b2'>
                                            Aside from the OpenZeppelin ERC721 implementation, the smart contract implements several public functions to provide option functionality for the NFTs.
                                        </Typography>
                                        <Card style={{backgroundColor: "#264a39"}} sx={{maxWidth:{sm:'80%', lg:'80%'}, minWidth: {sm:'80%', lg:'80%'}, padding:2}} >
                                            <Typography align="center" style={{ fontWeight: 600 }}>
                                                getCurrentOption()
                                            </Typography>
                                            <Typography align="center" variant='b2'>
                                            Public view: returns the ID, underlyingID, originalOwner (option-writer) and underlyingContractAddress of the latest option
                                            </Typography>
                                        </Card>
                                        <Card style={{backgroundColor: "#264a39"}} sx={{maxWidth:{sm:'80%', lg:'80%'}, minWidth: {sm:'80%', lg:'80%'}, padding:2}}>
                                            <Typography align="center" style={{ fontWeight: 600 }}>
                                                safeTransferFrom()
                                            </Typography>
                                            <Typography align="center" variant='b2'>
                                            Public virtual override: sets the optOwner property of the Option-struct before performing the standard ERC721 safeTransferFrom()
                                            </Typography>
                                        </Card>
                                        <Card style={{backgroundColor: "#264a39"}} sx={{maxWidth:{sm:'80%', lg:'80%'}, minWidth: {sm:'80%', lg:'80%'}, padding:2}}>
                                            <Typography align="center" style={{ fontWeight: 600 }}>
                                                writeNFTonUnderlying()
                                            </Typography>
                                            <Typography align="center" variant='b2'>
                                            Public payable: Transfers the underlying NFT to the smart contract and then transfers a new option on the underlying NFT to the option writer. 
                                            </Typography>
                                        </Card>
                                        <Card style={{backgroundColor: "#264a39"}} sx={{maxWidth:{sm:'80%', lg:'80%'}, minWidth: {sm:'80%', lg:'80%'}, padding:2}}>
                                            <Typography align="center" style={{ fontWeight: 600 }}>
                                                optionOwnerOf()
                                            </Typography>
                                            <Typography align="center" variant='b2'>
                                            Public view: returns the owner of the options if the option is not expired, else returns the option writer
                                            </Typography>
                                        </Card>
                                        <Card style={{backgroundColor: "#264a39"}} sx={{maxWidth:{sm:'80%', lg:'80%'}, minWidth: {sm:'80%', lg:'80%'}, padding:2}}>
                                            <Typography align="center" style={{ fontWeight: 600 }}>
                                            cancelWriteReturnNFT()
                                            </Typography>
                                            <Typography align="center" variant='b2'>
                                            Public: (Only owner of option who is also the option writer) - Give back the NFT (calls) / ETH (puts) without requiring payment of strike or NFT. If you write an option, an regret before expiry, you can cancel the option. 
                                            </Typography>
                                        </Card>
                                        <Card style={{backgroundColor: "#264a39"}} sx={{maxWidth:{sm:'80%', lg:'80%'}, minWidth: {sm:'80%', lg:'80%'}, padding:2}}>
                                            <Typography align="center" style={{ fontWeight: 600 }}>
                                            redeemOption()
                                            </Typography>
                                            <Typography align="center" variant='b2'>
                                            Public: The owner of an option can redeem it (exercise) until it expires. For call options, you redeem by paying the strike, and then recieve the underlying NFT. The option writer recieves the strike but loses the NFT. For put options you redeem by transfering an NFT of the underlying contract address, and then recieve the strike. The option writer recieves the NFT.
                                            </Typography>
                                        </Card>
                                        <Card style={{backgroundColor: "#264a39"}} sx={{maxWidth:{sm:'80%', lg:'80%'}, minWidth: {sm:'80%', lg:'80%'}, padding:2}}>
                                            <Typography align="center" style={{ fontWeight: 600 }}>
                                            retrieveNFTfromExpiredOption()
                                            </Typography>
                                            <Typography align="center" variant='b2'>
                                            Public: The option writer can use this functions to get back the underlying assets once the option is expired.
                                            </Typography>
                                        </Card>
                                    </Stack>
                                    </Card>
                                </Box>




                </main>

            </div>
 
        </ThemeProvider>
    );
}

export default Docs;