

import * as React from "react"
import axios from 'axios';
import { Typography} from '@mui/material'


const network = 'sepolia';

async function getTransactions(userAddress, nftAddress){
                const apiCall = `https://api-${network}.etherscan.io/api?module=account&action=tokennfttx&contractaddress=${nftAddress}&address=${userAddress}&page=1&offset=1000&startblock=0&endblock=27025780&sort=asc&apikey=G8PCGZNE6MNVJN8FRFBH9BN9AA5YAUGA2K`;
                //console.log(apiCall);
                const resp1 = await axios.get(apiCall);
                var listOfNfts = [];
                for (var i = 0; i < resp1['data']['result'].length; i++){
                    var tokenID = resp1['data']['result'][i]['tokenID'];
                    if (resp1['data']['result'][i]['to'] == userAddress){
                        typeof  listOfNfts[tokenID] === 'undefined' ? listOfNfts[tokenID] = 1: listOfNfts[tokenID]++; 
                    };
                    if (resp1['data']['result'][i]['from'] == userAddress){
                        typeof  listOfNfts[tokenID] === 'undefined' ? listOfNfts[tokenID] = -1: listOfNfts[tokenID]--;                        
                    };                   
                };
                //console.log(listOfNfts);
                var newList = [];
                for (var i in listOfNfts){
                    if (listOfNfts[i] > 0 ){
                     newList.push({'id':i});
                    } 
                }
                //console.log(newList);
                return(newList);
};

async function getAllERC721Transactions(userAddress){
    const apiCall = `https://api-${network}.etherscan.io/api?module=account&action=tokennfttx&address=${userAddress}&page=1&offset=10000&startblock=0&endblock=27025780&sort=asc&apikey=G8PCGZNE6MNVJN8FRFBH9BN9AA5YAUGA2K`;
    //console.log(apiCall);
    const resp1 = await axios.get(apiCall);
    var listOfNfts = [];
    for (var i = 0; i < resp1['data']['result'].length; i++){ // FOR ALL TRANSACTIONS
        var tokenID = resp1['data']['result'][i]['tokenID'];
        var contractAddress = resp1['data']['result'][i]['contractAddress'];
        
        if (typeof  listOfNfts[contractAddress] == 'undefined'){
            listOfNfts[contractAddress] = [];
        }; 

        if (resp1['data']['result'][i]['to'] == userAddress){
            typeof  listOfNfts[contractAddress][tokenID] === 'undefined' ? listOfNfts[contractAddress][tokenID] = 1: listOfNfts[contractAddress][tokenID]++; 
        };
        if (resp1['data']['result'][i]['from'] == userAddress){
            typeof  listOfNfts[contractAddress][tokenID] === 'undefined' ? listOfNfts[contractAddress][tokenID] = -1: listOfNfts[contractAddress][tokenID]--;                        
        };                   
    };
    //console.log(listOfNfts);
    var newList = [];

    for (var i in listOfNfts){
        //console.log(listOfNfts[i]);
        for (var ii in listOfNfts[i]){
            //console.log(listOfNfts[i][ii]);
            //console.log(ii);
            if (listOfNfts[i][ii] > 0 ){
                //console.log(`${i}: ${ii} == ${listOfNfts[i][ii]}`);
                if (typeof newList[i] == 'undefined'){newList[i] =[]};
                    newList[i].push({'id':ii});
               } 
        }
    }
    //console.log(newList);
    return(newList);
};


async function getWrittenOptions(userAddress, optionAddress){
    //const apiCall = `https://api-goerli.etherscan.io/api?module=account&action=tokennfttx&contractaddress=${optionAddress}&address=${userAddress}&page=1&offset=10000&startblock=0&endblock=27025780&sort=asc&apikey=G8PCGZNE6MNVJN8FRFBH9BN9AA5YAUGA2K`;
    const apiCall = `https://api-${network}.etherscan.io/api?module=account&action=tokennfttx&contractaddress=${optionAddress}&page=1&offset=10000&startblock=0&endblock=27025780&sort=asc&apikey=G8PCGZNE6MNVJN8FRFBH9BN9AA5YAUGA2K`;
    
    console.log(apiCall);
    const resp1 = await axios.get(apiCall);
    console.log(resp1);
    var listOfNfts = [];
    for (var i = 0; i < resp1['data']['result'].length; i++){ // FOR ALL TRANSACTIONS
        var tokenID = resp1['data']['result'][i]['tokenID'];
        var contractAddress = resp1['data']['result'][i]['contractAddress'];
        console.log(tokenID, ">>","From:", resp1['data']['result'][i]['from'], "To:", resp1['data']['result'][i]['to'])
        if ((resp1['data']['result'][i]['from'] == '0x0000000000000000000000000000000000000000') && (resp1['data']['result'][i]['to'] == userAddress)){
            typeof  listOfNfts[tokenID] === 'undefined' ? listOfNfts[tokenID] = 1: listOfNfts[tokenID]++;                        
        };    
        if ((resp1['data']['result'][i]['to'] == '0x0000000000000000000000000000000000000000') && (resp1['data']['result'][i]['from'] == userAddress)){
            typeof  listOfNfts[tokenID] === 'undefined' ? listOfNfts[tokenID] = -1: listOfNfts[tokenID]--;                        
        };        
        if ((resp1['data']['result'][i]['to'] == '0x0000000000000000000000000000000000000000') && (resp1['data']['result'][i]['from'] != userAddress)){
            typeof  listOfNfts[tokenID] === 'undefined' ? listOfNfts[tokenID] = -1: listOfNfts[tokenID]--;                        
        };       
        // REMOVE OPTIONS THAT ARE BURNED BY OTHER ACCOUNTS
    };
    console.log(listOfNfts);
    var newList = [];
    for (var i in listOfNfts){
        if (listOfNfts[i] > 0 ){
         newList.push({'id':i});
        } 
    }
    console.log(newList);
    return(newList);
};

export {getTransactions, getAllERC721Transactions, getWrittenOptions};