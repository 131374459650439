import * as React from "react";
import { Box, Typography, AppBar, Stack, IconButton, Toolbar,Button} from '@mui/material'
import { ethers } from "ethers";
import OSwhite from './logos/OSwhite.svg' ;
import ESlogo from './logos/etherscan-logo-light-circle.svg';
import {Twitter} from '@mui/icons-material';
import { getOrders, getBlockGas } from './supaBaseFuncs.js';


// Custom hook for counting animation
const useCountUp = (end, duration = 2000, start = 0) => {
        const [count, setCount] = React.useState(start);
        const [isAnimating, setIsAnimating] = React.useState(true);
      
        React.useEffect(() => {
          if (end !== '-' && !isNaN(end)) {
            let startTimestamp;
            const step = (timestamp) => {
              if (!startTimestamp) startTimestamp = timestamp;
              const progress = Math.min((timestamp - startTimestamp) / duration, 1);
              setCount(Math.floor(progress * (end - start) + start));
              if (progress < 1) {
                window.requestAnimationFrame(step);
              } else {
                setIsAnimating(false);
              }
            };
            window.requestAnimationFrame(step);
          }
        }, [end, duration, start]);
      
        return [isAnimating ? count : end, isAnimating];
      };
      export function AppBarFooter(props) {
        const { bb, gg, mode } = props;
        
        const [animatedBb, bbAnimating] = useCountUp(bb, 2000, 0);
        const [animatedGg, ggAnimating] = useCountUp(gg, 2000, 0);
    
        const [bbChanged, setBbChanged] = React.useState(false);
        const [ggChanged, setGgChanged] = React.useState(false);
        const [dataUpdated, setDataUpdated] = React.useState(false);

        React.useEffect(() => {
                if (bb !== 0) {
                setBbChanged(true);
                setDataUpdated(true);
                const timer = setTimeout(() => setBbChanged(false), 1000);
                return () => clearTimeout(timer);
                }
        }, [bb]);
            
        React.useEffect(() => {
                if (gg !== 0) {
                    setGgChanged(true);
                    setDataUpdated(true);
                    const timer = setTimeout(() => setGgChanged(false), 1000);
                    return () => clearTimeout(timer);
                }
            }, [gg]);
    
        return (
            <AppBar 
                position="fixed" 
                sx={{ 
                    height: '20px',
                    minHeight: '20px',
                    top: 'auto', 
                    bottom: 0, 
                    backgroundColor: mode == "light" ? 'rgba(255, 255, 255, 0.7)': 'rgba(0, 0, 0, 0.7)',
                    backdropFilter: 'blur(5px)',
                    boxShadow: 'none',
                    zIndex: 1300
                }}
            >
                <Toolbar 
                    disableGutters
                    sx={{ 
                        minHeight: '20px !important',
                        height: '100%',
                        px: 1
                    }}
                >
                    <Box
                    sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: dataUpdated ? '#4caf50' : '#f44336',
                        marginRight: 1,
                        transition: 'background-color 0.3s ease',
                        }}
                    />
                    <Typography 
                        variant='caption'
                        sx={{
                            fontSize: 9,
                            fontFamily: 'Roboto Mono, monospace',
                            color: mode === 'dark' ? 'white' : 'black',
                            lineHeight: 1,
                            opacity: 0.8,
                            letterSpacing: -0.3,
                        }}
                    >
                        Block: #
                        <span style={{
                            transition: 'color 0.5s ease',
                            color: bbChanged ? '#4caf50' : 'inherit',
                        }}>
                            {bbAnimating ? animatedBb : bb}
                        </span>
                        {' '}- Gas:{' '}
                        <span style={{
                            transition: 'color 0.5s ease',
                            color: ggChanged ? '#4caf50' : 'inherit',
                        }}>
                            {ggAnimating ? animatedGg : gg}
                        </span>
                        {' '}gwei
                    </Typography>
                </Toolbar>     
            </AppBar>
        );
    }
