import * as React from "react";
//import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
import Modal from "@mui/material/Modal";

import Swipeable from "./Swipeable";
import { ethers } from "ethers";
import BAYC from "./artifacts/contracts/FakeBAYC.sol/BAYC.json";
import Option from "./artifacts/contracts/ERC721Option.sol/ERC721Option.json";
import {getTransactions, getAllERC721Transactions} from './Etherscan';
import {Logo} from './ethlogo.js';
import {LogoSmall} from './ethlogoSmall.js';
import InputAdornment from '@mui/material/InputAdornment';

import {Twitter, Percent, HourglassTop} from '@mui/icons-material';

import {
  Typography,
  Card,
  Grid,
  Box,
  Stack,
  TextField,
  Button
} from "@mui/material";

const gradient1 = "radial-gradient(circle at top, #414345, #232526)";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  borderRadius: 1,
  boxShadow: 0,
  bgcolor: "transparent",
  p: 1,
  outline: "none",
};

var bs = require("black-scholes");

export default function CalcModal(props) {
  const isRP = props.isRP;
  const activeStrike = props.activeStrike;
  const activeExpiry = props.activeExpiry;
  const activePC = props.activePC;
  const calcOpen = props.calcOpen;
  const setCalcOpen = props.setCalcOpen;
  const bb = props.bb;
  
  const calchandleOpen = props.handleOpen;
  const calchandleClose = props.handleClose;

  const [cFloor, setCfloor] = React.useState(activeStrike);
  const [cStrike, setCstrike] = React.useState(activeStrike);
  const [cVol, setCvol] = React.useState(175);
  const [cExp, setCexp] = React.useState(Math.max(activeExpiry - bb, 1));
  const [cValue, setCValue] = React.useState(bs.blackScholes(cFloor, cStrike, cExp/2302080, cVol/100, .05, activePC.toLowerCase()).toFixed(3))
  
  React.useEffect( () => {
    setCValue(bs.blackScholes(cFloor, cStrike, cExp/2302080, cVol/100, .05, activePC.toLowerCase()).toFixed(3));
  },
  [cFloor, cStrike, cExp, cVol]);

  React.useEffect( () => {
    setCfloor(activeStrike);
    setCstrike(activeStrike);
    setCexp(Math.max(activeExpiry - bb, 1));
  }, [activeStrike, activeExpiry]);

  const [openT, setOpenT] = React.useState(false);
  const handleOpen = () => setOpenT(true);
  const handleClose = () => setOpenT(false);

  function handleUpdate(){
    setOpenT(true);
    //setCfloor(activeStrike);
    //setCstrike(activeStrike);
    //setCexp(activeExpiry);
  }

  const pdTop = isRP? 10 : 10;
  const mgTop = isRP? 5 : 5;
  return (
    <div>
        <Button size="small" onClick={() => handleUpdate()} color = {props.switchPC == 'Call' ? 'primary': 'secondary'}>
        Est. value: {cValue} ETH
        </Button>
      <Modal
        open={openT}
        onClose={() => setOpenT(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding:1}}>
                                    <Card sx={{maxWidth:'60%', padding:2}}>
                                    <Typography>
                                        Calculator (Black-Scholes)
                                    </Typography>
                                    <br/>
                                    <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                                        <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='center' 
                                            alignItems="stretch" columns={{ xs: 2, sm: 2, md: 2 }}>
                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label="Floor price (ETH)" fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start"><Logo /></InputAdornment>
                                                    ),
                                                }} variant="outlined" 
                                                defaultValue={cFloor}
                                                onChange={e => setCfloor(e.target.value)}/>     
                                                </Grid>
                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label="Strike (ETH)" fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start"><Logo /></InputAdornment>
                                                    ),
                                                }} variant="outlined"
                                                defaultValue={cStrike}
                                                onChange={e => setCstrike(e.target.value)} />     
                                                </Grid>
                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label="Volatility (%, annual)" fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start"><Percent /></InputAdornment>
                                                    ),
                                                }} variant="outlined"
                                                defaultValue={cVol}
                                                onChange={e => setCvol(e.target.value)} />     
                                                </Grid>
                                                <Grid item xs={2} >
                                                <TextField id="outlined-basic" label="Expiry (# Blocks)" fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start"><HourglassTop /></InputAdornment>
                                                    ),
                                                }} variant="outlined"
                                                defaultValue={cExp}
                                                onChange={e => setCexp(e.target.value)} />     
                                                </Grid>
                                                <Grid item xs={2} >
                                                <Typography align="center" variant='h5'>
                                                    Fair value: {cValue} ETH
                                                </Typography>
                                                </Grid>

                                                <Grid item xs={2} >
                                                <Typography align="center" >
                                                    Percentage of floor: {(bs.blackScholes(cFloor, cStrike, cExp/2302080, cVol/100, .05, activePC.toLowerCase())*100/cFloor).toFixed(3)} %
                                                </Typography>
                                                </Grid>

                                                <Grid item xs={2} >
                                                <Typography align="center" variant='caption' 
                                                        sx={{height:'5px', display:'flex', 
                                                            alignContent:'center', 
                                                            margin:0, padding:0}}>
                                                        Delta: 48% 
                                                    </Typography>
                                                    <br/>
                                                    <Typography align="center" variant='caption' 
                                                        >
                                                        Gamma (ETH): ETH 0.5  (floor+1%) 
                                                    </Typography>
                                                    <br/>
                                                </Grid>


                                                

                                        </Grid>

                                    </Stack>
                                    </Card>
                                </Box>
        </Box>
      </Modal>
    </div>
  );
}
