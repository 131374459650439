import React from 'react';
import {Helmet} from "react-helmet";
import { createTheme, ThemeProvider } from '@mui/material';
//import { styled } from '@mui/material/styles';
import useStyles from '../styles';
import { Typography, Button, IconButton, Stack, Box, Card, CssBaseline, Container, AppBar, Toolbar, ButtonGroup} from '@mui/material'

//import { createTheme, ThemeProvider } from '@mui/material/styles';
import {AppBarModule} from '../AppBarModuleFP';
import {AppBarFooter} from '../AppBarFooter';
import SvgComponent from '../optionMetadata/OptionSVG';
import ZtrikeIcon from '../ZtrikeIcon.js' ;

import OSwhite from '../logos/OSwhite.svg' ;
import OSblack from '../logos/OSblack.svg' ;

import ESlogo from '../logos/etherscan-logo-light-circle.svg';
import ESlogoBlack from '../logos/etherscan-logo-dark.svg';

//import ZtrikePattern from '../ZvgPatternAnimated.svg';
import ZtrikePatternDark from '../logos/ZvgPatternDark.svg';
import ZtrikePattern2 from '../logos/ZvgPattern2.svg';

import ZtrikePattern from '../ZtrikePattern.js';

import ZtrikeName from '../logos/ZvgZtrikeNameDark.svg';
import {Twitter} from '@mui/icons-material';
import Icon from '@material-ui/core/Icon';

import { useState } from 'react';

import { Link } from "react-router-dom";


import * as ReactDOMServer from "react-dom/server";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import SavingsIcon from '@mui/icons-material/Savings';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaidIcon from '@mui/icons-material/Paid';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ImageIcon from '@mui/icons-material/Image';

const App = () => {

    const [mode, setMode] = React.useState('light');
    const btnColor = mode == 'light' ? 'black' : 'white';

    function handleMode(){
        if (mode=="light"){
            setMode("dark");
        } else {
            setMode("light");
        }
    }
    const lightMode = {
      // Light mode
      primary: {
        light: '#757ce8',
        main: '#264a39',
        dark: '#607d8b',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#e64a19',
        contrastText: '#000',
      },
      background: {
        default: '#fcfcfc',
        paper: '#dbdbdb',
      },
    };
    const darkMode = {
      // Dark mode
      primary: {
        light: '#757ce8',
        main: '#264a39',
        dark: '#607d8b',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#e64a19',
        contrastText: '#000',
      },
      background: {
        default: '#414345',
        paper: '#232526',
      },
    };


    const theme = React.useMemo(
      () => createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? lightMode
            : darkMode),
        },
        typography: {
          fontFamily: 'Inter',
        },
        shape: {
          borderRadius: 12,
        },
        custom: {
          gradient1: mode === 'dark'
            ? "radial-gradient(circle at top, #414345, #232526)"
            : "radial-gradient(circle at top, #fcfcfc, #dbdbdb)",
        },
      }), [mode]);

const [erc721OptionAddress, setOption] = useState('0x0792e69D2d3449895f047177E8Df2beEB05B09A6')
//const [scrollY, setScrollY] = useState(0);

/*React.useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);*/


const gradient1 = "radial-gradient(circle at top, #414345, #232526)";


// magic
const svgString = encodeURIComponent(
  ReactDOMServer.renderToStaticMarkup(
        <ZtrikePattern 
        lightColor = {mode=='light' ? '#dbdbdb' : '#232526'} //'#414345'
        darkColor = {mode=='light' ? '#ababab' : '#414345'} //'#fcfcfc'
        opacity = "0.2"
        />
    )
);



// const cards1 = [1, 2]; // Cards for option list

    const classes = useStyles(theme);

    const scrollToHowItWorks = () => {
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth'
        });
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // First, add this function inside the App component to get inverted colors
    const getInvertedColors = () => ({
        background: mode === 'light' ? darkMode.background.paper : lightMode.background.paper,
        color: mode === 'light' ? lightMode.background.paper : darkMode.background.paper,
        iconColor: mode === 'light' ? theme.palette.secondary.main : theme.palette.secondary.main
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ztrike | NFT Options </title>
                <style>{'body { background-color: black; }'}</style>
                <style>
                    {`
                        body { background-color: black; }
                        @keyframes bounce {
                            0%, 20%, 50%, 80%, 100% {
                                transform: translateY(0);
                            }
                            40% {
                                transform: translateY(-20px);
                            }
                            60% {
                                transform: translateY(-10px);
                            }
                        }
                    `}
                </style>
            </Helmet>
            
            
            
            <div style={{ background: theme.custom.gradient1}} >
            
                <AppBarModule
                handleMode={handleMode}
                mode={mode}
                />
                
    <main >
                
      <div  style ={ { backgroundImage: `url("data:image/svg+xml,${svgString}")`, //`url(${ZtrikePattern})`, 
                      backgroundRepeat:'repeat', 
                      backgroundPosition: 'center left 0px', 
                      btransform: 'rotate(-10deg)', 
                      backgroundSize:'60px',
                      backgroundAttachment: 'fixed',
                      backgroundOpacity:'1'} }>
                   <Box sx={{ height: '100vh' }} 
        display="flex" 
        flexDirection="column">
                    <div className={classes.container2}>
                    <div>

                                     
                        </div>  
                        <br/><br/>                        
                        <Container maxWidth='md' sx={{marginLeft:{md:'23%', sm:'5%'}}}>
                                <br/>
                               
                                    <Stack direction="row" spacing={2}>
                                        <SvgComponent strike='89.5'  contract='0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d' nftId='9999' writer='0xE4F5fb6Ef9494917d09e20E1B87413907Bdf7903' expiry='7140442' type='Put'/>
                                        <Box sx={{maxWidth: {xs:'37%',sm:'50%'} }}>
                                              
                                                <Typography sx={{ typography: { xs: 'h5', sm: 'h4', md: 'h2' } }} >
                                                The NFT Option Protocol
                                                </Typography>
                                                <br/>
                                                <Typography variant='body' >
                                                Manage risk, leverage your conviction, and earn yield on any NFT
                                                
                                                </Typography>

                                        </Box>
                                    </Stack>                            
                            
                        
                        </Container>
                        </div>
                    
                                <Box   display="flex" justifyContent="center" alignItems="center">
                                    <Button component={Link} to="/app" variant='outlined' 
                                    style={{maxWidth: '50%',  minWidth: '50%'}}
                                    sx={{borderColor:btnColor, color:btnColor}}>
                                        Launch App </Button>
                                </Box>

                                <Box display="flex" justifyContent="center"  alignItems="center" sx={{paddingTop:2}}>
                                    <Stack direction="row" spacing={2}>
                                        <IconButton onClick={() => window.open('https://google.com')}><img src={mode === 'light' ? OSblack : OSwhite} width={30} /></IconButton>
                                        <IconButton onClick={() => window.open('https://goerli.etherscan.io/address/0x7002cC4e2b8b539E71c9BcEe8529c6AED17B8E96')}><img src={mode === 'light' ?  ESlogoBlack : ESlogo} width={30}/></IconButton>
                                        <IconButton  onClick={() => window.open('https://twitter.com/ztrike_io')}>
                                        <Twitter fontSize='large' fill={btnColor}/>  
                                        </IconButton>
                                        
                                    </Stack>
                                </Box>
                                

                            <div>
                 
                            </div> 
                            <Box sx={{ 
                                position: 'absolute', 
                                bottom: 20, 
                                width: '100%', 
                                display: 'flex', 
                                justifyContent: 'center' 
                            }}>
                                <IconButton 
                                    onClick={scrollToHowItWorks}
                                    sx={{ 
                                        color: btnColor,
                                        animation: 'bounce 2s infinite'
                                    }}
                                >
                                    <KeyboardArrowDownIcon sx={{ fontSize: 40 }} />
                                </IconButton>
                            </Box> 
                            </Box>
                        <Box 
                            sx={{ 
                                minHeight: '100vh',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 4,
                                background: 'transparent'
                            }}
                        >
                            <Container maxWidth="lg">
                                <Typography 
                                    variant="h3" 
                                    align="center" 
                                    sx={{ mb: 8, mt:8 }}
                                >
                                    How it Works
                                </Typography>
                                
                                <Stack 
                                    direction={{ xs: 'column', md: 'row' }} 
                                    spacing={4} 
                                    justifyContent="center"
                                >
                                    <Card sx={{ 
                                            p: 4, 
                                            textAlign: 'center',
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 2,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            transition: 'all 0.3s ease',
                                            cursor: 'pointer',
                                            '@keyframes pulse': {
                                                '0%': { opacity: 0.1 },
                                                '50%': { opacity: 0.3 },
                                                '100%': { opacity: 0.1 }
                                            },
                                            '&:hover': {
                                                background: getInvertedColors().background,
                                                color: getInvertedColors().color,
                                                transform: 'translateY(-5px)',
                                                '& .cardIcon': {
                                                    color: getInvertedColors().iconColor,
                                                },
                                                '& .backgroundIcon': {
            animation: 'pulse 4s ease-in-out infinite'  // Changed from 2s to 4s
        }
                                            }
                                        }}>
                                            <Box className="backgroundIcon" sx={{
                                                position: 'absolute',
                                                right: -20,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                opacity: 0.2,
                                                pointerEvents: 'none',
                                                transition: 'opacity 0.3s ease'
                                            }}>
                                            <AccountBalanceIcon className="cardIcon" sx={{ fontSize: 200, color: theme.palette.primary.main, position: 'relative' }} />
                                        </Box>
                                        <Typography variant="h5" sx={{ position: 'relative' }}>Write</Typography>
                                        <Typography fontSize={14} sx={{ textAlign: 'left', position: 'relative' }}>
                                            Deposit ETH or NFTs to write put or call options on any NFT collection. <br></br> <br></br>
                                            You recieve an ERC721 NFT representing ownership of the option.
                                        </Typography>
                                    </Card>

                                    <Card sx={{ 
                                            p: 4, 
                                            textAlign: 'center',
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 2,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            transition: 'all 0.3s ease',
                                            cursor: 'pointer',
                                            '@keyframes pulse': {
                                                '0%': { opacity: 0.1 },
                                                '50%': { opacity: 0.3 },
                                                '100%': { opacity: 0.1 }
                                            },
                                            '&:hover': {
                                                background: getInvertedColors().background,
                                                color: getInvertedColors().color,
                                                transform: 'translateY(-5px)',
                                                '& .cardIcon': {
                                                    color: getInvertedColors().iconColor,
                                                },
                                                '& .backgroundIcon': {
            animation: 'pulse 4s ease-in-out infinite'  // Changed from 2s to 4s
        }
                                            }
                                        }}>
                                            <Box className="backgroundIcon" sx={{
                                                position: 'absolute',
                                                right: -20,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                opacity: 0.2,
                                                pointerEvents: 'none',
                                                transition: 'opacity 0.3s ease'
                                            }}>
                                            <ShowChartIcon className="cardIcon" sx={{ fontSize: 200, color: theme.palette.primary.main, position: 'relative' }} />
                                        </Box>
                                        <Typography variant="h5" sx={{ position: 'relative' }}>Trade</Typography>
                                        <Typography fontSize={14} sx={{ textAlign: 'left', position: 'relative' }}>
                                            Buy and sell options through Ztrike's orderbook. <br></br> <br></br>
                                            Trade with 0% fees, you only pay gas!
                                        </Typography>
                                    </Card>

                                    <Card sx={{ 
                                            p: 4, 
                                            textAlign: 'center',
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 2,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            transition: 'all 0.3s ease',
                                            cursor: 'pointer',
                                            '@keyframes pulse': {
                                                '0%': { opacity: 0.1 },
                                                '50%': { opacity: 0.3 },
                                                '100%': { opacity: 0.1 }
                                            },
                                            '&:hover': {
                                                background: getInvertedColors().background,
                                                color: getInvertedColors().color,
                                                transform: 'translateY(-5px)',
                                                '& .cardIcon': {
                                                    color: getInvertedColors().iconColor,
                                                },
                                                '& .backgroundIcon': {
                                                    animation: 'pulse 4s ease-in-out infinite'  // Changed from 2s to 4s
                                                }
                                            }
                                        }}>
                                            <Box className="backgroundIcon" sx={{
                                                position: 'absolute',
                                                right: -20,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                opacity: 0.2,
                                                pointerEvents: 'none',
                                                transition: 'opacity 0.3s ease'
                                            }}>
                                            <PaidIcon className="cardIcon" sx={{ fontSize: 200, color: theme.palette.primary.main, position: 'relative' }} />
                                        </Box>
                                        <Typography variant="h5" sx={{ position: 'relative' }}>Exercise</Typography>
                                        <Typography fontSize={14} sx={{ textAlign: 'left', position: 'relative' }}>
                                            Exercise in-the-money options at any time before expiry. <br></br><br></br>
                                            All options are fully collateralized, decentralized and free from counterparty risk.
                                        </Typography>
                                    </Card>
                                </Stack>
                                <Box sx={{ 
                                    width: '100%', 
                                    display: 'flex', 
                                    justifyContent: 'center',
                                    mt: 4
                                }}>
                                    <IconButton 
                                        onClick={scrollToTop}
                                        sx={{ 
                                            color: btnColor,
                                            animation: 'bounce 2s infinite',
                                            transform: 'rotate(180deg)'
                                        }}
                                    >
                                        <KeyboardArrowUpIcon sx={{ fontSize: 40 }} />
                                    </IconButton>
                                </Box>
                            </Container>
                        </Box>
</div>

</main>
                
                

            { true ? '':


           
            <AppBar position="static" sx={{ top: 'auto', bottom: 0, height:'10vh', marginTop:'-5vh'}}>
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    

                                <Stack direction="row" spacing={2}>
                                <ButtonGroup>
                                <Button variant="outlined" color='primary' component={Link} to="/app" > <Typography variant='overline' >App</Typography></Button>
                                <Button variant="outlined" color='primary' component={Link} to="/app" ><Typography variant='overline' >Docs</Typography></Button>
                                <Button variant="outlined" color='primary' component={Link} to="/app" ><Typography variant='overline' >Calc</Typography></Button>
                                </ButtonGroup>
                                
                                </Stack>
                                

                        <Stack direction="row" spacing={2}>
                                <IconButton onClick={() => window.open('https://opensea.io')}><img src={mode === 'light' ? OSblack : OSwhite} width={20} /></IconButton>
                                <IconButton onClick={() => window.open(`https://rinkeby.etherscan.io/address/${erc721OptionAddress}`)}><img src={mode === 'light' ?  ESlogoBlack : ESlogo} width={20}/></IconButton>
                                <IconButton  onClick={() => window.open('https://twitter.com/ztrike_io')}>
                                        <Twitter fontSize='medium'/>  
                                </IconButton>
                        </Stack>
                </Toolbar>     
            </AppBar>
             }
            </div>
            
        </ThemeProvider>
    );
}

export default App;