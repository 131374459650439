import SvgComponent from './optionMetadata/OptionSVG';

import OptionJSON from './optionMetadata/OptionJSON2'; // USE OPTIONJSON3 for inline dataurl

import ReactDOMServer from 'react-dom/server'

import React from 'react';
//import { create } from "ipfs-http-client";


//imports needed for this function
import axios from 'axios';

//const FormData = require('form-data');

// Authentication test
import {Buffer} from 'buffer';



// INFURA ------------------------------------

//const ipfsClient = require('ipfs-http-client');
import { create } from 'ipfs-http-client'

const projectId = process.env.REACT_APP_INFURA_IPFS_ID;
const projectSecret = process.env.REACT_APP_INFURA_IPFS_SECRET;
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const client = create({
    host: 'ztrike.infura-ipfs.io', //'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});


const INFURApinFileToIPFS = (file) => {
    return client.add(file, {'pin':true}).then((res) => {
        return res;
        }).catch(function (error) {
            console.log("Pinning error");
        });
};


// ---------------------------------------------
const UploadFile = async (strike, contractAddress, writer, nftId, expiry, optType) => {
      // Step 1 - Create SVG and upload to IPFS, return URL
      let file = ReactDOMServer.renderToString(
          <SvgComponent 
          strike={strike}
          contract={contractAddress}
          nftId={nftId}
          writer={writer}
          expiry={expiry}
          type={optType}
          /> 
          
        );
      
      //const ipfsHash = await pinFileToIPFS(process.env.REACT_APP_PINATA_API, process.env.REACT_APP_PINATA_SECRET, file, `${nftId}.svg`, 'text/plain');
      const ipfsHash = await INFURApinFileToIPFS(file);
      console.log(ipfsHash);
      console.log(ipfsHash['path']);
      
      // Base64 encode?
      console.log("Trying to encode");
      let encodedData = Buffer.from(file).toString('base64'); //window.btoa(file);
      //console.log(encodedData);
      
      // Step 2. create metadata JSON and upload to IPFS, return url
      
      let jsonFile = ReactDOMServer.renderToString(
        <OptionJSON
          contractAddress = {contractAddress}
          strike = {strike}
          nftId={nftId}
          expiry={expiry}
          imageHash={ipfsHash['path']}
          OptionId='N/A'
          type={optType}
          writer={writer}
          imageBase64={encodedData}
          />
      );

      let newjs = JSON.parse(jsonFile.replace(/&quot;/g,'"'))
      console.log(newjs)
      //const jsonHash = await pinFileToIPFS(process.env.REACT_APP_PINATA_API, process.env.REACT_APP_PINATA_SECRET, newjs, `${nftId}.json`, 'json');
      const jsonHash = await INFURApinFileToIPFS(newjs);
      console.log(jsonHash);
      console.log(jsonHash['path']);
    return(
        jsonHash['path']
    );

    };

export default UploadFile;