
export const Ztrike = String(`<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.0" id="svg15" xmlns:svg="http://www.w3.org/2000/svg"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 97.2 97.2"
	 enable-background="new 0 0 97.2 97.2" xml:space="preserve">
<g id="SvgjsG1016" transform="matrix(1,0,0,1,0,0)">
	<path id="rect3" fill="#111111" d="M9.6,0h61.6c5.3,0,9.6,4.3,9.6,9.6v78c0,5.3-4.3,9.6-9.6,9.6H9.6c-5.3,0-9.6-4.3-9.6-9.6v-78
		C0,4.3,4.3,0,9.6,0z"/>
</g>
<g id="SvgjsG1017" transform="matrix(1.6431347854707434,0,0,1.6431347854707434,17.47895225574539,-12.159079166963707)">
	<path id="path6" fill="#F6F6F6" d="M8.2,48.3c-1,0-1.5-0.9-1.5-1.5v-6.2c0-0.8,0.3-1.3,1-1.8L22,30.6l-2.7-1.5l-10.5,6
		c-0.2,0.1-0.5,0.2-0.8,0.2c-0.8,0-1.5-0.7-1.5-1.5c0-0.6,0.3-1,0.8-1.3l10.9-6.3c0.4-0.2,0.7-0.3,1-0.3s0.7,0.1,1,0.3l5.3,3.1
		c0.6,0.3,0.7,0.9,0.7,1.3c0,0.5-0.3,1-0.7,1.3L9.6,41.2v3.1l14.7-8.5c0.2-0.1,0.5-0.2,0.8-0.2c0.8,0,1.5,0.7,1.5,1.5
		c0,0.6-0.3,1-0.8,1.3L8.9,48.1C8.7,48.2,8.4,48.3,8.2,48.3L8.2,48.3z M2.5,58L2.5,58c-1,0-1.5-0.9-1.5-1.5v-13
		C1,42.7,1.7,42,2.5,42S4,42.8,4,43.6V54l20.2-11.7c0.6-0.3,1.2-0.2,1.5,0c0.6,0.3,0.7,0.9,0.7,1.3v6.2c0,0.8-0.3,1.3-1,1.7
		l-10.9,6.3C14.3,58,14,58,13.8,58c-0.8,0-1.5-0.7-1.5-1.5c0-0.6,0.3-1,0.8-1.3l10.5-6v-3.1L3.3,57.8C3.1,58,2.8,58,2.5,58L2.5,58z
		 M2.6,32.1c-1,0-1.5-0.9-1.5-1.5v-6.2c0-0.8,0.3-1.3,1-1.8L13,16.4c0.2-0.1,0.5-0.2,0.8-0.2c0.8,0,1.5,0.7,1.5,1.5
		c0,0.6-0.3,1-0.8,1.3L4,25v3.1l14.3-8.3c0.4-0.2,0.7-0.3,1-0.3s0.7,0.1,1,0.3l5.3,3.1c0.5,0.3,0.8,0.7,0.8,1.3
		c0,0.8-0.7,1.5-1.5,1.5c-0.3,0-0.5-0.1-0.8-0.2l-4.8-2.8l-16,9.2C3.1,32,2.8,32.1,2.6,32.1L2.6,32.1z"/>
</g>
</svg>
`);
