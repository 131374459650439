import { createClient } from '@supabase/supabase-js'
import { ethers } from 'ethers';
// Create a single supabase client for interacting with your database
const supabase = createClient('https://xmrsyqbtjkwmrxwzbodf.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhtcnN5cWJ0amt3bXJ4d3pib2RmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjYwNjgxNjAsImV4cCI6MjA0MTY0NDE2MH0.KQQUZEFTfz_-ZHvYuf1Y2Uym4n6PGkmiZcbObUDMvN4')

function printDictDetails(dict) {
    for (let key in dict) {
        if (dict.hasOwnProperty(key)) {
            console.log(`Name: ${key}, Value: ${dict[key]}, Type: ${typeof dict[key]}`);
        }
    }
  }
  
async function getUniqueUnderlyingFromOrders(){
    let { data: orders, error } = await supabase
    .from('orders')
    .select('underlyingContract');
    
    console.log("unique from:", orders);


    

}

async function getActiveOrderIdByOptionId(optionId) {
    let { data: orders, error } = await supabase
        .from('orders')
        .select('*')
        .eq('optionId', optionId)
        .eq('isOffer', true)
        .is('isCancelled', false)
        .is('isFilled', false)
        .is('isGeneral', false)
        .order('_orderId', { ascending: false })
        .limit(1);        

    if (error) {
        console.error("Error fetching orders:", error);
        return null; // Return null if there's an error
    }

    // Check if any active orders were found
    if (orders.length > 0) {
        return orders[0]._orderId; // Return the orderId of the first active order
    }

    return null; // Return null if no active orders found
}


async function getOrders() {
    //console.log("connecting to sb");
    let { data: orders, error } = await supabase
    .from('orders')
    .select('*').order('_orderId', { ascending: true });
    console.log("orders", orders);
    //printDictDetails(orders[0]);
    return orders;
}

async function getOrder(orderId) {
    //console.log("connecting to sb");
    let { data: orders, error } = await supabase
    .from('orders')
    .select('*').eq('_orderId', orderId);
    //console.log(orders);
    //printDictDetails(orders[0]);
    return orders;
}


async function getOrderOfferOnly(orderId) {
    let { data: orders, error } = await supabase
        .from('orders')
        .select('*')
        .eq('_orderId', orderId)
        .eq('isOffer', true)
        .is('isCancelled', false)
        .is('isFilled', false)
        .is('isGeneral', false)
        .order('_orderId', { ascending: false })
        .limit(1);

    if (error) {
        console.error("Error fetching orders:", error);
        return null;
    }

    return orders;
}

async function getOwners() {
    //console.log("connecting to sb");
    let { data: owners, error } = await supabase
    .from('owners')
    .select('*');
    //console.log(orders);
    //printDictDetails(orders[0]);
    return owners;
}


async function getGeneralBidDetails(orderId) {
    //console.log("connecting to sb");
    let { data: gBid, error } = await supabase
    .from('GeneralBids')
    .select('*').eq('orderId', orderId);
    //console.log(orders);
    //printDictDetails(orders[0]);
    return gBid;
}


async function getOption(optionId) {
    //console.log("connecting to sb");
    let { data: option, error } = await supabase
    .from('options')
    .select('*').eq('optionId',optionId);
    return option;
}


async function getOptions() {
    //console.log("connecting to sb");
    let { data: options, error } = await supabase
    .from('options')
    .select('*')//.neq('tokenURI','none');
    //console.log(options);
    //printDictDetails(orders[0]);
    return options;
}

async function getOptionsListedByOwner(ownerOf) {
    // First get matching orders
    const [currentBlock] = await getBlockGas();
    
    let { data: orders, error } = await supabase
        .from('orders')
        .select('optionId')
        .eq('orderCreator', ethers.getAddress(String(ownerOf)))
        .eq('isOffer', true)
        .is('isFilled', false)
        .is('isCancelled', false)
        .gt('validUntil', currentBlock);

    if (error) {
        console.error("mops Error fetching orders:", error);
        return [];
    }

    // Then get the corresponding options
    const optionIds = orders.map(order => order.optionId);
    if (optionIds.length === 0) return [];

    let { data: options, optionsError } = await supabase
        .from('options')
        .select('*')
        .in('optionId', optionIds);

    if (optionsError) {
        console.error("Error fetching options:", optionsError);
        return [];
    }

    return options;
}

async function getOptionsByOwner(ownerOf) {
    //console.log("connecting to sb");
    let { data: owned, error } = await supabase
    .from('owners')
    .select('*').eq('owner',ethers.getAddress(String(ownerOf).toLowerCase()));
    console.log(owned);

    let options = [];
    for (var o in owned){
        let myop = await getOption(owned[o].optionId);
        options.push(myop[0]);
    }

    console.log(options);
    return options;
}


async function getOptionImage(id) {
    //console.log("connecting to sb");
    const { data } = supabase
            .storage
            .from('optionImages')
            .getPublicUrl(`OptionId${id}.svg`)
    return data;
}


async function getBlockGas() {
    //console.log("block");
    //console.log("connecting to sb ---- block gas");
    const { data, error } = await supabase
        .from('blockInfo')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(1)
    //console.log(data);
    //console.log(data[0]["block"], data[0]['gas']);
    //printDictDetails(data[0]);
    return [data[0]["block"], data[0]['gas']];
}


export {getOrders,getUniqueUnderlyingFromOrders, getBlockGas, supabase, 
        getOptions, getOptionImage, getOptionsByOwner,
         getOwners, getOrder, getOption, getGeneralBidDetails, getActiveOrderIdByOptionId, getOptionsListedByOwner, getOrderOfferOnly};