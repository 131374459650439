import React from 'react';
import { Card, CardContent, Stack, ButtonGroup, Button, Typography, Grid } from '@mui/material';
import FormattedPrice from './FormattedPrice';
import ModalCreateOrder from './ModalCreateOrder';
import { ethers } from 'ethers'
import { SelectNFT } from './OrderViewSelectNFT';
import { acceptOrder, matchOrders } from './ethCreateOffer';

import { convertBlocksToTime } from './Etherfuncs';

const OrderCard = ({ theme, order, isBid, userAccount, curr, option, openModalId, setOpenModalId, handleClose,
  contractAddress,
  strike,
  expiry,
  isCall,
  modalSelectNFTVisible,
  setModalSelectNFTVisible,
  modalSelectedNFT, 
  setModalSelectedNFT,
  mode,
  toggleTabBar,
  blockMapping
 }) => {

  // if expiry is defined, and not in blockMapping, add it to block mapping

  {/* make sure it is sorted in the right place in the list
    {
    String(expiry): {
        blocks: expiry - bb,
        time: convertBlocksToTime(expiry - bb)
      }
}
    */}

    function updateBlockMapping(blockMapping, expiry, bb) {
      const expiryKey = String(expiry);
      if (!blockMapping.hasOwnProperty(expiryKey)) {
        const blocks = expiry - bb;
        const time = convertBlocksToTime(blocks);
        const newEntry = { blocks, time };
      
        let obj =  {
          ...blockMapping,
          [expiryKey]: newEntry,
        }
        blockMapping = Object.keys(obj).sort().reduce((acc, key) => (acc[key] = obj[key], acc), {});
      }
      return blockMapping;
    }

  const newBlockMapping = updateBlockMapping(blockMapping, expiry, curr);

  const orderId = order?._orderId;


  //console.log("test567 OrderCard render - orderId:", orderId);
  //console.log("test567 OrderCard render - modalSelectedNFT:", modalSelectedNFT);

  const cardStyle = {
    backgroundImage: theme.custom.gradient1,
    margin: 2,
    minHeight: '20px',
    border: 0,
    borderColor: isBid ? 'primary.main' : 'secondary.main',
    width: '100%',
    padding:0
  };

  const buttonGroupStyle = {
    marginBottom: 0,
    marginTop: 0,
    width: '45%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  };

  return (
    <>
      <Grid item xs={12}>
        <Card style={cardStyle}>
          <CardContent sx={{ 
            padding: '0 !important', 
            paddingBottom: '0 !important',
            paddingTop: '0 !important',
            paddingLeft: isBid ? '0 !important' : '0.25rem !important',
            paddingRight: isBid ? '0.25rem !important' : '0 !important',
            margin: 0, 
            height: '100%' 
          }}>
            <Stack direction="row" sx={{ 
               minHeight: '100%', padding: 0, margin: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' 
            }}>
              {isBid && (
                <ButtonGroup orientation="vertical" sx={buttonGroupStyle}>
                   <Button 
                    variant="contained" 
                    size="small" 
                    sx={{ 
                        fontSize: 10, paddingTop: 0, paddingBottom: 0, flex: 1, color: 'white' 
                    }} 
                    color='secondary'
                    disabled={!order}
                    onClick={() => {
                      if (order) {
                          if (order.isGeneral) {
                              console.log("test567 Button clicked, current modalSelectedNFT:", modalSelectedNFT);
                              if (modalSelectedNFT[orderId]?.optionId) {
                                  console.log("test567 Accepting order with optionId:", modalSelectedNFT[orderId].optionId);
                                  if (modalSelectedNFT[orderId].isListed) {
                                      // Call matchOrders with both order IDs
                                      console.log("MATCH!!!", orderId, modalSelectedNFT[orderId].listingOrderId, modalSelectedNFT[orderId])
                                      matchOrders(orderId, modalSelectedNFT[orderId].listingOrderId);
                                  } else {
                                      // Call acceptOrder for held options
                                      acceptOrder(orderId, modalSelectedNFT[orderId].optionId);
                                  }
                              } else {
                                  console.log("test567 Opening modal for orderId:", orderId);
                                  setModalSelectNFTVisible(orderId);
                              }
                          } else {
                              console.log("test567 Non-general order, accepting directly");
                              acceptOrder(orderId);
                          }
                      }
                  }}
                >
                    Sell {modalSelectedNFT[orderId]?.optionId ? `#${modalSelectedNFT[orderId].optionId}` : ''}
                </Button>
                  <Button 
                    variant="outlined" 
                    size="small" 
                    sx={{ 
                      fontSize: 10, 
                    paddingTop: 0, 
                    paddingBottom: 0, 
                    flex: 1, 
                    color: theme.palette.mode === 'dark' ? 'white' : 'black' 
                    }} 
                    color="secondary"
                    onClick={() => setOpenModalId(`${String(strike)}${String(expiry)}${isCall? 'Call':'Put'}${isBid ? 'Bid' : 'Offer'}`)}
                  >
                    Bid
                  </Button>
                </ButtonGroup>
              )}
              
              {order ? (
                <FormattedPrice price={order.price} isBid={isBid} myOrder={String(order.orderCreator).toLowerCase() == String(userAccount).toLowerCase()}/>
              ) : (
                <FormattedPrice price={"-"} isBid={isBid} myOrder={true}/>
              )}
              
              {!isBid && (
                <ButtonGroup orientation="vertical" sx={buttonGroupStyle}>
                  <Button 
                    variant="contained" 
                    disabled={!order}
                    size="small" 
                    sx={{ 
                      fontSize: 10, paddingTop: 0, paddingBottom: 0, flex: 1, borderColor: 'white'
                    }} 
                    color='primary'
                    onClick={() => {
                      if (order) {
                        acceptOrder(orderId);
                      } else {
                        console.log("Order is not defined");
                      }
                    }}// Open modal on click
                  >
                    Buy
                  </Button>
                  <Button 
                    variant="outlined" 
                    size="small" 
                    sx={{ 
                      fontSize: 10, paddingTop: 0, paddingBottom: 0, flex: 1, color: theme.palette.mode === 'dark' ? 'white' : 'black'
                    }} 
                    color="primary"
                    onClick={() => setOpenModalId(`${String(strike)}${String(expiry)}${isCall? 'Call':'Put'}${isBid ? 'Bid' : 'Offer'}`)}
                  >
                    Ask
                  </Button>
                </ButtonGroup>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      {/* ModalCreateOrder component 
      Bid should be general by default
      Offer is always specific
      TODO: Be able to choose general or specific for bids
      */}

      <ModalCreateOrder
        key={`modal-${openModalId}-${userAccount}`}
        activeStrike={strike} //ethers.formatEther(String(option.strike))
        activeExpiry={expiry}
        underlyingContract={contractAddress}
        activePC={isCall ? 'call' : 'put'}
        bb={curr}
        switchPC={isCall ? 'Call' : 'Put'}
        isSpecific={!isBid}
        activeUnderlyingID={null} //order.underlyingId
        optionID={null} //order.optionId
        orderType={isBid ? 'Bid' : 'Offer'} //(String(order.ownerOf).toLowerCase() === String(userAccount).toLowerCase()) ? 'Offer' : 'Bid'
        openT={openModalId === `${String(strike)}${String(expiry)}${isCall? 'Call':'Put'}${isBid ? 'Bid' : 'Offer'}`} // Check if this modal should be open
        setOpenT={handleClose} // Close the modal
        expIsBlock={true}
        blockMapping={newBlockMapping}
        userAccount={userAccount}
      />

{(order && userAccount && isBid) && (
    <SelectNFT
        open={modalSelectNFTVisible === orderId}  // Simplified condition
        setOpen={setModalSelectNFTVisible}
        setModalSelectedNFT={(newSelection) => {
            console.log("test567 Setting modalSelectedNFT in OrderCard:", newSelection);
            setModalSelectedNFT(newSelection);
        }}
        modalSelectedNFT={modalSelectedNFT}
        userAccount={userAccount}
        filterContract={contractAddress}
        filterIsCall={isCall}
        filterStrike={strike}
        filterExpiry={expiry}
        orderId={orderId}
        theme={theme}
        mode={mode}
        toggleTabBar={toggleTabBar}
    />
)}

    </>
  );
};

export default OrderCard;