import * as React from "react"

const Ztrike = (props) => {
  const lstyle = `.st0{fill:${props.logocolor}}`;

  const textColor = props.mode == 'light' ? '#000':'#fff'

  const tstyle = `.st1{fill:${textColor}}`;
  //console.log("textColor", textColor);

  return(
  <svg
    id="svg15"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    width={props.width}
    viewBox="0 0 370 97.2"
    style={{
      enableBackground: "new 0 0 370 97.2",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{lstyle}</style>
    <style>{tstyle}</style>
    <g id="SvgjsG1016">
      <path
        id="rect3"
        className="st0"
        d="M9.6 0h61.6c5.3 0 9.6 4.3 9.6 9.6v78c0 5.3-4.3 9.6-9.6 9.6H9.6c-5.3 0-9.6-4.3-9.6-9.6v-78C0 4.3 4.3 0 9.6 0z"
      />
    </g>
    <g id="SvgjsG1017" transform="matrix(1.64313 0 0 1.64313 17.479 -12.16)">
      <path
        id="path6"
        d="M8.2 48.3c-1 0-1.5-.9-1.5-1.5v-6.2c0-.8.3-1.3 1-1.8L22 30.6l-2.7-1.5-10.5 6c-.2.1-.5.2-.8.2-.8 0-1.5-.7-1.5-1.5 0-.6.3-1 .8-1.3l10.9-6.3c.4-.2.7-.3 1-.3s.7.1 1 .3l5.3 3.1c.6.3.7.9.7 1.3 0 .5-.3 1-.7 1.3L9.6 41.2v3.1l14.7-8.5c.2-.1.5-.2.8-.2.8 0 1.5.7 1.5 1.5 0 .6-.3 1-.8 1.3L8.9 48.1c-.2.1-.5.2-.7.2zM2.5 58c-1 0-1.5-.9-1.5-1.5v-13c0-.8.7-1.5 1.5-1.5s1.5.8 1.5 1.6V54l20.2-11.7c.6-.3 1.2-.2 1.5 0 .6.3.7.9.7 1.3v6.2c0 .8-.3 1.3-1 1.7l-10.9 6.3c-.2.2-.5.2-.7.2-.8 0-1.5-.7-1.5-1.5 0-.6.3-1 .8-1.3l10.5-6v-3.1L3.3 57.8c-.2.2-.5.2-.8.2zm.1-25.9c-1 0-1.5-.9-1.5-1.5v-6.2c0-.8.3-1.3 1-1.8L13 16.4c.2-.1.5-.2.8-.2.8 0 1.5.7 1.5 1.5 0 .6-.3 1-.8 1.3L4 25v3.1l14.3-8.3c.4-.2.7-.3 1-.3.3 0 .7.1 1 .3l5.3 3.1c.5.3.8.7.8 1.3 0 .8-.7 1.5-1.5 1.5-.3 0-.5-.1-.8-.2l-4.8-2.8-16 9.2c-.2.1-.5.2-.7.2z"
        style={{
          fill: "#ffff",
        }}
      />
    </g>
    <g id="SvgjsG1018" transform="matrix(2.15237 0 0 2.15237 100.408 19.759)">
      <path
        id="path9"
        d="M15.2 7.8v2.3L4.3 16.5h10.9v2.3H-.1v-2.3l10.9-6.4H-.1V7.8h15.3zm3.2 0h15.3v2.3h-6.5v8.8H25v-8.8h-6.5l-.1-2.3zm32.4 8.8h1.1v2.2H50.2c-1.6 0-4-.2-5.6-1.7-.7-.7-1.1-1.6-1.3-2.6h-4v4.3h-2.2L37 9V7.9h11.6c1.4 0 3.5.9 3.5 3.3s-2 3.3-3.3 3.3h-3.4c.1.4.3.8.6 1 1.3 1.1 3.7 1.1 4.8 1.1zm-11.5-4.4H49c.1 0 .4 0 .6-.2.2-.1.4-.3.4-.9 0-.4-.2-.7-.5-.9-.4-.2-.7-.2-.7-.2h-9.4l-.1 2.2zm18.4 6.7h-2.2V8.1h2.2v10.8zm18.4-2.3v2.2H74.4c-1.5 0-4-.2-5.6-1.7-.7-.7-1.2-1.7-1.3-2.8-.8.1-1.7.2-2.7.2h-1.6v4.3H61V8h2.2v4.3h1.6c4.6 0 7-1.6 7-3.1V8.1H74v1.1c0 1.8-1.4 3.7-4.3 4.7.1.7.3 1.3.7 1.7 1.1 1.1 3.5 1.1 4.6 1.1l1.1-.1zm18.6-6.4H81.6v2.1h8.7v2.3h-8.7v2.1h13.1V19H79.4V8h15.3v2.2z"
        className="st1"
        style={{
            fill: {textColor},
          }}
      />
    </g>
    <g id="SvgjsG1019" transform="translate(321.357 19.151) scale(2.18271)">
      <path
        id="path12"
        d="M-1.7 18.5H-4v-2.3h2.3v2.3zm5.5.4H1.6V8.1h2.2v10.8zM21.4 9c.9.9.9 2.1.9 4.1v.4c0 1.1 0 2-.1 2.7-.1.6-.3 1.4-1.2 2-1.1.7-2.7.7-6.3.8h-1.5c-2.8 0-4.2-.1-5.2-1.1s-1-2.4-1-4.4c0-2.3 0-3.7 1.2-4.6 1-.8 2.5-.8 5.8-.8h.6c4.1-.2 5.8-.2 6.8.9zM20 13c0-1.1 0-2.2-.2-2.5-.3-.3-1.5-.3-3-.3H14c-2.3 0-4 .1-4.5.4-.3.2-.3 1.4-.3 2.8 0 1.3 0 2.5.3 2.8.4.4 2.8.4 5.1.4 2.6 0 4.5 0 5.1-.4.3-.1.3-1.2.3-2.7V13z"
        className="st1"
        style={{
            fill: {textColor},
          }}
      />
    </g>
  </svg>
)};

export default Ztrike
