import * as React from 'react';
import {Box, Skeleton, Link, IconButton,FormGroup, FormControlLabel, Checkbox, Card, CardContent, CardActions, Stack, Divider, Grid, TextField, Button, InputLabel, MenuItem, FormControl, Select, Typography, ButtonGroup, CardMedia} from '@mui/material';
import {SelectNFTmodal} from './SelectNFTModal.js';
import { ethers } from 'ethers'

import {Logo} from './ethlogo.js';
import InputAdornment from '@mui/material/InputAdornment';
import IOSSwitch from './IOSwitch';
import {ContractModal} from './ContractModal';
import UploadFile from './IPFSupload';
import LinearProgress from './LinearProgress';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useState, useEffect } from 'react';
import OSwhite from './logos/OSwhite.svg' ;
import ModalCreateOrder from './ModalCreateOrder';
import { useTheme } from '@material-ui/core/styles';

import RedeemPutManager from './RedeemPutManager';

//import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json'
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json'
import Marketplace from './artifacts/contracts/Marketplace.sol/Marketplace.json'
import {ZtrikeAddress,  NFTAddress, MarketplaceAddress} from './ethContracts';
import {initializeWallet, redeemOption, regretOption, retrieveOption } from "./Etherfuncs";
import {ethCreateOfferInitializeWallet, createOrder, acceptOrder, cancelOrder} from './ethCreateOffer.js';
import { getOrder, getBlockGas, supabase, getOptions, getOptionImage, getOwners, getActiveOrderIdByOptionId, getOrderOfferOnly} from './supaBaseFuncs.js';
import { getWrittenOptions, getTransactions } from './Etherscan.js';
import OptCardMedia from './OptCardMedia.js';

import { useConnectWallet } from '@web3-onboard/react'
import SearchableContractInput from './contractSelectDropdown.js';

const INFURA_URL = process.env.REACT_APP_INFURA_URL;

//(BigInt.prototype).toJSON = function () {
//    return Number(this.toString());
//  }; // monkey path

export function OptionsView(props) {
  const theme = props.theme;
  const mode = props.mode;
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

  React.useEffect(() => {
    if (wallet) {
      initializeWallet(wallet);
      ethCreateOfferInitializeWallet(wallet);
    }
  }, [wallet]);


      const switchPC = props.switchPC;
      const userAccount = props.userAccount;
      const toggleTabBar = props.toggleTabBar;

      const [MyOrders, setMyOrders] = useState([]);
      const [curr, setCurr] = React.useState(6637107);
      const [currGas, setCurrGas] = React.useState('11');
      const [filterOn, setFilterOn] = React.useState(false);

      const [filterContract, setFilterContract] = useState('');
      const [filterCreator, setFilterCreator] = useState('');
      const [showExpired, setShowExpired] = useState(false);
      const [showCancelled, setShowCancelled] = useState(false);
      const [filteredOptions, setFilteredOptions] = useState([]);

      const [rpOpen, setRPopen] = React.useState(false); // currently one value for all options 
      const [RPselectText, setRPSelectText] = React.useState('Select NFT'); // currently one value for all options
      const [selectedNFTid, setSelectedNFTid] = React.useState(6); // currently one value for all options


      const [listOfOptions, setListOfOptions] = React.useState([])

      const [activeOrderIds, setActiveOrderIds] = React.useState({}); // State to hold active order IDs
      
      const [openModalId, setOpenModalId] = useState(null); // State to manage which modal is open

      const [redeemFlowId, setRedeemFlowId] = useState(null);
      const [nftSelections, setNftSelections] = useState({});
      const [showMyOptions, setShowMyOptions] = useState(false);

      const [orderDetails, setOrderDetails] = useState({});

      const [urlFilterOptionId, setUrlFilterOptionId] = useState('');
      const [showSpecificOption, setShowSpecificOption] = useState('');

      const [uniqueContracts, setUniqueContracts] = useState(['Nothing']);

      const handleOpen = (optionId) => {
          setOpenModalId(optionId); // Set the currently open modal ID
      };
  
      const handleClose = () => {
          setOpenModalId(null); // Close the modal
      };
      //const handleOpen = () => setOpenT(true);
      //const handleClose = () => setOpenT(false);
    

      useEffect(() => {
        const fetchActiveOrderIds = async () => {
            const ids = {};
            for (const option of filteredOptions) {
                const orderId = await getActiveOrderIdByOptionId(option.optionId);
                ids[option.optionId] = orderId; // Store the order ID in the object with optionId as the key
            }
            setActiveOrderIds(ids); // Update the state with the fetched order IDs
        };
    
        fetchActiveOrderIds(); // Call the function to fetch the order IDs
    }, [filteredOptions]); // Dependency array to re-fetch if filteredOptions changes
    


 // Add this new function to sort the options
 const sortOptions = (options, userAccount) => {
  return options.sort((a, b) => {
    const aIsOwner = a.ownerOf?.toLowerCase() === userAccount?.toLowerCase();
    const bIsOwner = b.ownerOf?.toLowerCase() === userAccount?.toLowerCase();
    
    // Get the first (most recent) order for each option
    const aOrder = orderDetails[a.optionId]?.[0];
    const bOrder = orderDetails[b.optionId]?.[0];
    
    const aIsOrderCreator = a.ownerOf?.toLowerCase() === MarketplaceAddress?.toLowerCase() && 
                           aOrder?.orderCreator?.toLowerCase() === userAccount?.toLowerCase();
    const bIsOrderCreator = b.ownerOf?.toLowerCase() === MarketplaceAddress?.toLowerCase() && 
                           bOrder?.orderCreator?.toLowerCase() === userAccount?.toLowerCase();
    
    if (!!(aIsOwner || aIsOrderCreator) && !(bIsOwner || bIsOrderCreator)) return -1;
    if (!(aIsOwner || aIsOrderCreator) && !!(bIsOwner || bIsOrderCreator)) return 1;
    
    return a.optionId - b.optionId;
  });
};




    // filter options
    useEffect(() => {
      console.log("StateChanged", listOfOptions[0], curr);
  
      if (listOfOptions.length > 0){
          const f = listOfOptions.filter(option => {
              // Check URL filter first
              if (urlFilterOptionId !== '') {
                  return Number(option.optionId) === urlFilterOptionId;
              }
              
              const contractMatch = !filterContract || option.underlyingContract?.toLowerCase() === filterContract?.toLowerCase();
              const creatorMatch = !filterCreator || option.optWriter?.toLowerCase() === filterCreator?.toLowerCase();
              const expiryMatch = showExpired || (Number(option.expiry) - curr) >= 1;
              const cancelledMatch = showCancelled || (!option.isCancelled && (option.ownerOf?.toLowerCase() !== '0x0000000000000000000000000000000000000000'));
              
              // Get the first (most recent) order
              const currentOrder = orderDetails[option.optionId]?.[0];
              
              const myOptionsMatch = !showMyOptions || !userAccount || (
                option.ownerOf?.toLowerCase() === userAccount?.toLowerCase() || // User owns the option
                option.optWriter?.toLowerCase() === userAccount?.toLowerCase() || // User wrote the option
                (option.ownerOf?.toLowerCase() === MarketplaceAddress?.toLowerCase() && // Option is in marketplace
                 currentOrder?.orderCreator?.toLowerCase() === userAccount?.toLowerCase()) // User created the order
              );
              
              return contractMatch && creatorMatch && expiryMatch && cancelledMatch && myOptionsMatch;
            });
          console.log(f);
          const sortedOptions = userAccount ? sortOptions(f, userAccount) : f;
          setFilteredOptions(sortedOptions);
      }
    }, [listOfOptions, 
      showExpired, showCancelled, showMyOptions, 
      filterContract, filterCreator, curr, userAccount, urlFilterOptionId]);
  

      
      async function getOptionsFromSupabase(){
            setListOfOptions([]);
            var options = await getOptions();
            const owners = await getOwners();
            console.log("Owners:", owners);
            console.log('Load Options:',options);
            console.log(options.length);
            
            // Get unique contracts from options - add null/undefined check
            const unique = [...new Set(options
                .filter(opt => opt.underlyingContract) // Filter out any null/undefined contracts
                .map(opt => opt.underlyingContract.toLowerCase()))] // Normalize case
                .filter(contract => ethers.isAddress(contract)); // Validate addresses
            
            console.log("Unique contracts found:", unique); // Debug log
            setUniqueContracts(unique.length > 0 ? unique : [NFTAddress]); // Fallback to NFTAddress if no contracts found
            
            for (var i=0; i < options.length; i++){
                let thisOp = owners.findIndex(({ optionId }) => optionId === options[i].optionId);
                let thisOwner = '0x0000000000000000000000000000000000000000';
                try{
                  thisOwner = owners[thisOp]['owner'];
                } catch{
                  console.log(thisOp, owners[thisOp], "Failed to find owner");
                }
                
                 //console.log(i)
                 if (options.tokenURI != 'none'){
                  let optImg = await getOptionImage(options[i].optionId);
                  //console.log(optImg);
                  let ownerOf = '...';//await ZtrikeContractProvider.ownerOf(options[i].optionId);
                  options[i] = { ...options[i], 
                               "publicURL": optImg['publicUrl'],
                               'ownerOf': thisOwner};
                 }
                }
            console.log("options >>>>", options);
            setListOfOptions(options);
      }

      React.useEffect( () => {
                
        getOptionsFromSupabase();
        
      }, []);


      useEffect(() => {
        console.log("supabase: SUBSCRIBING TO OPTIONS CHANNEL");
        const channels = supabase.channel('optionsView-options-channel')
        .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'options' },
        (payload) => {
            console.log('supabase: Change received!', payload);
            getOptionsFromSupabase();
        }
        )
        .subscribe()
      
        // add return right here!
        return () => channels.unsubscribe();
      }, []);


      useEffect(() => {
        console.log("supabase: SUBSCRIBING TO OWNERS CHANNEL");
        const channels = supabase.channel('optionsView-owbers-channel')
        .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'owners' },
        (payload) => {
            console.log('supabase: owners: Change received!', payload);
            getOptionsFromSupabase();
        }
        )
        .subscribe()
      
        // add return right here!
        return () => channels.unsubscribe();
      }, []);

      const fetchBlock = async () => {
        let [bb, fGas] = await getBlockGas();
        console.log("GETTING BLOCKNO...")
        setCurrGas(fGas);
        setCurr(bb);
    }
    
    function toggleFilter(){
        setFilterOn(!filterOn);
    }

    useEffect(() => {
      console.log("fetching blocks at startup");
          fetchBlock();

    }, []);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            const details = {};
            for (const optionId in activeOrderIds) {
                if (activeOrderIds[optionId]) {
                    const order = await getOrder(activeOrderIds[optionId]);
                    details[optionId] = order;
                }
            }
            console.log("details >>>>", details);
            setOrderDetails(details);
        };

        if (Object.keys(activeOrderIds).length > 0) {
            fetchOrderDetails();
        }
    }, [activeOrderIds]);

    useEffect(() => {
        const handleHashChange = () => {
            const hashParts = window.location.hash.toLowerCase().split('#');
            // Remove empty string from split
            const filteredParts = hashParts.filter(part => part);
            
            if (filteredParts[0] === 'options' && filteredParts[1] !== undefined) {
                // Convert to integer and validate
                const optionId = parseInt(filteredParts[1], 10);
                if (!isNaN(optionId) || optionId === 0) {
                    setUrlFilterOptionId(optionId);
                    setShowSpecificOption(optionId.toString());
                } else {
                    setUrlFilterOptionId('');
                    setShowSpecificOption('');
                }
            } else {
                setUrlFilterOptionId('');
                setShowSpecificOption('');
            }
        };

        handleHashChange();
        window.addEventListener('hashchange', handleHashChange);
        return () => window.removeEventListener('hashchange', handleHashChange);
    }, []);

    const clearUrlFilter = () => {
        setUrlFilterOptionId('');
        setShowSpecificOption('');
        window.history.replaceState(null, null, '#options');
    };

    return(
    <Card sx={{minHeight:'500px', maxWidth:{xs:'100%', sm:'100%', lg:'100%'}, minWidth: {sm:'97%', sm:'90%', lg:'50%'},
     paddingLeft:1, paddingRight:0, paddingTop:1}}>
      <CardContent sx={{paddingLeft:0, paddingRight:0,  marginLeft:1, marginRight:0}}>    
          
          <Grid container  spacing={{ xs: 2, sm:3, md: 4 }} justify='top' 
          alignItems="stretch" columns={{ xs: 2, sm: 4, md: 6 }}>              
              <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <Typography variant="h6" align='left' style={{paddingBottom:0, paddingTop:2}}>
                    {urlFilterOptionId ? `Option #${urlFilterOptionId}` : 'Options'}
                  </Typography>
                  
                    <Stack 
                    direction="row" 
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        paddingTop: 2,
                        paddingRight:1,
                        alignContent:'top'

                    }}
                    >
           
                    <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-6.5, paddingRight:1 }}>
                        <IconButton onClick={() => toggleFilter()}>
                            {filterOn ?  <FilterListOffIcon/> : <FilterListIcon/>}
                        </IconButton>
                    </Box>
                   

                    <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-6 }}>
                    <Button variant="outlind" size="small" 
                        onClick={() => toggleTabBar(0)} color = {switchPC == 'Call' ? 'primary': 'secondary'}
                        sx={{border:1, borderColor:'primary.main', width:'100%'}}>
                          <Typography sx={{fontSize:12, color:'primary.main'}}>
                          Write new option
                          </Typography>
                        
                        </Button>
                    </Box>
                    </Stack>

                    
                    
                    {/* Only show My Options checkbox if user is connected */}
                    {userAccount && (
                      <Stack 
                      direction="row" 
                      sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '100%',
                          paddingTop: 0,
                          paddingRight:0,
                          alignContent:'top'
  
                      }}
                      >
                      <Box sx={{ width: 'auto', paddingBottom:1, marginTop:-2, paddingRight:0 }}>
                        <FormGroup>
                          <FormControlLabel 
                            control={
                              <Checkbox 
                                checked={showMyOptions} 
                                onChange={(e) => setShowMyOptions(e.target.checked)}
                                sx={{ 
                                  '& .MuiSvgIcon-root': { 
                                    fontSize: 20  // Smaller checkbox size
                                  }
                                }}
                              />
                            } 
                            label="My options"
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '0.875rem'  // Smaller label text (14px)
                              }
                            }}
                          />
                        </FormGroup>       
                      </Box>
                      </Stack>
                    )}
                  
                    {filterOn ? 
                    <>
                        <Divider textAlign="left" sx={{paddingBottom:2}}>Filter Orders</Divider>
                        <SearchableContractInput
                            contracts={uniqueContracts}
                            onChange={setFilterContract}
                            label="Underlying Contract"
                            sx={{paddingBottom:2, paddingRight:1}}
                        />
                        
                        <TextField 
                            id="outlined-basic" 
                            label="Option Writer" 
                            fullWidth
                            variant="outlined" 
                            size="small"
                            value={filterCreator}
                            onChange={(e) => setFilterCreator(e.target.value)}
                            sx={{paddingBottom:-1, paddingRight:1}}
                        />
                        
                        <TextField 
                            id="outlined-basic" 
                            label="Option Owner" 
                            fullWidth
                            variant="outlined" 
                            size="small"
                            value={filterCreator}
                            onChange={(e) => setFilterCreator(e.target.value)}
                            sx={{paddingBottom:-1, paddingRight:1}}
                        />

                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={showExpired} 
                                        onChange={(e) => setShowExpired(e.target.checked)}
                                    />
                                } 
                                label="Show expired options" 
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={showCancelled} 
                                        onChange={(e) => setShowCancelled(e.target.checked)}
                                    />
                                } 
                                label="Show cancelled/redeemed options" 
                            />
                        </FormGroup>
                        <Divider sx={{paddingTop:2}}/>
                    </>
                    : ''}
                {filterOn ? 
                     <Divider sx={{paddingTop:2}}/>
                    : ''}

                
              </Grid>

              <Grid container item xs={12} wrap='nowrap' style={{ paddingTop: 0, 
                                                maxHeight:'1000px',
                                                overflowY:'hidden',
                                                overflowX: 'atuo',
                                                display: "flex",
                                                flexDirection:"row",
                                                maxWidth: '100%',
                                                scrollbarColor: '#282929 #6f7070',
                                                scrollbarWidth: 'thin',
                                                marginBottom: 0}}>
                {  (filteredOptions.length == 0) ? 
                [1,2,3,4,5,6].map( (e, index) => { 
                  //console.log("ee", e);
                  return (
                <Grid item xs={12} sm={10} md={6} 
                style={{ padding: 3, paddingTop:0, marginTop:0, marginBottom:10,
                 minWidth:'190px', maxWidth:'190px', minHeight:'400px'}} 
                 key={`skeleton-${index}`}>
                     <Card style={{backgroundImage: theme.custom.gradient1, 
                        padding:2, height:'100%'}}>
                            <Box style={{marginTop:0, paddingTop:0}}>
                          
                          <Skeleton variant="rounded" width={210} height={250} />
                          
                          <Skeleton variant="rectangular" width={210} height={60} />
                          
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            </Box>
                            </Card>
                        </Grid>
                  )})
                :
                filteredOptions.map( (e, index) => { 
                        //console.log("ee", e);
                        return (
                            <Grid item  key={`filteredOptions-${index}-${e.optionId}`} xs={12} sm={10} md={6} 
                            style={{ padding: 3, paddingTop:0, marginTop:0, marginBottom:10,
                             minWidth:'190px', maxWidth:'190px', minHeight:'200px'}} 
                             >
                                 <Card style={{backgroundImage: theme.custom.gradient1, 
                                    padding:2, height:'100%'}}>
                                        <Box style={{marginTop:0, paddingTop:0}}>
                                        <OptCardMedia currOptionImage={e.publicURL} height='310px'/>

                                        </Box>
                                      <Stack direction="column" sx={{justifyItems:'left', alignItems:'flex-start'}}>

                                      
                                        <Stack direction="row" spacing={1} align='bottom'>
                                            <Typography gutterBottom variant="h6" component="div" sx={{paddingTop: '8px'}}>
                                            Option # {String(e.optionId)}
                                            </Typography>
                                            <IconButton onClick={() => window.open(`https://testnets.opensea.io/assets/sepolia/${ZtrikeAddress}/${e.optionId}`)}><img src={OSwhite} width={20} /></IconButton>
                                        </Stack>


                                        <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                          {e.isCall ? 'Call' : 'Put'} 
                                          <Link color={e.isCall ? 'primary': 'secondary'}  target="_blank" href={`https://sepolia.etherscan.io/address/${ZtrikeAddress}`}>
                                            {e.contractAddress.substring(0,5) + '...' + e.contractAddress.substring(38,50)} </Link> @ {ethers.formatEther(String(e.strike))}
                                        </Typography>
                                        
                                        <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                        {e.isCall ? `Underlying ID: ${e.underlyingId}` : 'Any NFT from collection'}
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                          Expiry:  <Link color={e.isCall ? 'primary': 'secondary'}  target="_blank" href={`https://sepolia.etherscan.io/block/${e.expiry}`}>  {e.expiry + '  '} </Link> {(Number(e.expiry) < curr ? '(Expired)' : '(Active)')}
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                        Owner: 
                                        {String(e.ownerOf).toLowerCase() === String(MarketplaceAddress).toLowerCase() ? (
                                            <Link 
                                                color={e.isCall ? 'primary' : 'secondary'} 
                                                target="_blank" 
                                                href={`https://sepolia.etherscan.io/address/${e.ownerOf}`}
                                            >
                                                Marketplace
                                            </Link>
                                        ) : String(e.ownerOf).toLowerCase() === "0x0000000000000000000000000000000000000000" ? (
                                            <Link 
                                                color={e.isCall ? 'primary' : 'secondary'} 
                                                target="_blank" 
                                                href={`https://sepolia.etherscan.io/address/${e.ownerOf}`}
                                            >
                                                Burned
                                            </Link>
                                        ) : (
                                            <Link 
                                                color={e.isCall ? 'primary' : 'secondary'} 
                                                target="_blank" 
                                                href={`https://sepolia.etherscan.io/address/${e.ownerOf}`}
                                            >
                                                {e.ownerOf.toLowerCase().substring(0, 5) + '...' + e.ownerOf.toLowerCase().substring(38, 50)}
                                            </Link>
                                        )}  
                                        {String(e.ownerOf).toLowerCase() === String(userAccount).toLowerCase() ? ' (You)' : ''}
                                    </Typography>

                                    <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                        Writer: 
                                        <Link 
                                            color={e.isCall ? 'primary' : 'secondary'} 
                                            target="_blank" 
                                            href={`https://sepolia.etherscan.io/address/${e.optWriter}`}
                                        >
                                            {String(e.optWriter).toLowerCase() === String(userAccount).toLowerCase() ? 'You' : 
                                            String(e.optWriter).substring(0, 5) + '...' + String(e.optWriter).substring(38, 50)}
                                        </Link> 
                                        {String(e.optWriter).toLowerCase() === String(userAccount).toLowerCase() ? ' (you)' : ''}
                                    </Typography>
                                        
                                    {String(e.ownerOf).toLowerCase() === String(MarketplaceAddress).toLowerCase() && orderDetails[e.optionId]?.[0] && (
                                        <>
                                            <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                                OrderCreator: 
                                                <Link 
                                                    color={e.isCall ? 'primary' : 'secondary'} 
                                                    target="_blank" 
                                                    href={`https://sepolia.etherscan.io/address/${orderDetails[e.optionId][0].orderCreator}`}
                                                >
                                                    {String(orderDetails[e.optionId][0].orderCreator).toLowerCase() === String(userAccount).toLowerCase() ? 'You' : 
                                                    String(orderDetails[e.optionId][0].orderCreator).substring(0, 5) + '...' + String(orderDetails[e.optionId][0].orderCreator).substring(38, 50)}
                                                </Link>
                                                {String(orderDetails[e.optionId][0].orderCreator).toLowerCase() === String(userAccount).toLowerCase() ? ' (you)' : ''}
                                            </Typography>
                                            <Typography gutterBottom variant="body2" component="div" style={{fontSize:12}}>
                                                OrderId: 
                                                <Link 
                                                    color={e.isCall ? 'primary' : 'secondary'} 
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        window.directLinkNavigation = true;
                                                        // Set the hash after a short delay to ensure the flag is read
                                                        setTimeout(() => {
                                                            window.location.hash = `orders#${orderDetails[e.optionId][0]._orderId}`;
                                                            toggleTabBar(3);
                                                        }, 0);
                                                    }}
                                                >
                                                    {orderDetails[e.optionId][0]._orderId}
                                                </Link>
                                            </Typography>
                                        </>
                                    )}
                                        
                                        </Stack>   
                                        <CardActions>
                                        <ButtonGroup style={{margin:0, minWidth:'100%', display:'flex'}}>
                                          <Button size="small" 
                                            color = 'primary'
                                            variant='outlined'
                                            style={{padding:1}}
                                            sx={{flexGrow:1}}
                                            disabled={((String(e.optWriter).toLowerCase() == String(e.ownerOf).toLowerCase())
                                              | (String(e.ownerOf).toLowerCase() != String(userAccount).toLowerCase()))}
                                            onClick={()=>  setRedeemFlowId(e.optionId)}>
                                            
                                            <Typography style={{fontSize:11, fontWeight:500}}>
                                            Redeem
                                            </Typography>
                                              
                                              
                                            </Button>
                                            
                                            <Button size="small"
                                            color = 'primary'
                                            variant='outlined'
                                            style={{padding:1}}
                                            sx={{flexGrow:1}}
                                            disabled={e.expiry > curr | String(e.ownerOf).toLowerCase() == "0x0000000000000000000000000000000000000000"}
                                            onClick={()=> retrieveOption(e.optionId, e.isCall)  }
                                            >
                                              <Typography style={{fontSize:11, fontWeight:500}}>
                                            Retrieve
                                            </Typography>
                                            </Button>
                                            
                                            <Button size="small"
                                            color = 'primary'
                                            variant='outlined'
                                            style={{padding:1}}
                                            sx={{flexGrow:1}}
                                            disabled={!(String(e.optWriter).toLowerCase() === String(e.ownerOf).toLowerCase()) | !(String(e.ownerOf).toLowerCase() === String(userAccount).toLowerCase())}
                                            onClick={()=> regretOption(e.optionId) }
                                            >
                                            <Typography style={{fontSize:11, fontWeight:500}}>
                                            Regret
                                            </Typography>
                                            </Button>
                                        </ButtonGroup>
                                      </CardActions>

                                  {((!e.isCall ) && (redeemFlowId==e.optionId) ) ?
                                    <Typography fontSize={8}>
                                      Select an NFT to exercise your put option
                                    </Typography>
                                    :''
                                    }
                                      {((!e.isCall ) && (redeemFlowId==e.optionId) ) ?
                                          <RedeemPutManager
                                            options={[e]} // Pass the current option in an array
                                            userAccount={userAccount}
                                            switchPC={switchPC}
                                            disabled={(e.expiry < curr) || (String(e.ownerOf).toLowerCase() !== String(userAccount).toLowerCase())}
                                            curr={curr}
                                            nftSelections={nftSelections}
                                            setNftSelections={setNftSelections}
                                          />
                                      :''}

                                     {((redeemFlowId==e.optionId) && (nftSelections[e.optionId]!=undefined))  &&
                                     <Box key={`OptionView-redeem-put-${e.optionId}`} sx={{height: '25px', width:'100%', paddingTop:0, paddingBottom:0, paddingLeft:'7px', paddingRight:'7px', marginBottom:'7px'}}>
                                        <Button variant="contained"
                                                size='small'
                                                style={{
                                                  paddingTop: 0,
                                                  paddingBottom: 0,
                                                  maxWidth: "100%",
                                                  //maxHeight: "100%",
                                                  minWidth: "100%",
                                                  minHeight: "100%",
                                                  marginTop: 0,
                                                  fontSize:'12px'
                                                }}
                                                color={switchPC == 'Call' ? 'primary': 'secondary'}
                                        disabled={nftSelections[e.optionId]==undefined}
                                        onClick={()=>redeemOption(e.optionId, e.isCall, nftSelections[e.optionId], ethers.formatEther(String(e.strike)), e.contractAddress)}>
                                          Exercise put {String(nftSelections[e.optionId])}
                                        </Button>
                                     </Box>
                                      
                                     }
                                      
                                      { (String(e.optWriter).toLowerCase() === String(userAccount).toLowerCase()  )&&
                                        (String(e.ownerOf).toLowerCase() === String(MarketplaceAddress).toLowerCase()) ?
                                            <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', gap:1, height:'50px'}}>
                                              <Typography key={e.optionId} variant="body2" 
                                                style={{ color: mode === "light" ? "primary.dark" : "primary.light", fontSize: '0.75rem' }}>
                                                Active in order #{activeOrderIds[e.optionId] !== undefined ? String(activeOrderIds[e.optionId]) : 'N/A'}
                                              </Typography>
                                              {activeOrderIds[e.optionId] !== undefined && orderDetails[e.optionId]?.[0] && (
                                                <Box sx={{margin:7, marginTop:0, width:'100%'}}>
                                                  <ButtonGroup 
                                                    size="small" 
                                                    sx={{
                                                      width: '100%',
                                                      height: '25px',
                                                      '& .MuiButton-root': {
                                                        minHeight: '25px',
                                                        height: '25px'
                                                      }
                                                    }}
                                                  >
                                                    <Button
                                                      variant="outlined"
                                                      color="primary"
                                                      onClick={() => {
                                                          // Navigate to orders tab and show specific order
                                                          window.history.replaceState(null, null, `#orders#${activeOrderIds[e.optionId]}`);
                                                          toggleTabBar(3); // Navigate to orders tab
                                                      }}
                                                      sx={{
                                                          fontSize: '11px',
                                                          fontWeight: 500,
                                                          padding: '0px 6px',
                                                          flexGrow: 1
                                                      }}
                                                    >
                                                      View
                                                    </Button>
                                                    <Button
                                                      variant="outlined"
                                                      color="error"
                                                      onClick={() => cancelOrder(activeOrderIds[e.optionId])}
                                                      sx={{
                                                        fontSize: '11px',
                                                        fontWeight: 500,
                                                        padding: '0px 6px',
                                                        flexGrow: 1
                                                      }}
                                                    >
                                                      Cancel
                                                    </Button>
                                                  </ButtonGroup>
                                                </Box>
                                              )}
                                            </Box>
                                        :
                                        <Box sx={{width:'100%', display:'flex', justifyContent:'center', maxHeight:'30px'}}>
                                        <div style={{margin:7,
                                            marginTop:0,
                                            justifyContent:"center", 
                                            width:'100%',
                                            display: 'flex'}}>
                                            <Button variant="outlined" size="small" disabled={(e.expiry < curr)} 
                                            onClick={() => handleOpen(e.optionId)} color = {props.switchPC == 'Call' ? 'primary': 'secondary'}
                                            sx={{border:1,                                               
                                            borderColor: !(String(e.ownerOf).toLowerCase() === String(userAccount).toLowerCase()) ? 'primary.main' : 'secondary.main',
                                             width:'100%'}}>

                                              <Typography sx={{fontSize:12, 
                                                color: !(String(e.ownerOf).toLowerCase() === String(userAccount).toLowerCase()) ? 'primary.main' : 'secondary.main'
                                                }}>
                                              { (String(e.ownerOf).toLowerCase() === String(userAccount).toLowerCase()) ? `Sell` : 'Bid'}
                                              </Typography>
                                            
                                            </Button>
                                            <ModalCreateOrder
                                              key={`modal-${e.optionId}-${userAccount}`}
                                              activeStrike={ethers.formatEther(String(e.strike))}
                                              activeExpiry={e.expiry}
                                              activePC={e.isCall ? 'call':'put'}
                                              bb={curr}
                                              switchPC = {'Call'}
                                              activeUnderlyingID = {e.underlyingId}
                                              optionID = {e.optionId}
                                              underlyingContract={e.contractAddress}
                                              orderType = { (String(e.ownerOf).toLowerCase() === String(userAccount).toLowerCase()) ? 'Offer' : 'Bid'}
                                              isSpecific = {true}
                                              openT={openModalId === e.optionId} // Check if this modal should be open
                                              setOpenT={handleClose} // Close the modal
                                              />
                                        </div>
                                        </Box>

                                      }
                                 </Card>
                            </Grid>
                    ) })
                }
              </Grid>

              {urlFilterOptionId && (
                  <Box sx={{ 
                      width: '100%', 
                      display: 'flex', 
                      justifyContent: 'flex-start',
                      mt: 1,
                      mb: 1
                  }}>
                      <Button 
                          size="small"
                          variant="outlined"
                          onClick={clearUrlFilter}
                          sx={{ 
                              minWidth: '100px',
                              fontSize: '0.75rem'
                          }}
                      >
                          Show all options
                      </Button>
                  </Box>
              )}

          </Grid>
          
      </CardContent>
</Card>)};














