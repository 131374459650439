import * as React from "react";
//import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
import Modal from "@mui/material/Modal";

import Swipeable from "./Swipeable";
import { ethers } from "ethers";
import BAYC from "./artifacts/contracts/FakeBAYC.sol/BAYC.json";
import Option from "./artifacts/contracts/ERC721Option.sol/ERC721Option.json";
import {getTransactions, getAllERC721Transactions} from './Etherscan';

import {
  Typography,
  AppBar,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CssBaseline,
  Grid,
  Toolbar,
  Container,
  Box,
  Paper,
  Stack,
  TextField,
  Button,
  ButtonGroup,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";

import { useConnectWallet } from '@web3-onboard/react'
const INFURA_URL = process.env.REACT_APP_INFURA_URL;

const gradient1 = "radial-gradient(circle at top, #414345, #232526)";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  borderRadius: 1,
  boxShadow: 0,
  bgcolor: "transparent",
  p: 1,
  outline: "none",
};

export function SelectNFTmodal(props) {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [listOfNfts, setListOfNfts] = React.useState([{ id: '' }]);

  const fakeBaycAddress = props.fakeBaycAddress;
  const userAccount = props.userAccount;
  const setSelectedNFTid = props.setSelectedNFTid;
  const selectText = props.selectText;
  const setSelectText = props.setSelectText;
  const switchPC = props.switchPC;
  const isRP = props.isRP;

  const NFTopen = props.NFTopen;
  const NFTsetOpen = props.NFTsetOpen;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect( () => {
    NFTsetOpen(open);
  }, [open]);

  React.useEffect( () => {
    setOpen(NFTopen);
  }, [NFTopen]);

  React.useEffect(
    () => {
      isOwner(fakeBaycAddress, setListOfNfts);
    }, [fakeBaycAddress]
  );

  React.useEffect(
    () => {
    if (!isRP){
      if (!ethers.isAddress(userAccount) ) {
        setSelectText('Connect wallet');
      } else {
        setSelectText('Select ERC721 from wallet')
      }
    } else {
      if (!ethers.isAddress(userAccount) ) {
        setSelectText('Connect wallet');
      } else {
        setSelectText('Select NFT')
      }  
    }
    }, [userAccount]
  );

  const [currentTokenURI, setCurrentTokenURI] = React.useState("");
  const [currImage, setCurrImage] = React.useState('placeholder');


  async function getImageFromMetadata(currentTokenURI) {
    console.log("getImgFro", currentTokenURI);
    //console.log(`currentToken: ${selectedNFTid}`)
    const response = await fetch(
       currentTokenURI //"http://localhost:3000/" +
    ).then((data) => data.clone().json());
    try{
        // Specification #1
        const img = response["properties"]["image"]["description"];
        setCurrImage(img);
        //console.log(img);
      }catch{
        // Specification #2
        setCurrImage(response["image"]);
        //console.log("catch", response["image"]);
    }
  }


  async function handleUpdate() {
    handleOpen();
    isOwner(fakeBaycAddress, setListOfNfts);
  }

  function handleSelect(i) {
    console.log(i);
    setSelectedNFTid(i);
    setSelectText(`ID #${i} selected`);
    handleClose();
  }

  async function tokenURI(activeStepNFTid) {
    console.log("activeStep", activeStepNFTid);
      const provider = new ethers.InfuraProvider("sepolia", INFURA_URL);
      const contract = new ethers.Contract(fakeBaycAddress, BAYC.abi, provider);
      const tokenidURI = await contract.tokenURI(activeStepNFTid);
      console.log("tokenURI function:", activeStepNFTid, tokenidURI);
      setCurrentTokenURI(tokenidURI);
  }

  async function isOwner(fakeBaycAddress, setListOfNfts) {
    var newlist = [];
    setListOfNfts([{ id: '' }]);
    if (wallet) {
      let account = wallet.accounts[0].address;
      const provider= new ethers.BrowserProvider(wallet.provider, 'any')
      const signer = await provider.getSigner()
      //setUserAccount(account);
      newlist = await getTransactions(account, fakeBaycAddress);
      const gg = await getAllERC721Transactions(account);
    }
    setListOfNfts(newlist);
    //console.log(newlist);

     
  }
  const pdTop = isRP? 10 : 10;
  const mgTop = isRP? 5 : 5;
  return (
    <Box sx={{width:'100%', height:'100%'}} >
      <Button
        variant="outlined"
        size='small'
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          maxWidth: "100%",
          //maxHeight: "100%",
          minWidth: "100%",
          minHeight: "100%",
          marginTop: 0,
          fontSize:'12px'
        }}
        color={switchPC == 'Call' ? 'primary': 'secondary'}
        onClick={handleUpdate}
        disabled={(!ethers.isAddress(fakeBaycAddress)) || (selectText == 'Connect wallet') }
      >
        {selectText}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Swipeable
            fakeBaycAddress={fakeBaycAddress} //
            listOfNfts={listOfNfts} //
            setListOfNfts={setListOfNfts}
            handleSelect={handleSelect} //
            tokenURI={tokenURI} //
            currentTokenURI={currentTokenURI} //
            currImage={currImage} //
            setCurrImage={setCurrImage} //
            getImageFromMetadata={getImageFromMetadata} //
            switchPC={switchPC} //
            placeholder='placeholder'//
          />
        </Box>
      </Modal>
    </Box>
  );
}
