import * as React from 'react';
import {useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
//import { autoPlay } from 'react-swipeable-views-utils';
import { Card, CardActions, CardContent, CardMedia, Container, Stack, IconButton, Link} from '@mui/material';
import LinearProgress from './LinearProgress';
import OptCardMedia from './OptCardMedia';
import RedeemPut from './RedeemPut';
import CalcModal from './CalcModal';
import ModalCreateOrder from './ModalCreateOrder';
import OSwhite from './logos/OSwhite.svg' ;
import ESlogo from './logos/etherscan-logo-light-circle.svg';


//const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const AutoPlaySwipeableViews = SwipeableViews;
var bs = require("black-scholes");


function SwipeableTextMobileStepper(props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStepOptionid, setactiveStepOptionid] = React.useState(0);
  const [rpOpen, setRPopen] = React.useState(false);
  const [RPselectText, setRPSelectText] = React.useState('Select NFT');
  const [selectedNFTid, setSelectedNFTid] = React.useState(6);
  
  
  const [calcOpen, setCalcOpen] = React.useState(false);
  const handleClose = () => setCalcOpen(false);
  const handleOpen = () => setCalcOpen(true);


  const listOfOptions = props.listOfOptions;
  const redeemOption = props.redeemOption;
  const maxSteps = listOfOptions.length;
  const tokenURI = props.tokenURI;
  const currentOptionURI = props.currentOptionURI;
  const currOptionImage = props.currOptionImage;
  const setCurrOptionImage = props.setCurrOptionImage;
  const getImageFromMetadata = props.getImageFromMetadata;
  const open = props.open;
  const activeStrike = props.activeStrike;
  const activePC = props.activePC;
  const activeUnderlyingAddress = props.activeUnderlyingAddress;
  const activeUnderlyingID = props.activeUnderlyingID;
  const activeExpiry = props.activeExpiry;
  const switchPC = props.switchPC;
  const bb = props.bb;
  const activeWriter = props.activeWriter;
  const activeOwner = props.activeOwner;
  const retrieveOption = props.retrieveOption;
  const regretOption = props.regretOption;
  const showHide = props.showHide;
  const loadingText = props.loadingText;

  const erc721OptionAddress = props.erc721OptionAddress;
  const userAccount = props.userAccount;
  React.useEffect(
    () => {
      getImageFromMetadata(currentOptionURI);}, [currentOptionURI]
  );

  React.useEffect(
    () => {tokenURI(activeStepOptionid);}, [activeStepOptionid]
  );

  React.useEffect(
    () => {
      setactiveStepOptionid(listOfOptions[activeStep].id);
      setCurrOptionImage('');
    }, [activeStep, open]
  );

  React.useEffect(
    () => {
      console.log("listOfOptions chgd");
    }, [listOfOptions]
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    setactiveStepOptionid(listOfOptions[activeStep].id);
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
      >
{listOfOptions.map((_card) => {
                                return <Box sx={{ width: '100%'}} spacing={2} padding={1}>
                                <Card sx={{ width: "100%"}}>  
                                                          
                                  <OptCardMedia currOptionImage={currOptionImage} currentOptionURI={currentOptionURI}/>
                                
                                    <CardContent>

                                    <Stack direction="row" spacing={1} align='bottom'>
                                        <Typography gutterBottom variant="h6" component="div" sx={{paddingTop: '8px'}}>
                                           Option # {_card.id}
                                        </Typography>
                                          <IconButton onClick={() => window.open(`https://testnets.opensea.io/assets/rinkeby/${erc721OptionAddress}/${_card.id}`)}><img src={OSwhite} width={20} /></IconButton>
                                      </Stack>


                                        <Typography gutterBottom variant="body2" component="div" fontWeight='400'>
                                          {activePC} <Link color={switchPC == 'Call' ? 'primary': 'secondary'} target="_blank" href={`https://rinkeby.etherscan.io/address/${erc721OptionAddress}`}>{activeUnderlyingAddress.substring(0,5) + '...' + activeUnderlyingAddress.substring(38,50)} </Link> @ {activeStrike}
                                        </Typography>
                                        {activePC == 'Call' &&
                                        <Typography gutterBottom variant="body2" component="div">
                                          ID: {activeUnderlyingID}
                                        </Typography>}
                                        <Typography gutterBottom variant="body2" component="div">
                                          Expiry:  <Link color={switchPC == 'Call' ? 'primary': 'secondary'}  target="_blank" href={`https://rinkeby.etherscan.io/block/${activeExpiry}`}>  {activeExpiry + '  '} </Link> {(activeExpiry < bb ? '(Expired)' : '(Active)')}
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="div">
                                          Owner: <Link color={switchPC == 'Call' ? 'primary': 'secondary'} target="_blank" href={`https://rinkeby.etherscan.io/address/${erc721OptionAddress}`}> {activeOwner.toLowerCase().substring(0,5) + '...' + activeOwner.toLowerCase().substring(38,50)} </Link>  {(activeOwner.toLowerCase() == userAccount ? '(you)' : '')}
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="div">
                                          Writer: <Link color={switchPC == 'Call' ? 'primary': 'secondary'} target="_blank" href={`https://rinkeby.etherscan.io/address/${erc721OptionAddress}`}>  {activeWriter.substring(0,5) + '...' + activeWriter.substring(38,50)} </Link> {(activeWriter == userAccount ? '(you)' : '')}
                                        </Typography>
                                        <CalcModal
                                        activeStrike={activeStrike}
                                        activeExpiry={activeExpiry}
                                        activePC={activePC.toLowerCase()}
                                        calcOpen={calcOpen}
                                        setCalcOpen={setCalcOpen}
                                        handleOpen={handleOpen}
                                        handleClose={handleClose}
                                        bb={bb}
                                        switchPC = {switchPC}
                                        />
                                         
                                        {activePC == 'Put' &&
                                        <div>
                                          <br/>
                                          <RedeemPut
                                              NFTopen = {rpOpen}
                                              NFTsetOpen = {setRPopen}
                                              fakeBaycAddress = {activeUnderlyingAddress}  
                                              userAccount = {userAccount}
                                              selectedNFTid = {selectedNFTid}
                                              setSelectedNFTid = {setSelectedNFTid}
                                              RPselectText = {RPselectText} 
                                              setRPSelectText = {setRPSelectText}
                                              switchPC = {switchPC}
                                              disabled={(activeExpiry < bb) || ((activeOwner.toLowerCase() != userAccount.toLowerCase()))}
                                            />
                                        </div>
                                    }
                                    
                                    {showHide && 
                                    <Typography align='center' variant="body2" component="div">
                                          {loadingText} 
                                        </Typography>}
                                            <LinearProgress showHide={showHide} switchPC = {switchPC}/>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" 
                                         color = {switchPC == 'Call' ? 'primary': 'secondary'}
                                         disabled={(activeExpiry < bb) || ((activeOwner.toLowerCase() != userAccount.toLowerCase()))}
                                        onClick={()=> redeemOption(_card.id, activePC === 'Call', selectedNFTid) }>Redeem</Button>
                                        
                                        <Button size="small"
                                         color = {switchPC == 'Call' ? 'primary': 'secondary'}
                                         disabled={activeExpiry >= bb }
                                         onClick={()=> retrieveOption(_card.id, activePC === 'Call') }
                                         >Retrieve</Button>
                                        
                                        <Button size="small"
                                         color = {switchPC == 'Call' ? 'primary': 'secondary'}
                                         disabled={ (activeExpiry < bb) 
                                                    || ((activeOwner.toLowerCase() != activeWriter.toLowerCase()) 
                                                        || (activeWriter.toLowerCase() != userAccount.toLowerCase())) }
                                         onClick={()=> regretOption(_card.id) }
                                         >Regret</Button>

                                    </CardActions>

 
                                </Card>
                            </Box>
                            })}
      </AutoPlaySwipeableViews>
      {listOfOptions.length == 0 && 
      <Typography>
        No options found
      </Typography>
      }
      {!listOfOptions.length == 0 && 
      <MobileStepper
      variant="dots"
        sx={{ borderRadius: 4, background:'transparent'}}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        color = {switchPC == 'Call' ? 'primary': 'secondary'}
        nextButton={
          <Button
            variant='contained'
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1 || listOfOptions.length == 0}
            color = {switchPC == 'Call' ? 'primary': 'secondary'}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button variant='contained' size="small" 
          onClick={handleBack} disabled={activeStep === 0}
          color = {switchPC == 'Call' ? 'primary': 'secondary'}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />}
    </Box>
  );
}

export default SwipeableTextMobileStepper;