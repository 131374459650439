import * as React from 'react';
import {AppBar, Grid, Toolbar, Stack, Box, Typography, Button} from '@mui/material'
import {MainMenu} from './MainMenu';
import Ztrike from './ZtrikeLogo.js' ;
import ZtrikeName from './logos/ZvgZtrikeName.svg';
import ZtrikeNameDark from './logos/ZvgZtrikeNameDark.svg';
import DarkModeSwitch
 from './darkModeSwitch.js';
import { Link } from "react-router-dom";

export function AppBarModule(props) {
    const handleMode = props.handleMode;
    const mode = props.mode;
    const btnColor = mode == 'light' ? 'black' : 'white';
    //#264a39
    return(
        <AppBar position='fixed'  color="transparent" elevation={0} enableColorOnDark>
        <Toolbar sx={{ justifyContent: "space-between" }}>
        <Ztrike width={150} logocolor= "#000"/> 


        <Box   display="flex" justifyContent="flex-start" alignItems="center">
                            <Box
                                            component="img"
                                            sx={{
                                            maxWidth: {xs:'70%', sm:'80%'},
                                            }}
                                            alt="Ztrike"
                                            src={ mode=='dark'? ZtrikeName: ZtrikeNameDark }
                                        />    
                    </Box> 

            <div>                                      
            <Grid container justify="flex-end"  >
            <Button variant="outlined" 
            component={Link} to="/app" 
            sx={{borderColor:btnColor, color:btnColor, marginTop:'5px'}}>
                TRADE
            </Button>
            </Grid>
            <DarkModeSwitch
                                onChange={handleMode}
                                checked={mode=="light"}
                                />
            </div>
        </Toolbar>
    </AppBar>
    )};
